/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './Counterparty.module.css';
import { ReactComponent as Down } from '../ico/down.svg';
import { ReactComponent as Search } from '../ico/search.svg';
import { ReactComponent as Cancel } from '../ico/cancel.svg';
import { ReactComponent as CreateIco } from '../ico/create.svg';
import cn from 'classnames';
import { FinanceCounterpartyModel } from '../../../../api/finance/dto/FinanceTypes';
import { useApi } from '../../../../hooks/useApi';
import GetCounterpartReq from '../../../../api/finance/dto/Counterparty/GetCounterpartReq';
import CounterpartyActionPopUP from '../../../../pages/finance/popUps/counterparty/CounterpartyAction';

interface CounterpartyInputProps {
	resultCounterparty: (counterparty: FinanceCounterpartyModel | null) => void;
	marginTop?: number;
	counterpartyDefault?: FinanceCounterpartyModel | null;
}

const CounterpartyInput: React.FC<CounterpartyInputProps> = observer(({resultCounterparty, marginTop = 0, counterpartyDefault = null }) => {

	const api = useApi();

	const [counterpartyList, setCounterpartyList] = useState<FinanceCounterpartyModel[] | null>(null);
	const [selectedCounterparty, setSelectedCounterparty] = useState<FinanceCounterpartyModel | null>(null);
	const [haveResults, setHaveResults] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [sortText, setSortText] = useState<string>('');
	const [showPopUp, setShowPopUp] = useState<boolean>(false);

	const tooglePopUp = () => setShowPopUp(!showPopUp);

	useEffect(() => {
		if (counterpartyDefault) {
			setSelectedCounterparty(counterpartyDefault);
		}
	},[counterpartyDefault]);

	useEffect(() => {
		if (counterpartyList && counterpartyList.length > 0) {
			setHaveResults(true);
		} else {
			setHaveResults(false);
		}
	}, [counterpartyList]);

	const getCounterpartyList = async (skip: number, take: number) => {

		const data: GetCounterpartReq = {
			skip,
			take
		};

		if (sortText && sortText !== '') {
			data.name = sortText;
		}

		const { body } = await api.finance.GetCounterparties(data);

		if (body) {
			setCounterpartyList(body);
		}
	}

	const updateCounterpartyList = () => getCounterpartyList(0, 100)

	useEffect(() => {
		getCounterpartyList(0, 15);
	}, [sortText]);

	useEffect(() => {
		getCounterpartyList(0, 100);
	},[selectedCounterparty]);

	useEffect(() => {
		resultCounterparty(selectedCounterparty);
	}, [selectedCounterparty]);

	const handleFocusText = (e: React.FocusEvent<HTMLInputElement>) => {
		e.target.value = '';
	};

	const handleFocusInputAccountFrom = () => {
		if (inputRef.current) {
			inputRef.current.focus();
			setIsOpen(!isOpen)
		}
	};

	const setNewCounterparty = (counterparty: FinanceCounterpartyModel | null) => {
		if (!counterparty) {return}
		
		setSelectedCounterparty(counterparty);
		setIsOpen(false);
		setSortText('');
	}

	return (
		<>
			{showPopUp && <CounterpartyActionPopUP resultCounterparty={setNewCounterparty} counterparty={null} onClose={tooglePopUp} updateCounterparty={updateCounterpartyList}/>}

			<div className={styles.lineFormContainer} style={{marginTop}}>
				<div className={styles.lineFormLabel}>Контрагент</div>

				<div className={styles.lineFormInputContainer}>

					<div className={styles.lineFormInputDropDown}>
						<input
							type='text'
							className={styles.lineFormInputStandart}
							placeholder='ООО «Рога и копыта»'
							ref={inputRef}
							onChange={(e) => setSortText(e.target.value)}
							onBlur={() => {setIsOpen(false); setSortText('')}}
							onFocus={(e) => handleFocusText(e)}
						/>
						<button
							className={styles.lineFormInputButton}
							style={{display: !isOpen ? 'block' : 'none'}}
							tabIndex={-1}
							onClick={handleFocusInputAccountFrom}
						>
							<div className={styles.accountSelectorButton}>
								{!selectedCounterparty && <span>ООО «Рога и копыта»</span>}
								{selectedCounterparty && <span style={{color: 'black'}}>{selectedCounterparty.name}</span>}
							</div>
						</button>

						<span className={cn(styles.lineFormInputStandartSymbol, {[styles.clearButton]: selectedCounterparty})}
						>
							{!selectedCounterparty && !isOpen && <Down/>}
							{!selectedCounterparty && isOpen && <Search/>}
							{selectedCounterparty && 
									<Cancel
										onClick={() => {
											setSelectedCounterparty(null);
											setSortText('')
										}}
									/>
							}
						</span>
					</div>

					{isOpen && 
					<div className={styles.searchResultContainer}>

						<div className={styles.searchResultWraper}>

							<div className={styles.searchResultScroller}>

								{counterpartyList && counterpartyList.map((item, i) => {
									return (
										<>
											<div
												key={item.id}
												className={styles.searchResultItem}
												onMouseDown={() => {
													setSelectedCounterparty(item);
													setIsOpen(false);
													setSortText('');
												}}
											>
												{item.name}
											</div>
											{counterpartyList.length - 1 === i && 
										<div className={styles.createRow}>
											<button className={styles.createRowButton}>
												<span className={styles.createRowButtonText} onMouseDown={() => setShowPopUp(true)}>
													<span>Создать</span>
												</span>
											</button>
										</div>
											}
										</>
									)
								})}

								{!haveResults && <div className={styles.searchEmptyResult}>Такого не найдено :-(</div>}

							</div>

							<div className={styles.createButton} onMouseDown={() => setShowPopUp(true)}>
								<CreateIco/>
							</div>

						</div>

					</div>
					}

				</div>

			</div>
		</>
		

	)
	
});

export default CounterpartyInput;