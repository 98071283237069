import moment from 'moment';
import { useEffect, useState } from 'react';
import DateRangePicker, { DateRange, RangeType } from 'rsuite/DateRangePicker';

import 'rsuite/DateRangePicker/styles/index.css';
import { formatMySQLDatetime } from '../../../utils/date';

export default function DatePickerLocal({onChangeDate}: {onChangeDate: (date: [string, string]) => void}) {

	const getCurrentPeriodIndex = () => {
		const yearStart = moment().startOf('year');
		const currentDate = moment();
		const weeksSinceYearStart = currentDate.diff(yearStart, 'weeks');
		return Math.floor(weeksSinceYearStart / 4) + 1;
	};

	const getStartPeriod = () => {
		const periodIndex = getCurrentPeriodIndex()
		const yearStart = moment().startOf('year');
		const periodStart = yearStart.clone().add((periodIndex - 1) * 4, 'weeks');
		return new Date(periodStart.format('YYYY-MM-DD'));
	}

	const getEndPeriod = () => {
		const periodIndex = getCurrentPeriodIndex()
		const yearStart = moment().startOf('year');
		const periodStart = yearStart.clone().add((periodIndex - 1) * 4, 'weeks');
		const periodEnd = periodStart.clone().add(4, 'weeks').subtract(1, 'day');

		return new Date(periodEnd.format('YYYY-MM-DD'));
	}

	const getStartLastPeriod = () => {
		const periodIndex = getCurrentPeriodIndex();
		const yearStart = moment().startOf('year');
		const periodStart = yearStart.clone().add((periodIndex - 2) * 4, 'weeks');
		return new Date(periodStart.format('YYYY-MM-DD'));
	}

	const getEndLastPeriod = () => {
		const periodIndex = getCurrentPeriodIndex();
		const yearStart = moment().startOf('year');
		const periodStart = yearStart.clone().add((periodIndex - 2) * 4, 'weeks');
		const periodEnd = periodStart.clone().add(4, 'weeks').subtract(1, 'day');

		return new Date(periodEnd.format('YYYY-MM-DD'));
	}

	const [currentDate, setCurrentDate] = useState<[Date, Date]>([getStartPeriod(), getEndPeriod()]);

	const predefinedRanges: RangeType[] = [
		{
			label: 'Сегодня',
			value: [new Date(), new Date()],
			placement: 'left'
		},
		{
			label: 'Вчера',
			value: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
			placement: 'left'
		},
		{
			label: 'Текущая неделя',
			value: [moment().startOf('week').toDate(), moment().endOf('week').toDate()],
			placement: 'left'
		},
		{
			label: 'Прошлая неделя',
			value: [moment().subtract(1, 'week').startOf('week').toDate(), moment().subtract(1, 'week').endOf('week').toDate()],
			placement: 'left'
		},
		{
			label: 'Текущий период',
			value: [getStartPeriod(), getEndPeriod()],
			placement: 'left'
		},
		{
			label: 'Прошлый период',
			value: [getStartLastPeriod(), getEndLastPeriod()],
			placement: 'left'
		},
	];

	const changeDate =(date: DateRange | null) => {
		if (!date) {return;}
		setCurrentDate([date[0], date[1]]);
	}

	useEffect(() => {
		onChangeDate([formatMySQLDatetime(currentDate[0]), formatMySQLDatetime(currentDate[1], true)]);
	}, [currentDate]);

	return (
		<DateRangePicker 
			size='lg' 
			format='dd.MM.yyyy' 
			isoWeek 
			character=' - '
			appearance='subtle'
			cleanable={false}
			placement='bottomEnd'
			showOneCalendar
			ranges={predefinedRanges}
			value={currentDate}
			onChange={(v) => changeDate(v)}

			style={{marginRight: 5}}
			
		/>
	);
}