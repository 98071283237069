import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getTitleName } from '../../../utils/pageTitle';

export const SetPageTitle = (): JSX.Element => {
	const {pathname} = useLocation();

	useEffect(() => {
		document.title = (getTitleName(pathname));
	},[pathname]);

	return (
		<></>
	);
};