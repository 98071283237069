/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './TextArea.module.css';

interface TextAreaInputProps {
	placeholderText: string;
	resultText: (text: string | null) => void;
	marginTop?: number;
	defaultValue?: string | null;
}

const TextAreaInput: React.FC<TextAreaInputProps> = observer(({resultText, placeholderText, marginTop = 0, defaultValue }) => {

	const [text, setText] = useState<string | null>(null);
	const [zIndex, setZIndex] = useState<number>(0);

	useEffect(() => {
		resultText(text);
	}, [text]);

	useEffect(() => {
		if (defaultValue) {
			setText(defaultValue);
		}
	}, [defaultValue]);

	const onFocus = () => setZIndex(1);
	const onBlur = () => setZIndex(0);

	return (
		<div className={styles.container} style={{marginTop, zIndex}}>

			<textarea
				style={{zIndex}}
				onFocus={onFocus}
				onBlur={onBlur}
				className={styles.textarea}
				value={text || ''}
				placeholder={placeholderText}
				onChange={(e) => setText(e.target.value)}
			/>

		</div>

	)
	
});

export default TextAreaInput;