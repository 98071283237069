/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './OperationAction.module.css';
import { ReactComponent as Minus } from '../../ico/minus.svg';
import { ReactComponent as Plus } from '../../ico/plus.svg';
import { ReactComponent as Transfer } from '../../ico/transfer.svg';
import { useApi } from '../../../../../hooks/useApi';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { useStores } from '../../../../../hooks/useStore';
import GetOperationResp from '../../../../../api/finance/dto/Operations/GetOperationResp';
import { FinanceAccountModel, FinanceCounterpartyModel, FinanceOperationCategoryModel, OperationTypes } from '../../../../../api/finance/dto/FinanceTypes';
import AccountInput from '../../../../../components/UI/finance/account/Account';
import CounterpartyInput from '../../../../../components/UI/finance/counterparty/Counterparty';
import TextInput from '../../../../../components/UI/finance/text/Text';
import DateInput from '../../../../../components/UI/finance/date/Date';
import CheckboxInput from '../../../../../components/UI/finance/checkbox/Checkbox';
import { isFutureDate } from '../../../../../utils/date';
import ValueInput from '../../../../../components/UI/finance/value/Value';
import OperationCategoryInput from '../../../../../components/UI/finance/operationCategory/OperationCategory';
import moment from 'moment';
import CounterpartyActionPopUP from '../../counterparty/CounterpartyAction';

interface PopupProps {
	operation: GetOperationResp | null;
	operationTypeSet: OperationTypes;
  onClose: () => void;
	addNewOperationToList: (operation: GetOperationResp) => void;
	updateOperationList: (operation: GetOperationResp) => void;
	deleteOperationList: (id: string) => void;
}

const OperationActionPopUP: React.FC<PopupProps> = observer(({ onClose, operationTypeSet, operation = null, updateOperationList, deleteOperationList, addNewOperationToList }) => {

	const store = useStores();
	const api = useApi();

	const [operationType, setOperationType] = useState<OperationTypes>(operationTypeSet);

	const [accountFrom, setAccountFrom] = useState<FinanceAccountModel | null>(null);
	const [accountTo, setAccountTo] = useState<FinanceAccountModel | null>(null);

	const [amountFrom, setAmountFrom] = useState<number>(0);
	const [amountTo, setAmountTo] = useState<number>(0);

	const [selectCounterparty, setSelectCounterparty] = useState<FinanceCounterpartyModel | null>(null);
	const [selectCategory, setSelectCategory] = useState<FinanceOperationCategoryModel | null>(null);

	const [description, setDescription] = useState<string | null>(null);
	const [date, setDate] = useState<string>('');
	const [isPlanned, setIsPlanned] = useState<boolean>(false);

	const [showCounterparty, setShowCounterparty] = useState<boolean>(false);
	const toogleCounterparty = () => setShowCounterparty(!showCounterparty);

	console.log('Показать попап контрагента', showCounterparty)

	useEffect(() => {
		setIsPlanned(isFutureDate(date))
	}, [date]);

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	useEffect(() => {
		if (operation) {
			setOperationType(operation.operation_type);
			
			if (operation.from_account) {
				setAccountFrom(operation.from_account);
			}

			if (operation.to_account) {
				setAccountTo(operation.to_account);
			}

			setAmountFrom(operation.amount_from || 0);
			setAmountTo(operation.amount_to || 0);

			setSelectCounterparty(operation.counterparty);
			setSelectCategory(operation.operation_category);
			setDescription(operation.description);
			setDate(moment(operation.date).format('YYYY-MM-DD').toString());
			setIsPlanned(operation.is_planned);
		}
	}, [operation]);

	const handleButtonClick = async () => {
		try {
			store.notificationsStore.showPreloader();

			if (operation) {
				const {body: updateOperation} = await api.finance.UpdateOperation(operation.id, {
					operation_type: operation.operation_type,
					from_account: accountFrom?.id ? accountFrom?.id : null,
					to_account: accountTo?.id ? accountTo?.id : null,
					amount_from: amountFrom,
					amount_to: amountTo,
					id_counterparty: selectCounterparty?.id ? selectCounterparty?.id : null,
					id_operation_category: selectCategory?.id ? selectCategory?.id : null,
					description,
					date,
					is_planned: isPlanned,
					for_approval: false,
				});
				if (updateOperation) {updateOperationList(updateOperation)}
				return;
			}

			if (operationType === 'expense') {
				const {body: newOperation} = await api.finance.CreateOperation({
					amount_from: amountFrom,
					amount_to: null,
					operation_type: operationType,
					from_account: accountFrom?.id ? accountFrom?.id : null,
					to_account: null,
					id_counterparty: selectCounterparty?.id ? selectCounterparty?.id : null,
					id_operation_category: selectCategory?.id ? selectCategory?.id : null,
					description: description ? description : null,
					date,
					is_planned: isPlanned,
					for_approval: false,
				});
				if (newOperation) {addNewOperationToList(newOperation)}
				return;
			}

			if (operationType === 'income') {
				const {body: newOperation} = await api.finance.CreateOperation({
					amount_from: null,
					amount_to: amountTo,
					operation_type: operationType,
					from_account: null,
					to_account: accountTo?.id ? accountTo?.id : null,
					id_counterparty: selectCounterparty?.id ? selectCounterparty?.id : null,
					id_operation_category: selectCategory?.id ? selectCategory?.id : null,
					description: description ? description : null,
					date,
					is_planned: isPlanned,
					for_approval: false,
				});
				if (newOperation) {addNewOperationToList(newOperation)}
				return;
			}

			if (operationType === 'transfer') {
				const {body: newOperation} = await api.finance.CreateOperation({
					amount_from: amountFrom,
					amount_to: amountTo,
					operation_type: operationType,
					from_account: accountFrom?.id ? accountFrom?.id : null,
					to_account: accountTo?.id ? accountTo?.id : null,
					id_counterparty: selectCounterparty?.id ? selectCounterparty?.id : null,
					id_operation_category: selectCategory?.id ? selectCategory?.id : null,
					description: description ? description : null,
					date,
					is_planned: isPlanned,
					for_approval: false,
				});
				if (newOperation) {addNewOperationToList(newOperation)}
				return;
			}

			

		} catch (e) {
			store.notificationsStore.setError(`${!operation ? 'Ошибка создания операции' : 'Ошибка обновления операции'}`);
		} finally {
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications(`${!operation ? 'Операция успешно создана' : 'Операция успешно обновлена'}`);
			onClose();
		}
	}

	const deleteOperation = async () => {
		try {
			store.notificationsStore.showPreloader();

			if (operation) {
				await api.finance.DeleteOperation(operation.id);
				deleteOperationList(operation?.id)
				return;
			}

		} catch (e) {
			store.notificationsStore.setError('Ошибка удаления операции');
		} finally {
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications('Операция успешно удалена');
			onClose();
		}
	}

	
	return (
		<>
			{/* {showCounterparty && <CounterpartyActionPopUP counterparty={null} onClose={toogleCounterparty} updateCounterparty={}/>} */}
			<div className={styles.container} onClick={handleOverlayClick}>
				<div className={styles.form}>

					{!operation &&

				<div className={styles.tabsTypeOperationContainer}>

					<div 
						className={cn(styles.tabsTypeOperationItem, {[styles.activeTab] : operationType === 'expense'})}
						onClick={() => setOperationType('expense')}
					>
						<Minus/>
						Расход
					</div>

					<div
						className={cn(styles.tabsTypeOperationItem, {[styles.activeTab] : operationType === 'transfer'})}
						onClick={() => setOperationType('transfer')}
					>
						<Transfer/>
						Перевод
					</div>

					<div 
						className={cn(styles.tabsTypeOperationItem, {[styles.activeTab] : operationType === 'income'})}
						onClick={() => setOperationType('income')}
					>
						<Plus/>
						Приход
					</div>


				</div>

					}

					<div className={styles.bodyFormContainer}>

						<ValueInput
							accountTo={accountTo}
							accountFrom={accountFrom}
							operaionType={operationType}
							marginTop={-1}
							amountTo={amountTo}
							amountFrom={amountFrom}
							setAmountTo={setAmountTo}
							setAmountFrom={setAmountFrom}
						/>

						{ operationType === 'expense' &&
						<AccountInput 
							labelName='Со счёта'
							accountDefault={accountFrom}
							resultAccount={setAccountFrom}
							marginTop={20}
						/>
						}

						{ operationType === 'income' &&
						<AccountInput 
							labelName='На счёт'
							accountDefault={accountTo}
							resultAccount={setAccountTo}
							marginTop={20}
						/>
						}
					
						{ operationType === 'transfer' &&
					<>
						<AccountInput 
							labelName='Со счёта'
							accountDefault={accountFrom}
							resultAccount={setAccountFrom}
							marginTop={20}
						/>

						<AccountInput 
							labelName='На счёт'
							accountDefault={accountTo}
							resultAccount={setAccountTo}
							marginTop={-1}
						/>
					</>
						}
					
						{ operationType !== 'transfer' &&
					<CounterpartyInput
						resultCounterparty={setSelectCounterparty}
						marginTop={-1}
						counterpartyDefault={selectCounterparty}
					/>
						}

						<OperationCategoryInput
							resultOperationCategory={setSelectCategory}
							operationCategoryDefault={selectCategory}
							marginTop={-1}
							operationType={operationType}
						/>

						<TextInput
							labelText='Описание'
							placeholderText='Введите описание'
							resultText={setDescription}
							marginTop={-1}
							defaultValue={description}
						/>

						<DateInput
							resultDate={setDate}
							labelName='Дата'
							marginTop={-1}
							dateDefault={date}
						/>

						<CheckboxInput
							labelText='Плановая операция'
							mustTrue={isFutureDate(date)}
							result={setIsPlanned}
							marginTop={10}
						/>

					</div>

					<div className={styles.formActionsContainer}>

						<div className={styles.formActionsContainerLeft}>

							<button className={styles.formActionsButtonMain} onClick={handleButtonClick}>{!operation ? 'Добавить' : 'Изменить'}</button>

							<button className={styles.formActionsButtonSecond} onClick={onClose}>Отменить</button>

						</div>

						<div className={styles.formActionsContainerRight}>

							{operation && <button style={{color: '#df3535'}} className={styles.formActionsButtonSecond} onClick={deleteOperation}>Удалить</button>}

						</div>

					</div>

				</div>


			</div>
		</>
	);
});

export default OperationActionPopUP;
