/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './TextBig.module.css';

interface TextBigInputProps {
	placeholderText: string;
	resultText: (text: string | null) => void;
	marginTop?: number;
	defaultValue?: string | null;
}

const TextBigInput: React.FC<TextBigInputProps> = observer(({resultText, placeholderText, marginTop = 0, defaultValue }) => {

	const [text, setText] = useState<string | null>(null);
	const [zIndex, setZIndex] = useState<number>(0);

	useEffect(() => {
		resultText(text);
	}, [text]);

	useEffect(() => {
		if (defaultValue) {
			setText(defaultValue);
		}
	}, [defaultValue]);

	const onFocus = () => setZIndex(1);
	const onBlur = () => setZIndex(0);

	return (
		<div className={styles.container} style={{marginTop, zIndex}}>

			<span className={styles.containerInput} style={{zIndex}}>

				<input
					style={{zIndex}}
					className={styles.input}
					type='text'
					value={text || ''}
					placeholder={placeholderText}
					onChange={(e) => setText(e.target.value)}
					onFocus={onFocus}
					onBlur={onBlur}
				/>
			</span>


		</div>

	)
	
});

export default TextBigInput;