/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './Date.module.css';
import moment, { Moment } from 'moment';
require('moment/locale/ru');
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { styled } from '@mui/material/styles'
import { set } from 'mobx';


interface DateInputProps {
	labelName: string;
	resultDate: (date: string) => void;
	marginTop?: number;
	dateDefault?: string | null;
}

const visualFormat = 'DD.MM.YYYY';
const serverFormat = 'YYYY-MM-DD';

const DateInput: React.FC<DateInputProps> = observer(({labelName, resultDate, marginTop = 0, dateDefault }) => {

	const getTodayDate = () => {
		return moment().format(visualFormat).toString();
	}
	
	const [date, setDate] = useState<string>(getTodayDate());
	const [dateForCalendar, setDateForCalendar] = useState<Moment | null>(moment(date));
	const inputRef = useRef<HTMLInputElement>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	useEffect(() => {
		if (dateDefault) {
			setDate(moment(dateDefault, serverFormat).format(visualFormat).toString());
		}
	}, [dateDefault]);

	useEffect(() => {
		resultDate(moment(date, visualFormat).format(serverFormat).toString());

		setDateForCalendar(moment(moment(date, visualFormat).format(serverFormat)));
	}, [date]);

	const handleFocusInputAccountFrom = () => {
		if (inputRef.current) {
			inputRef.current.focus();
			setIsOpen(!isOpen)
		}
	};

	const StyledStaticDatePicker = styled(StaticDatePicker)({
		'.MuiPickersToolbar-root': {
			display: 'none',
		},
		'.MuiDateCalendar-root button': {
			color: '#fff',
		},
		'.MuiDayCalendar-header span': {
			color: '#999',
		},
		'.MuiPickersDay-root.MuiPickersDay-today': {
			border: '2px solid #FB7C0F',
		},
		'.MuiPickersDay-root.MuiPickersDay-today.Mui-selected': {
			border: 'none',
		},
		'.MuiDateCalendar-root': {
			color: '#fff',
			backgroundColor: '#2B333B',
		},
		'.MuiDialogActions-root': {
			color: '#fff',
			backgroundColor: '#2B333B',
			display: 'none'
		},
		'.MuiPickersCalendarHeader-switchViewButton': {
			color: '#fff',
		},
	})

	const setDateFromCalendar = (dateFC: Moment | null) => {
		setDate(moment(dateFC, serverFormat).format(visualFormat).toString());
		setIsOpen(false);
	}

	const setDateFromInput = (dateFI: string) => {
		if (moment(date).isValid()) {
			setDate(date);
		}
	}

	return (
		<div className={styles.lineFormContainer} style={{marginTop}}>
			<div className={styles.lineFormLabel}>{labelName}</div>

			<div className={styles.lineFormInputContainer}>

				<div className={styles.lineFormInputDropDown}>
					<input
						id='accountFrom'
						type='date'
						value={moment(dateForCalendar).format(serverFormat)}
						className={styles.lineFormInputStandart}
						ref={inputRef}
						onChange={(e) => setDateFromInput(e.target.value)}
						onMouseDown={() => {setIsOpen(false)}}
						// onFocus={(e) => handleFocusText(e)}
					/>
					<button
						className={styles.lineFormInputButton}
						style={{display: !isOpen ? 'block' : 'none'}}
						tabIndex={-1}
						onClick={handleFocusInputAccountFrom}
					>
						<div className={styles.accountSelectorButton}>
							<span style={{color: 'black'}}>{date}</span>
						</div>
					</button>
				</div>

				{isOpen && 
							<div className={styles.searchResultContainer}>
								<LocalizationProvider dateAdapter={AdapterMoment}>
									<StyledStaticDatePicker 
										onChange={(d) => setDateFromCalendar(d)}
										value={dateForCalendar}
										defaultValue={dateForCalendar}
										localeText={{
											cancelButtonLabel: 'Отмена',
											okButtonLabel: 'Выбрать',
										}} 
									/>
								</LocalizationProvider>
							</div>
				}

			</div>

		</div>

	)
	
});

export default DateInput;