import React from 'react';
import styles from './CustomCheckbox.module.css';

interface CustomCheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked, onChange }) => {
	const handleCheckboxChange = () => {
		onChange(!checked);
	};

	return (
		<div className={styles.containerMain}>
			<label className={styles.container}>
				<input
					type="checkbox"
					checked={checked}
					onChange={handleCheckboxChange}
					className={styles.checkbox}
				/>
				<span className={styles.checkmark}></span>
			</label>
		</div>
	);
};

export default CustomCheckbox;
