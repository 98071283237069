import React, { useEffect } from 'react';
import styles from './account.module.css';
import { AccountProps } from './account.props';
import { formatNumberWithSpaces } from '../../../utils/numbers';
import CreateUpdateAccountPopUP from '../../../pages/finance/popUps/accounts/create/CreateAccount';


const FinanceAccount = ({ account, updateAccounts }: AccountProps) => {

	const [currencySymbol, setCurrencySymbol] = React.useState<string>('');

	useEffect(() => {
		if (account.currency === 'RUB') {
			setCurrencySymbol('₽');
		} else if (account.currency === 'USD') {
			setCurrencySymbol('$');
		}
	}, [account.currency]);

	const [showEditPopUp, setShowEditPopUp] = React.useState<boolean>(false);

	const toogleEditPopUp = () => {
		setShowEditPopUp(!showEditPopUp);
	}

	return (
		<>
			{showEditPopUp && <CreateUpdateAccountPopUP  onClose={toogleEditPopUp} updateAccounts={updateAccounts} account={account}/>}
			<div className={styles.indicator} onClick={() => setShowEditPopUp(true)}>
				<p className={styles.indicatorTitle}>{account.name}</p>
				<p style={{color: account.balance < 0 ? 'red' : 'black'}} className={styles.indicatorBalance}>{formatNumberWithSpaces(account.balance)} {currencySymbol}</p>
			</div>
		</>
	);
};

export default FinanceAccount;
