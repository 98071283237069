/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './CounterpartyGroupAction.module.css';
import { ReactComponent as Minus } from '../../ico/minus.svg';
import { ReactComponent as Plus } from '../../ico/plus.svg';
import { ReactComponent as Transfer } from '../../ico/transfer.svg';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { FinanceCounterpartyGroupModel } from '../../../../api/finance/dto/FinanceTypes';
import { useStores } from '../../../../hooks/useStore';
import { useApi } from '../../../../hooks/useApi';
import TextInput from '../../../../components/UI/finance/text/Text';
import TextBigInput from '../../../../components/UI/finance/textBig/TextBig';
import TextAreaInput from '../../../../components/UI/finance/textArea/TextArea';
import CounterpartyGroupInput from '../../../../components/UI/finance/counterpartyGroup/CounterpartyGroup';

interface PopupProps {
	counterpartyGroup: FinanceCounterpartyGroupModel | null;
  onClose: () => void;
	updateCounterpartyGroup: () => void;
	resultCounterpartyGroup: (counterparty: FinanceCounterpartyGroupModel | null) => void;
}

const CounterpartyGroupActionPopUP: React.FC<PopupProps> = observer(({ onClose, counterpartyGroup = null, updateCounterpartyGroup, resultCounterpartyGroup }) => {

	const store = useStores();
	const api = useApi();

	const [name, setName] = useState<string | null>(null);

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	useEffect(() => {
		if (counterpartyGroup) {
			setName(counterpartyGroup.name);
		}
	}, [counterpartyGroup]);

	const handleButtonClick = async () => {
		try {
			if (!name) {
				store.notificationsStore.setError('Введите название контрагента');
				return;
			}

			store.notificationsStore.showPreloader();

			if (!counterpartyGroup) {
				const {body} = await api.finance.CreateCounterpartyGroup({
					name,
				});
				updateCounterpartyGroup();
				if(body) {resultCounterpartyGroup(body)}
			} else {
				const {body} = await api.finance.UpdateCounterpartyGroup(counterpartyGroup.id, {
					name,
				});
				updateCounterpartyGroup();
				if(body) {resultCounterpartyGroup(body)}
			}
			
		} catch (e) {
			store.notificationsStore.setError(`${!counterpartyGroup ? 'Ошибка создания группы' : 'Ошибка обновления группы'}`);
		} finally {
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications(`${!counterpartyGroup ? 'Группа успешно создана' : 'Группа успешно обновлена'}`);
			onClose();
		}
	}

	const deleteCounterpartyGroup = async () => {
		try {
			store.notificationsStore.showPreloader();

			if (counterpartyGroup) {
				await api.finance.DeleteCounterpartyGroup(counterpartyGroup.id);
				return;
			}
		} catch (e) {
			store.notificationsStore.setError('Ошибка удаления группы');
		} finally {
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications('Группа успешно удалена');
			onClose();
		}
	}

	
	return (
		<div className={styles.container} onClick={handleOverlayClick}>
			<div className={styles.form}>

				

				<div className={styles.bodyFormContainer}>

					<TextBigInput
						placeholderText='Название группы'
						resultText={setName}
						marginTop={20}
						defaultValue={name}
					/>

				</div>

				<div className={styles.formActionsContainer}>

					<div className={styles.formActionsContainerLeft}>

						<button className={styles.formActionsButtonMain} onClick={handleButtonClick}>{!counterpartyGroup ? 'Создать' : 'Изменить'}</button>

						<button className={styles.formActionsButtonSecond} onClick={onClose}>Отменить</button>

					</div>

					<div className={styles.formActionsContainerRight}>

						{counterpartyGroup && <button style={{color: '#df3535'}} className={styles.formActionsButtonSecond} onClick={deleteCounterpartyGroup}>Удалить</button>}

					</div>

				</div>

			</div>


		</div>
	);
});

export default CounterpartyGroupActionPopUP;
