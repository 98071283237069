/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './Value.module.css';
import { getSymbolFromCurrency } from '../../../../utils/text';
import { FinanceAccountModel, OperationTypes } from '../../../../api/finance/dto/FinanceTypes';
import cn from 'classnames';

interface ValueInputProps {
	accountTo: FinanceAccountModel | null;
	accountFrom: FinanceAccountModel | null;
	operaionType: OperationTypes;
	marginTop?: number;

	amountTo: number;
	amountFrom: number;
	setAmountTo: (amount: number) => void;
	setAmountFrom: (amount: number) => void;
}

const ValueInput: React.FC<ValueInputProps> = observer(({accountTo, accountFrom, operaionType, marginTop, amountTo, amountFrom, setAmountTo, setAmountFrom}) => {

	const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		if (e.target.value === '0') {
			e.target.value = ''; // Очищаем поле ввода, если значение равно 0
		}
	};

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		if (e.target.value === '') {
			e.target.value = '0'; // Восстанавливаем 0, если пользователь ничего не ввел
		}

		if (operaionType === 'expense') {
			setAmountFrom(+e.target.value);
		}

		if (operaionType === 'income') {
			setAmountTo(+e.target.value);
		}

		if (operaionType === 'transfer' && accountTo && accountTo.currency === accountFrom?.currency) {
			setAmountTo(+e.target.value);
			setAmountFrom(+e.target.value);
		}
	};

	return (
		<>

			{operaionType === 'expense' &&
			<div className={styles.valueContainer}>

				<div className={styles.valueSumbol}>
					-
				</div>

				<div className={styles.valueCurrency}>
					{accountFrom && getSymbolFromCurrency(accountFrom.currency)}
				</div>

				<span className={styles.valueAmount}>
					<input
						className={cn (styles.valueAmountInput, {[styles.emptyInput]: amountFrom === 0})}
						value={amountFrom}
						onChange={(e) => setAmountFrom(+e.target.value)}
						placeholder="0"
						onFocus={handleFocus}
						onBlur={handleBlur}
						type='number'
					/>
				</span>
					
			</div>
			}

			{operaionType === 'income' &&
			<div className={styles.valueContainer}>

				<div className={styles.valueSumbol} style={{top: 17}}>
					+
				</div>

				<div className={styles.valueCurrency}>
					{accountTo && getSymbolFromCurrency(accountTo.currency)}
				</div>

				<span className={styles.valueAmount}>
					<input
						className={cn (styles.valueAmountInput, {[styles.emptyInput]: amountTo === 0})}
						value={amountTo}
						onChange={(e) => setAmountTo(+e.target.value)}
						placeholder="0"
						onFocus={handleFocus}
						onBlur={handleBlur}
						type='number'
					/>
				</span>
					
			</div>
			}

			{operaionType === 'transfer' && accountTo && accountTo.currency === accountFrom?.currency &&
			<div className={styles.valueContainer}>

				<div className={styles.valueSumbol} style={{top: 20}}>
					±
				</div>

				<div className={styles.valueCurrency}>
					{accountTo && getSymbolFromCurrency(accountTo.currency)}
				</div>

				<span className={styles.valueAmount}>
					<input
						className={cn (styles.valueAmountInput, {[styles.emptyInput]: amountTo === 0})}
						value={amountTo}
						onChange={(e) => {setAmountTo(+e.target.value); setAmountFrom(+e.target.value)}}
						placeholder="0"
						onFocus={handleFocus}
						onBlur={handleBlur}
						type='number'
					/>
				</span>
					
			</div>
			}

			{operaionType === 'transfer' && accountTo && accountTo.currency !== accountFrom?.currency &&
			<>
				<div className={styles.valueContainer}>

					<div className={styles.valueSumbol}>
				-
					</div>

					<div className={styles.valueCurrency}>
						{accountFrom && getSymbolFromCurrency(accountFrom.currency)}
					</div>

					<span className={styles.valueAmount}>
						<input
							className={cn (styles.valueAmountInput, {[styles.emptyInput]: amountFrom === 0})}
							value={amountFrom}
							onChange={(e) => setAmountFrom(+e.target.value)}
							placeholder="0"
							onFocus={handleFocus}
							onBlur={(e) => {setAmountFrom(+e.target.value)}}
							type='number'
						/>
					</span>
				
				</div>

				<div className={styles.valueContainer}>

					<div className={styles.valueSumbol} style={{top: 17}}>
					+
					</div>

					<div className={styles.valueCurrency}>
						{accountTo && getSymbolFromCurrency(accountTo.currency)}
					</div>

					<span className={styles.valueAmount}>
						<input
							className={cn (styles.valueAmountInput, {[styles.emptyInput]: amountTo === 0})}
							value={amountTo}
							onChange={(e) => setAmountTo(+e.target.value)}
							placeholder="0"
							onFocus={handleFocus}
							onBlur={(e) => {setAmountTo(+e.target.value)}}
							type='number'
						/>
					</span>
					
				</div>
			</>
			}

		</>
	)
	
});

export default ValueInput;