/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import cn from 'classnames';
import styles from './TableRow.module.css';
import CustomCheckbox from '../Checkbox/CustomCheckbox';
import GetOperationResp from '../../../api/finance/dto/Operations/GetOperationResp';
import moment from 'moment';
import AmountFinance from '../finance/amount/Amount';
import ApprovalButton from '../finance/approvalButton/ApprovalButton';

require('moment/locale/ru');

interface TableRowProps {
    operation: GetOperationResp,
    isSelected?: boolean;
    onCheckboxChange: (checked: boolean) => void;
		openPopup: (operation: GetOperationResp) => void;
		updateOperation: (operation: GetOperationResp) => void;
}

const TableRow: React.FC<TableRowProps> = ({
	operation,
	isSelected = false,
	onCheckboxChange,
	openPopup,
	updateOperation
}) => {

	const getCuurencySymbol = (currency: string) => {
		if (currency === 'RUB') {
			return ' ₽';
		} else if (currency === 'USD') {
			return ' $';
		}
		return currency;
	}

	return (
		<div className={cn(styles.tableRow, { [styles.checked]: isSelected })} onClick={() => openPopup(operation)}>

			<div className={styles.tableCellCheckbox}>
				<CustomCheckbox checked={isSelected} onChange={onCheckboxChange}/>
			</div>

			<div className={styles.tableCellDate}>
				<div className={styles.tableCellDateInfo}>
					{moment(operation.date).format('DD.MM.YYYY')}
				</div>
			</div>

			<div className={styles.tableCellTotal}>

				{operation?.from_account && operation?.amount_from &&

				<AmountFinance 
					amount={-operation?.amount_from} 
					currency={getCuurencySymbol(operation?.from_account?.currency ? operation?.from_account?.currency : '')} 
					isPlaned={operation.is_planned}
				/>
				}

				{operation?.to_account && operation?.amount_to &&
				
				<AmountFinance 
					amount={operation?.amount_to} 
					currency={getCuurencySymbol(operation?.to_account?.currency ? operation?.to_account?.currency : '')} 
					isPlaned={operation.is_planned}
				/>

				}

			</div>

			<div className={styles.tableCellDetails}>

				<div className={cn(styles.tableCellDetailsMain, {[styles.shortDescription] : operation.is_planned})}>
					{operation?.description}
				</div>

				<div className={cn(styles.tableCellDetailsSecond, {[styles.shortDescription] : operation.is_planned})}>

					<div className={styles.tableCellDetailsCategory}>
						{operation?.operation_category?.color && <div className={styles.categoryColor} style={{backgroundColor: operation?.operation_category?.color}}/>}
						<span>{operation?.operation_category?.name}</span>
					</div>

				</div>

				{operation.is_planned &&
				<ApprovalButton
					operation={operation}
					result={updateOperation}
				/>
				}

			</div>

			<div className={styles.tableCellContractor}>
				<div className={styles.tableCellContractorMain}>
					{operation?.counterparty?.name}
				</div>
			</div>

			<div className={styles.tableCellRequisite}>
				<div className={styles.tableCellDetailsMain}>
					{operation.amount_from ? operation?.from_account?.name : operation?.to_account?.name}
				</div>
			</div>

		</div>
	);
};

export default TableRow;
