/* eslint-disable no-unused-vars */
import { Managers } from '../../models/managers';
import ApiClient from '../ApiClient';
import { NiceResponse } from '../dto/NiceResponse';
import { DealLogResp } from './dto/GetDealsLogsResp.dto';
import Leagues from './dto/GetLeaguesResponse.dto';
import ManagersList from './dto/GetManagersListResponse.dto';
import NormsLead from './dto/GetNormsLead.dto';
import Tags from './dto/GetTagsResponse.dto';
import TypesDeal from './dto/GetTypesResponse.dto';

export class ManagersApi {
	constructor(private api: ApiClient) { }

	path = '/managers';

	async getManangers(roles: string[]) {
		const revenue = await this.api.client.post<NiceResponse<Managers[]>>(this.path + '/list', {roleName: roles});
		return revenue.data;
	}

	async getTags(search: string | null) {
		const revenue = await this.api.client.get<NiceResponse<Tags[]>>(search ? this.path + '/tags?search=' + search :  this.path + '/tags');
		return revenue.data;
	}

	async getTypesDelas() {
		const revenue = await this.api.client.get<NiceResponse<TypesDeal[]>>(this.path + '/deal-types');
		return revenue.data;
	}

	async addUpdateDealTypes(data: TypesDeal[]) {
		const revenue = await this.api.client.post<NiceResponse<null>>(this.path + '/deal-types', {data: data});
		return revenue.data;
	}

	async deleteTypesDeal(typeName: string) {
		const revenue = await this.api.client.delete<NiceResponse<null>>(this.path + '/deal-types?typeName=' + typeName);
		return revenue.data;
	}

	async getLeagues() {
		const revenue = await this.api.client.get<NiceResponse<Leagues[]>>(this.path + '/league');
		return revenue.data;
	}

	async addUpdateLeague(data: Leagues[]) {
		const revenue = await this.api.client.post<NiceResponse<null>>(this.path + '/league', {data: data});
		return revenue.data;
	}

	async getDealsTypesNames(name: string | null) {

		const params: { name?: string} = {}
	
		if (name && name !== '') {
			params.name = name
		}

		const resp = await this.api.client.get<NiceResponse<string[]>>(this.path + '/deal-types-name', {params});
		return resp.data;
	}

	async getListManagers() {
		const revenue = await this.api.client.get<NiceResponse<ManagersList[]>>(this.path + '/managers-list');
		return revenue.data;
	}

	async addUpdateManager(data: ManagersList[]) {
		const revenue = await this.api.client.post<NiceResponse<null>>(this.path + '/managers-list', {data: data});
		return revenue.data;
	}

	async getLeadNorms() {
		const revenue = await this.api.client.get<NiceResponse<NormsLead[]>>(this.path + '/need-leads');
		return revenue.data;
	}

	async getDealsLogs(data: {type_deal?: string, skip: number, take: number}) {
		const revenue = await this.api.client.post<NiceResponse<DealLogResp>>(this.path + '/deals-logs', data);
		return revenue.data;
	}
}