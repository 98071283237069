/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { observer } from 'mobx-react'
import Indicator from '../../../UI/Indicator/Indicator'
import { useEffect, useState } from 'react';
import TrfficStats from '../../../../api/analytics/dto/TrfficStatsResp.dto';
import { useApi } from '../../../../hooks/useApi';
import { useStores } from '../../../../hooks/useStore';
import { AppSettings } from '../../../../appSettings';

import countries from 'i18n-iso-countries';
import * as ru from 'i18n-iso-countries/langs/ru.json';
countries.registerLocale(ru);


const Telegram: React.FC = observer(() => {

	const api = useApi();
	const store = useStores();

	const [spents, setSpents] = useState<number>(0);
	const [trafficStats, setTrafficStats] = useState<TrfficStats>();

	useEffect(() => {
		(async () => {
			try {
				store.notificationsStore.showPreloader();

				const {body: trafficStatsRespGoogle} = await api.analytics.getTrafficStats({
					start_date: store.dateStore.startDate,
					end_date: store.dateStore.endDate,
					utm: ['telegram', 'tg'],
					bron_ids: AppSettings.bronIds
				});

				if (trafficStatsRespGoogle) {
					setTrafficStats(trafficStatsRespGoogle);
				}

				const {body: totalSpentRespGoogle} = await api.spents.getSpents({
					start_date: store.dateStore.startDate,
					end_date: store.dateStore.endDate,
					utm_source: ['telegram', 'tg'],
				});

				if (totalSpentRespGoogle) {
					const spentsData = totalSpentRespGoogle.map(spent => spent.spent);
					const totalSpent = spentsData.reduce((acc, spent) => acc + spent, 0);
					setSpents(totalSpent);
				}

			} catch (error) {
				store.notificationsStore.setError('Ошибка при загрузке данных')
			} finally {
				store.notificationsStore.hidePreloader();
			}
		})();
	}, [store.dateStore.startDate, store.dateStore.endDate]);
	
	return (
		<div>
			<div style={{display: 'flex', flexWrap: 'wrap'}}>

				{trafficStats &&

						<>

							<Indicator 
								name='Потрачено' 
								curentValue={spents}
								format='currency' 
								symbol='$'
							/>

							<Indicator 
								name='Новых контактов' 
								curentValue={trafficStats.conatcts_count}
								format='number'
							/>

							<Indicator 
								name='Регистраций' 
								curentValue={trafficStats.webinar_registrations}
								format='number'
							/>

							<Indicator 
								name='Чекинов' 
								curentValue={trafficStats.webinar_checkins}
								format='number'
							/>

							<Indicator 
								name='% в чекин' 
								curentValue={+(trafficStats.webinar_checkins / trafficStats.webinar_registrations * 100).toFixed(2)}
								format='percent'
							/>

							<Indicator 
								name='Броней' 
								curentValue={trafficStats.bron_count}
								format='number'
							/>

							<Indicator 
								name='Выручка' 
								curentValue={trafficStats.revenues}
								format='currency' 
								symbol='$'
							/>

							<Indicator 
								name='Продаж' 
								curentValue={trafficStats.sales}
								format='number'
							/>

							<Indicator 
								name='Средний чек' 
								curentValue={trafficStats.revenues / trafficStats.sales}
								format='currency' 
								symbol='$'
							/>

							<Indicator 
								name='Цена брони' 
								curentValue={spents / trafficStats.bron_count}
								format='currency' 
								symbol='$'
							/>

							<Indicator 
								name='ROAS' 
								curentValue={+(trafficStats.revenues / spents * 100).toFixed(2)}
								format='percent'
							/>

						</>

				}

				<div style={{width: '100%', height: 1, background: 'rgb(224, 224, 224)', marginTop: 20, marginBottom: 20}}/>

				<div>

					<h3 style={{marginBottom: 10}}>С учетом ранее зарегистрированных</h3>

					{trafficStats &&
							
							<div style={{display: 'flex', flexWrap: 'wrap'}}>
								<Indicator 
									name='Броней' 
									curentValue={trafficStats.bron_count_with_old}
									format='number'
								/>

								<Indicator 
									name='Цена брони' 
									curentValue={spents / trafficStats.bron_count_with_old}
									format='currency' 
									symbol='$'
								/>

								<Indicator 
									name='Выручка' 
									curentValue={trafficStats.revenues_with_old}
									format='currency' 
									symbol='$'
								/>

								<Indicator 
									name='Продаж' 
									curentValue={trafficStats.sales_with_old}
									format='number'
								/>

								<Indicator 
									name='Средний чек' 
									curentValue={trafficStats.revenues_with_old / trafficStats.sales_with_old}
									format='currency' 
									symbol='$'
								/>

							</div>

					}

				</div>

			</div>
		</div>
	)
})

export default Telegram;