/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './OperationsCategotyGroup.module.css';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/useStore';
import { useApi } from '../../../../hooks/useApi';
import TextBigInput from '../../../../components/UI/finance/textBig/TextBig';
import { FinanceOperationCategoryGroupModel, OperationTypes } from '../../../../api/finance/dto/FinanceTypes';

interface PopupProps {
	defaultValue: FinanceOperationCategoryGroupModel | null;
  onClose: () => void;
	update: () => void;
	result: (item: FinanceOperationCategoryGroupModel | null) => void;
	operationType: OperationTypes;
}

const OperationsCategotyGroupActionPopUP: React.FC<PopupProps> = observer(({ onClose, defaultValue = null, update, result, operationType }) => {

	const store = useStores();
	const api = useApi();

	const [name, setName] = useState<string | null>(null);

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	useEffect(() => {
		if (defaultValue) {
			setName(defaultValue.name);
		}
	}, [defaultValue]);

	const handleButtonClick = async () => {
		try {
			if (!name) {
				store.notificationsStore.setError('Введите название группы');
				return;
			}

			store.notificationsStore.showPreloader();

			if (!defaultValue) {
				const {body} = await api.finance.CreateOperationCategoryGroup({
					name,
					operation_type: operationType,
					
				});
				update();
				if(body) {result(body)}
			} else {
				const {body} = await api.finance.UpdateOperationCategoryGroup(defaultValue.id, {
					name,
					operation_type: operationType,
				});
				update();
				if(body) {result(body)}
			}
			
		} catch (e) {
			store.notificationsStore.setError(`${!defaultValue ? 'Ошибка создания группы' : 'Ошибка обновления группы'}`);
		} finally {
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications(`${!defaultValue ? 'Группа успешно создана' : 'Группа успешно обновлена'}`);
			onClose();
		}
	}

	const deleteItem = async () => {
		try {
			store.notificationsStore.showPreloader();

			if (defaultValue) {
				await api.finance.DeleteOperationCategoryGroup(defaultValue.id);
				return;
			}
		} catch (e) {
			store.notificationsStore.setError('Ошибка удаления группы');
		} finally {
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications('Группа успешно удалена');
			onClose();
		}
	}

	
	return (
		<div className={styles.container} onClick={handleOverlayClick}>
			<div className={styles.form}>

				

				<div className={styles.bodyFormContainer}>

					<TextBigInput
						placeholderText='Название группы'
						resultText={setName}
						marginTop={20}
						defaultValue={name}
					/>

				</div>

				<div className={styles.formActionsContainer}>

					<div className={styles.formActionsContainerLeft}>

						<button className={styles.formActionsButtonMain} onClick={handleButtonClick}>{!defaultValue ? 'Создать' : 'Изменить'}</button>

						<button className={styles.formActionsButtonSecond} onClick={onClose}>Отменить</button>

					</div>

					<div className={styles.formActionsContainerRight}>

						{defaultValue && <button style={{color: '#df3535'}} className={styles.formActionsButtonSecond} onClick={deleteItem}>Удалить</button>}

					</div>

				</div>

			</div>


		</div>
	);
});

export default OperationsCategotyGroupActionPopUP;
