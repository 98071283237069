import { AvatarProps } from './Avatar.props';
import styles from './Avatar.module.css';
import cn from 'classnames';
import React, { memo } from 'react';
import { ReactComponent as Setting } from './ico/setting.svg';
import { useStores } from '../../../hooks/useStore';

const Avatar = ({size, setting = false, profile = false, className} : AvatarProps) : JSX.Element => {

	const {userStore: {user}} = useStores();

	const smallAvatarUrl = !user?.avatar ? `${process.env.REACT_APP_DEFAULT_AVATAR_150}` : user?.avatar + '150x150.webp';
	const bigAvatarUrl = !user?.avatar ? `${process.env.REACT_APP_DEFAULT_AVATAR_500}` : user?.avatar + '500x500.webp';

	return (
		<>
			{!profile &&
		<div className={cn(styles.avatar, className)}>
			<img className={styles.avatarImg} src={smallAvatarUrl} alt='avatar' width={size} height={size}/>
			{setting && <Setting className={styles.setting}/>}
		</div>
			}
			{profile &&
			<img src={bigAvatarUrl} width='237px' height='237px' style={{borderRadius: '4px'}} alt='avatar-profile'/>
			}
		</>
	);
};

export default memo(Avatar);