import React, { useEffect } from 'react';
import styles from './Indicator.module.css';
import { IndicatorProps } from './Indicator.props';
import { formatNumberWithSpaces } from '../../../utils/numbers';
import ProgressBar from '../ProgressBar/ProgressBar';
import moment from 'moment';

const Indicator = ({ name, curentValue = 0, lastValue = 0, symbol, format = 'number'  }: IndicatorProps) => {

	const [formatedValue, setFormatedValue] = React.useState<string>('');
	const [formatedLastValue, setFormatedLastValue] = React.useState<string>('');

	
	const [percent, setPercent] = React.useState<number>(0);
	
	useEffect(() => {
		setPercent(+(curentValue / lastValue * 100).toFixed(2));

		if (format === 'currency' || format === 'number') {
			setFormatedValue(formatNumberWithSpaces(+curentValue.toFixed(2)));
			setFormatedLastValue(formatNumberWithSpaces(+lastValue.toFixed(2)));
		}
		if (format === 'percent') {
			setFormatedValue(`${curentValue}%`);
			setFormatedLastValue(`${lastValue}%`);
		}
		if (format === 'time') {
			setFormatedValue(secondsToDuration(curentValue));
			setFormatedLastValue(secondsToDuration(lastValue));
		}
		if (symbol) {
			setFormatedValue(`${formatNumberWithSpaces(+curentValue.toFixed(2))} ${symbol}`);
			setFormatedLastValue(`${formatNumberWithSpaces(+lastValue.toFixed(2))} ${symbol}`);
		}

	}, [curentValue, lastValue]);

	const secondsToDuration = (seconds: number) =>  {
		const duration = moment.duration(seconds, 'seconds');
		const hours = Math.floor(duration.asHours());
		const minutes = duration.minutes();
		const secondsNew = duration.seconds();
		const formattedDuration = `${hours}:${minutes}:${secondsNew}`;
		return formattedDuration;
	}

	return (
		<div className={styles.indicator}>
			<p className={styles.indicatorTitle}>{name}</p>
			<h2>{formatedValue}</h2>
			{percent >= 0 && percent != Infinity &&
			<>
				<p className={styles.indicatorPrecent} style={{color: percent > 100 ? '#4caf50' : '#F54F4F'}}>{percent}%</p>
				<ProgressBar percent={percent}/>
				<p className={styles.indicatorLastValue}>{formatedLastValue}</p>
			</>
			}
		</div>
	);
};

export default Indicator;
