/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './ColorPicker.module.css';
import cn from 'classnames';

interface ColorInputProps {
	result: (color: string | null) => void;
	defaultValue?: string | null;
	marginTop?: number;
}

const colorList = [
	'#f6402c',
	'#eb1360',
	'#9c1bb1',
	'#6633b9',
	'#3d4db7',
	'#47af4a',
	'#049687',
	'#04bbd5',
	'#01a6f6',
	'#297ebb',
	'#89c440',
	'#ccdd1d',
	'#ffec14',
	'#ffc100',
	'#ff9800',
	'#000000',
	'#5e7c8b',
	'#9d9d9d',
	'#7a5547',
	'#d35600'
]

const ColorPickerInput: React.FC<ColorInputProps> = observer(({result, marginTop = 0, defaultValue }) => {

	const [color, setColor] = useState<string | null>(null);

	useEffect(() => {
		result(color);
	}, [color]);

	useEffect(() => {
		if (defaultValue) {
			setColor(defaultValue);
		}
	}, [defaultValue]);

	return (
		<div className={styles.lineFormContainer} style={{marginTop}}>
			<div className={styles.lineFormLabel}>Цвет</div>

			<div className={styles.lineFormInputContainer}>

				<div className={styles.colorsContainer}>

					{colorList.map((item, index) => (
						<div 
							className={cn(styles.colorItem, { [styles.colorSelected]: color === item })} 
							key={index} 
							style={{backgroundColor: item}} 
							onClick={() => setColor(item)}>
						</div>
					))}

					<div 
						className={cn(styles.colorItem, { [styles.colorSelected]: color === null })} 
						onClick={() => setColor(null)}
					/>

				</div>
				

			</div>

		</div>

	)
	
});

export default ColorPickerInput;