import React from 'react';
import './TinyLoader.css'; // Вынесем стили в отдельный CSS файл
import cn from 'classnames';

type TinyLoaderProps = {
    width?: number;
    white?: boolean;
    blue?: boolean;
    red?: boolean;
    green?: boolean;
		className?: string;
};

const TinyLoader: React.FC<TinyLoaderProps> = ({
	width = 18,
	white = false,
	blue = false,
	red = false,
	green = false,
	className
}) => {
	// Определяем CSS классы для заливки в зависимости от пропсов
	const getRingClass = () => {
		if (white) {return '__white';}
		if (blue) {return '__blue';}
		if (red) {return '__red';}
		if (green) {return '__green';}
		return '';
	};

	return (
		<svg className={cn('ui-tiny-loader', className ? className : '')} width={width} height={width} viewBox="0 0 108 108">
			<path
				className={`ui-tiny-loader-ring ${getRingClass()}`}
				d="M.5 54c0 29.547234 23.952766 53.5 53.5 53.5s53.5-23.952766 53.5-53.5c0-16.916017-7.912898-32.534425-21.113145-42.586444C77.146073 4.376678 65.857265.5 54 .5c-4.142136 0-7.5 3.357864-7.5 7.5s3.357864 7.5 7.5 7.5c8.545335 0 16.64829 2.782628 23.299246 7.847346C86.81278 30.591926 92.5 41.817308 92.5 54c0 21.262963-17.237037 38.5-38.5 38.5S15.5 75.262963 15.5 54c0-4.142136-3.357864-7.5-7.5-7.5S.5 49.857864.5 54z"
			/>
		</svg>
	);
};

export default TinyLoader;
