import React from 'react';
import styles from './ProgressBar.module.css';
import { ProgressBarProps } from './ProgressBar.props';

const ProgressBar = ({ percent = 0 }: ProgressBarProps) => {
	return (
		<div className={styles.progressBarContainer}>
			<div
				className={styles.progressBar}
				style={{ width: `${percent}%`, backgroundColor: percent > 100 ? '#4caf50' : '#F54F4F' }}
			/>
		</div>
	);
};

export default ProgressBar;
