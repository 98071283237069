
export function formatMySQLDatetime(date: Date | undefined, endOfDay = false) {
	if (!date) {return '';}
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');

	// Устанавливаем время на 00:00:00
	let formattedDate = `${year}-${month}-${day} 00:00:00`;

	if (endOfDay) {
		// Устанавливаем время на 23:59:59
		formattedDate = `${year}-${month}-${day} 23:59:59`;
	}

	return formattedDate;

	// const time = moment.utc(formattedDate, 'YYYY-MM-DD HH:mm:ss').utcOffset(5);

	// formattedDate = time.format('YYYY-MM-DD HH:mm:ss');

	// return formattedDate;
}


export function isToday(dateString: string): boolean {
	const date = new Date(dateString);
	const today = new Date();
	return date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear();
}

export function isFutureDate(dateString: string): boolean {
	const date = new Date(dateString);
	const today = new Date();
	return date.getDate() > today.getDate() &&
		date.getMonth() >= today.getMonth() &&
		date.getFullYear() >= today.getFullYear();
}

export function roundDate(timestamp: number, unit: 'date' | 'month'): number {
	const date = new Date(timestamp);

	if (unit === 'date') {
		// Округление до начала дня
		date.setHours(0, 0, 0, 0);
	} else if (unit === 'month') {
		// Округление до начала месяца
		date.setDate(1);
		date.setHours(0, 0, 0, 0);
	}

	return date.getTime();
}

export function dateToYMD(dateInput: string | Date): string {
	const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;

	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');

	return `${year}${month}${day}`;
}


export function addDate(timestamp: number, amount: number, unit: 'day' | 'month'): number {
	const date = new Date(timestamp);

	if (unit === 'day') {
		// Добавление дней
		date.setDate(date.getDate() + amount);
	} else if (unit === 'month') {
		// Добавление месяцев
		date.setMonth(date.getMonth() + amount);
	}

	return date.getTime();
}