import React from 'react';
import styles from './Separator.module.css';

interface SeparatorProps {
    text?: string;
    noTopMargin?: boolean;
    colored?: boolean;
}

const Separator: React.FC<SeparatorProps> = ({ text, noTopMargin = false, colored = false }) => {
	const hasText = Boolean(text);
	const separatorClassName = [
		styles.uiSeparator,
		!hasText ? styles.uiSeparatorNoText : styles.uiSeparatorWithText,
		colored && hasText ? styles.uiSeparatorOrangeView : '',
	].join(' ');

	return (
		<div className={separatorClassName} style={{ marginTop: noTopMargin ? 0 : undefined }}>
			<div className={styles.uiSeparatorBorder}></div>
			{hasText && (
				<div className={styles.uiSeparatorTextBlockWrap}>
					<div className={styles.uiSeparatorTextBlockText}>
						{text}
					</div>
				</div>
			)}
			<div className={styles.uiSeparatorBorder}></div>
		</div>
	);
};

export default Separator;
