import React from 'react';
import styles from './Footer.module.css';
import { FooterProps } from './Footer.props';
import cn from 'classnames';


export const Footer = ({className, ...props}: FooterProps): JSX.Element => {
	return (
		<div {...props} className={cn(styles.footer, className)}>
			<div className={styles.links}>
				<a>Договор оферты</a>
				<a>Политика конфиденциальности</a>
			</div>
			<p className={styles.watermark}>© EVO, {new Date().getFullYear()}</p>
		</div>
	);
};
