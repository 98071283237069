/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './ApprovalButton.module.css';
import GetOperationResp from '../../../../api/finance/dto/Operations/GetOperationResp';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ReactComponent as CheckIcon } from '../ico/check.svg';
import { useStores } from '../../../../hooks/useStore';
import { useApi } from '../../../../hooks/useApi';
import moment from 'moment';
import { isFutureDate } from '../../../../utils/date';
import cn from 'classnames';

interface ApprovalButtonProps {
	operation: GetOperationResp;
	result: (operation: GetOperationResp) => void;
}

const ApprovalButton: React.FC<ApprovalButtonProps> = observer(({operation, result }) => {

	const store = useStores();
	const api = useApi();

	const [loading, setLoading] = useState(false);

	const updateOperation = async () => {
		try {
			setLoading(true);

			const {body} = await api.finance.UpdateOperation(operation.id, {
				operation_type: operation.operation_type,
				from_account: operation.from_account?.id ? operation.from_account?.id : null,
				to_account: operation.to_account?.id ? operation.to_account?.id : null,
				amount_from: operation.amount_from,
				amount_to: operation.amount_to,
				id_counterparty: operation.counterparty?.id ? operation.counterparty?.id : null,
				id_operation_category: operation.operation_category?.id ? operation.operation_category?.id : null,
				description: operation.description,
				date: moment(operation.date).format('YYYY-MM-DD'),
				is_planned: false,
				for_approval: false,
			});

			if (body) {
				result(body);
			}

		} catch (e) {
			store.notificationsStore.setError('Ошибка при загрузке операций');
		} finally {
			setLoading(false);
		}
	}

	return (
		<div className={cn({[styles.disabledButton] : isFutureDate(operation.date), [styles.approveButton] : !isFutureDate(operation.date)})} onClick={(e) => {if (!isFutureDate(operation.date)) { e.stopPropagation(); updateOperation()}}}>
			
			{!isFutureDate(operation.date) &&
			<div className={styles.approveButtonIcon}>
				{!loading && <CheckIcon/>}
				{loading &&
				<Box sx={{ display: 'flex', width: 12, height: 12, marginTop: 0.1 }}>
					<CircularProgress size={12} color='inherit'/>
				</Box>
				}
			</div>
			}

			<div className={cn(styles.approveButtonText, {[styles.disabledButtonText] : isFutureDate(operation.date)})}>
						ПРИНЯТЬ
			</div>

		</div>

	)
	
});

export default ApprovalButton;
