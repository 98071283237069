/* eslint-disable no-unused-vars */
import ApiClient from '../ApiClient';
import { NiceResponse } from '../dto/NiceResponse';
import CreateAccountsReq from './dto/Accounts/CreateAccountReq';
import UpdateAccountReq from './dto/Accounts/UpdateAccountReq';
import CreateCounterpartyReq from './dto/Counterparty/CreateCounterpartyReq';
import GetCounterpartReq from './dto/Counterparty/GetCounterpartReq';
import CreateCounterpartyGroupReq from './dto/CounterpartyGroup/CreateCounterpartyGroupReq';
import GetCounterpartyGroupReq from './dto/CounterpartyGroup/GetCounterpartyGroupReq';
import { FinanceAccountModel, FinanceCounterpartyGroupModel, FinanceCounterpartyModel, FinanceOperationCategoryGroupModel, FinanceOperationCategoryModel, OperationTypes } from './dto/FinanceTypes';
import CreateOperationCategory from './dto/OperationCategory/CreateOperationCategoryReq';
import GetOperationCategoryReq from './dto/OperationCategory/GetOperationCategoryReq';
import CreateOperationCategoryGroupReq from './dto/OperationCategoryGroup/CreateOperationCategoryGroupReq';
import GetOperationCategoryGroupReq from './dto/OperationCategoryGroup/GetOperationCategoryGroupReq';
import CreateOperationReq from './dto/Operations/CreateOperationReq';
import GetOperationReq from './dto/Operations/GetOperationReq';
import GetOperationResp from './dto/Operations/GetOperationResp';
import UpdateOperationReq from './dto/Operations/UpdateOperationReq';
import GetDailyReportReq from './dto/Reports/GetDailyReportReq';
import GetDailyReportResp from './dto/Reports/GetDailyReportResp';

export class FinanceApi {
	constructor(private api: ApiClient) { }

	path = '/finance';

	async CreateAccount(req: CreateAccountsReq) {
		const response = await this.api.client.post<NiceResponse<null>>(this.path + '/account', { ...req});
		return response.data;
	}

	async GetAccounts() {
		const response = await this.api.client.get<NiceResponse<FinanceAccountModel[]>>(this.path + '/accounts');
		return response.data;
	}

	async UpdateAccount(req: UpdateAccountReq) {
		const response = await this.api.client.put<NiceResponse<null>>(this.path + '/account', { ...req});
		return response.data;
	}

	async DeleteAccount(id: string) {
		const response = await this.api.client.delete<NiceResponse<null>>(this.path + '/account/' + id);
		return response.data;
	}

	async CreateOperation(req: CreateOperationReq) {
		const response = await this.api.client.post<NiceResponse<GetOperationResp>>(this.path + '/operation', { ...req});
		return response.data;
	}

	async GetOperations(req: GetOperationReq) {
		const response = await this.api.client.get<NiceResponse<GetOperationResp[]>>(this.path + '/operations', { params: { startDate: req.startDate, endDate: req.endDate, skip: req.skip, take: req.take } });
		return response.data;
	}

	async UpdateOperation(id: string, req: UpdateOperationReq) {
		const response = await this.api.client.put<NiceResponse<GetOperationResp>>(this.path + '/operation/' + id, { ...req});
		return response.data;
	}

	async DeleteOperation(id: string) {
		const response = await this.api.client.delete<NiceResponse<null>>(this.path + '/operation/' + id);
		return response.data;
	}

	async CreateCounterparty(req: CreateCounterpartyReq) {
		const response = await this.api.client.post<NiceResponse<null>>(this.path + '/counterparty', { ...req});
		return response.data;
	}

	async GetCounterparties(req: GetCounterpartReq) {
		
		const params: { skip: number; take: number; name?: string  } = {
			skip: req.skip,
			take: req.take,
		}
	
		if (req.name && req.name !== '') {
			params.name = req.name
		}

		const response = await this.api.client.get<NiceResponse<FinanceCounterpartyModel[]>>(this.path + '/counterparties', { params });
		return response.data;
	}

	async UpdateCounterparty(id: string, req: CreateCounterpartyReq) {
		const response = await this.api.client.put<NiceResponse<null>>(this.path + '/counterparty/' + id, { ...req});
		return response.data;
	}

	async DeleteCounterparty(id: string) {
		const response = await this.api.client.delete<NiceResponse<null>>(this.path + '/counterparty/' + id);
		return response.data;
	}

	async CreateCounterpartyGroup(req: CreateCounterpartyGroupReq) {
		const response = await this.api.client.post<NiceResponse<FinanceCounterpartyGroupModel>>(this.path + '/counterparty-group', { ...req});
		return response.data;
	}

	async GetCounterpartyGroups(req: GetCounterpartyGroupReq) {
		const params: { skip: number; take: number; name?: string  } = {
			skip: req.skip,
			take: req.take,
		}
	
		if (req.name && req.name !== '') {
			params.name = req.name
		}

		const response = await this.api.client.get<NiceResponse<FinanceCounterpartyGroupModel[]>>(this.path + '/counterparty-groups', { params });
		return response.data;
	}

	async UpdateCounterpartyGroup(id: string, req: CreateCounterpartyGroupReq) {
		const response = await this.api.client.put<NiceResponse<FinanceCounterpartyGroupModel>>(this.path + '/counterparty-group/' + id, { ...req});
		return response.data;
	}

	async DeleteCounterpartyGroup(id: string) {
		const response = await this.api.client.delete<NiceResponse<null>>(this.path + '/counterparty-group/' + id);
		return response.data;
	}

	async CreateOperationCategory(req: CreateOperationCategory) {
		const response = await this.api.client.post<NiceResponse<null>>(this.path + '/operation-category', { ...req});
		return response.data;
	}

	async GetOperationCategories(req: GetOperationCategoryReq) {
		const params: { skip: number; take: number; name?: string, operationType?: string  } = {
			skip: req.skip,
			take: req.take,
		}
	
		if (req.name && req.name !== '') {
			params.name = req.name
		}

		if (req.operationType && req.operationType !== '') {
			params.operationType = req.operationType
		}

		const response = await this.api.client.get<NiceResponse<FinanceOperationCategoryModel[]>>(this.path + '/operation-categories', { params });
		return response.data;
	}

	async UpdateOperationCategory(id: string, req: CreateOperationCategory) {
		const response = await this.api.client.put<NiceResponse<null>>(this.path + '/operation-category/' + id, { ...req});
		return response.data;
	}

	async DeleteOperationCategory(id: string) {
		const response = await this.api.client.delete<NiceResponse<null>>(this.path + '/operation-category/' + id);
		return response.data;
	}

	async CreateOperationCategoryGroup(req: CreateOperationCategoryGroupReq) {
		const response = await this.api.client.post<NiceResponse<FinanceOperationCategoryGroupModel>>(this.path + '/operation-category-group', { ...req});
		return response.data;
	}

	async GetOperationCategoryGroups(req: GetOperationCategoryGroupReq) {
		const params: { skip: number; take: number; name?: string, typeOperation?: OperationTypes  } = {
			skip: req.skip,
			take: req.take,
		}
	
		if (req.name && req.name !== '') {
			params.name = req.name
		}

		if (req.typeOperation) {
			params.typeOperation = req.typeOperation
		}

		const response = await this.api.client.get<NiceResponse<FinanceOperationCategoryGroupModel[]>>(this.path + '/operation-category-groups', { params });
		return response.data;
	}

	async UpdateOperationCategoryGroup(id: string, req: CreateOperationCategoryGroupReq) {
		const response = await this.api.client.put<NiceResponse<FinanceOperationCategoryGroupModel>>(this.path + '/operation-category-group/' + id, { ...req});
		return response.data;
	}

	async DeleteOperationCategoryGroup(id: string) {
		const response = await this.api.client.delete<NiceResponse<null>>(this.path + '/operation-category-group/' + id);
		return response.data;
	}

	async GetDailyReport(req: GetDailyReportReq) {
		const response = await this.api.client.get<NiceResponse<GetDailyReportResp[]>>(this.path + '/daily-report', { params: { startDate: req.startDate, endDate: req.endDate, currency: req.currency } });
		return response.data;
	}

}