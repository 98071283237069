/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './Radio.module.css';
import cn from 'classnames';
import { OperationCategoryTypes } from '../../../../api/finance/dto/FinanceTypes';

interface RadioInputProps {
	result: (value: OperationCategoryTypes) => void;
	value: OperationCategoryTypes;
	labelText: string;
	checked?: boolean;
	additionalLabelText?: string;
	additionalLabelClass?: string;
}

const RadioInput: React.FC<RadioInputProps> = observer(({result, labelText, checked, value, additionalLabelText, additionalLabelClass }) => {

	return (
		<label className={styles.radioContainer}>
			<input
				type="radio"
				checked={checked}
				onChange={() => result(value)}
				className={styles.radio}
			/>
			{' ' + labelText + ' '}
			{ additionalLabelText && <span className={cn(styles.radioType, additionalLabelClass)}>{additionalLabelText}</span>}
		</label>

	)
	
});

export default RadioInput;