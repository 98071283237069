export const getTitleName = (location: string): string => {
	if (location === '/') { return 'Вход | EVO'; }
	if (location === '/registration') { return 'Регистрация | EVO'; }
	if (location === '/forget-password') { return 'Востановление пароля | EVO'; }
	if (location === '/reset-password') { return 'Изменение пароля | EVO'; }

	if (location === '/profile') { return 'Профиль | EVO'; }
	if (location === '/dashboard') { return 'Дашборд | EVO'; }
	

	else { return 'EVO'; }
};