/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './TypeCategoryOperation.module.css';
import { OperationCategoryTypes, OperationTypes } from '../../../../api/finance/dto/FinanceTypes';
import cn from 'classnames';
import RadioInput from '../radio/Radio';
import NalogTypeInput from '../nalogType/NalogType';

interface CheckboxInputProps {
	labelText: string;
	result: (iteam: string, nalogType: string | null) => void;
	operationType: OperationTypes;
	defaultValue?: OperationCategoryTypes;
	marginTop?: number;
}

const TypeCategoryOperationInput: React.FC<CheckboxInputProps> = observer(({result, operationType, labelText, marginTop = 0, defaultValue }) => {

	const [selectedValue, setSelectedValue] = useState<OperationCategoryTypes>(defaultValue || operationType === 'expense' ? 'expense_core_business' : operationType === 'income' ? 'income_core_business' : 'transfer_core_business');
	const [nalogType, setNalogType] = useState<string | null>(null);

	useEffect(() => {
		let nalogNew = nalogType

		if (selectedValue !== 'expense_tax_payment') {
			nalogNew = null;
			setNalogType(null);
		}

		result(selectedValue, nalogNew);
	}, [selectedValue]);

	return (
		<div className={styles.lineFormContainer} style={{ marginTop }}>
			<div className={styles.lineFormLabel}>{labelText}</div>

			<div className={styles.lineFormInputContainer}>

				{operationType === 'expense' &&

				<>

					<RadioInput
						result={(value) => setSelectedValue(value)}
						checked={selectedValue === 'expense_core_business'}
						value='expense_core_business'
						labelText='на основную деятельность'
						additionalLabelText='Операционная'
						additionalLabelClass={styles.operationType}
					/>

					<RadioInput
						result={(value) => setSelectedValue(value)}
						checked={selectedValue === 'expense_profit_withdrawal_or_dividends'}
						value='expense_profit_withdrawal_or_dividends'
						labelText='вывод прибыли или дивиденды'
						additionalLabelText='Операционная'
						additionalLabelClass={styles.operationType}
					/>

					<RadioInput
						result={(value) => setSelectedValue(value)}
						checked={selectedValue === 'expense_capital_investments'}
						value='expense_capital_investments'
						labelText='на основные средства, капитальные вложения'
						additionalLabelText='Инвестиционная'
						additionalLabelClass={styles.investType}
					/>

					<RadioInput
						result={(value) => setSelectedValue(value)}
						checked={selectedValue === 'expense_loan_repayment'}
						value='expense_loan_repayment'
						labelText='выплата тела кредита, займа или возвратного депозита'
						additionalLabelText='Финансовая'
						additionalLabelClass={styles.financialType}
					/>

					<RadioInput
						result={(value) => setSelectedValue(value)}
						checked={selectedValue === 'expense_interest_repayment'}
						value='expense_interest_repayment'
						labelText='погашение процентов'
						additionalLabelText='Операционная'
						additionalLabelClass={styles.operationType}
					/>

					<RadioInput
						result={(value) => setSelectedValue(value)}
						checked={selectedValue === 'expense_tax_payment'}
						value='expense_tax_payment'
						labelText='оплата налогов'
						additionalLabelText='Операционная'
						additionalLabelClass={styles.operationType}
					/>

					{selectedValue === 'expense_tax_payment' && 
					<NalogTypeInput
						result={(value) => setNalogType(value)}
						defaultValue={nalogType}
					/>
					}

				</>

				}

				{operationType === 'transfer' &&

					<>

						<RadioInput
							result={(value) => setSelectedValue(value)}
							checked={selectedValue === 'transfer_core_business'}
							value='transfer_core_business'
							labelText='Перевод между счетами'
							additionalLabelText='Операционная'
							additionalLabelClass={styles.operationType}
						/>

					</>

				}

				{operationType === 'income' &&

				<>

					<RadioInput
						result={(value) => setSelectedValue(value)}
						checked={selectedValue === 'income_core_business'}
						value='income_core_business'
						labelText='от основной деятельности'
						additionalLabelText='Операционная'
						additionalLabelClass={styles.operationType}
					/>

					<RadioInput
						result={(value) => setSelectedValue(value)}
						checked={selectedValue === 'income_capital_injection'}
						value='income_capital_injection'
						labelText='ввод капитала'
						additionalLabelText='Операционная'
						additionalLabelClass={styles.operationType}
					/>

					<RadioInput
						result={(value) => setSelectedValue(value)}
						checked={selectedValue === 'income_sale_fixed_assets'}
						value='income_sale_fixed_assets'
						labelText='от продажи основных средств'
						additionalLabelText='Инвестиционная'
						additionalLabelClass={styles.investType}
					/>

					<RadioInput
						result={(value) => setSelectedValue(value)}
						checked={selectedValue === 'income_sale_borrowing'}
						value='income_sale_borrowing'
						labelText='получение кредита, займа или возвратного депозита'
						additionalLabelText='Финансовая'
						additionalLabelClass={styles.financialType}
					/>

				</>

				}

				<div 
					className={
						cn(styles.inform,{
							[styles.operationType]: selectedValue === 'expense_core_business' || 
							selectedValue === 'expense_profit_withdrawal_or_dividends' || 
							selectedValue === 'expense_interest_repayment' || 
							selectedValue === 'expense_tax_payment' ||
							selectedValue === 'transfer_core_business' ||
							selectedValue === 'income_core_business' ||
							selectedValue === 'income_capital_injection',
							[styles.investType]: selectedValue === 'expense_capital_investments' || selectedValue === 'income_sale_fixed_assets',
							[styles.financialType]: selectedValue === 'expense_loan_repayment' || selectedValue === 'income_sale_borrowing',
						})
					}
				>
					<ul>

						{selectedValue === 'expense_core_business' &&
						<>
							<li>Например, закупка товара, выплата ЗП, аренда офиса, реклама</li>
							<li>Уменьшает нераспределенную прибыль в отчете <b>Баланс</b></li>
							<li><b>Операционная деятельность</b> это все движения денег, связанные с основной ежедневной работой бизнеса, которая обеспечивает прибыль</li>
						</>
						}

						{selectedValue === 'expense_profit_withdrawal_or_dividends' &&
						<>
							<li>Уменьшает нераспределенную прибыль в <b>Балансе</b></li>
							<li>Не влияет на чистую прибыль в <b>Отчете о прибылях и убытках</b></li>
						</>
						}

						{selectedValue === 'expense_capital_investments' &&
						<>
							<li>Например, покупка станка, автомобиля, склада, кофемашины</li>
							<li>Не влияет на <b>Отчет о прибылях и убытках</b></li>
							<li><b>Инвестиционная деятельность</b> это покупка, продажа и обслуживание основных средств: оборудования, имущества, значимых объектов вашей инфраструктуры. Как бы инвестируете собственные деньги в себя</li>
						</>
						}

						{selectedValue === 'expense_loan_repayment' &&
						<>
							<li>Возврат заёмных средств (но не процентов по кредиту — это уже операционные расходы)</li>
							<li>Также возврат/оплата возвращаемых депозитов</li>
							<li>Не влияет на <b>Отчет о прибылях и убытках</b></li>
							<li><b>Финансовая деятельность</b> это кредиты и инвестиционные транши. Вы просто получаете (или отдаете) деньги. Дальше они могут пойти в инвестиционную или операционную деятельность</li>
						</>
						}

						{selectedValue === 'expense_interest_repayment' &&
						<>
							<li>Выплата процентов по кредитам. Попадает в отдельную графу в <b>Отчете о прибылях и убытках</b></li>
							<li>Уменьшает чистую прибыль</li>
							<li>Уменьшает нераспределенную прибыль в отчете <b>Баланс</b></li>
							<li><b>Операционная деятельность</b> это все движения денег, связанные с основной ежедневной работой бизнеса, которая обеспечивает прибыль</li>
						</>
						}

						{selectedValue === 'expense_tax_payment' &&
						<>
							<li>Уменьшает чистую прибыль в <b>Отчете о прибылях и убытках</b></li>
							<li><b>Операционная деятельность</b> это все движения денег, связанные с основной ежедневной работой бизнеса, которая обеспечивает прибыль</li>
						</>
						}

						{selectedValue === 'transfer_core_business' &&
						<>
							<li>Переводы между любыми вашими счетами</li>
							<li><b>Операционная деятельность</b> это все движения денег, связанные с основной ежедневной работой бизнеса, которая обеспечивает прибыль</li>
						</>
						}

						{selectedValue === 'income_core_business' &&
						<>
							<li>Например, оплата от клиента</li>
							<li><b>Операционная деятельность</b> это все движения денег, связанные с основной ежедневной работой бизнеса, которая обеспечивает прибыль</li>
						</>
						}

						{selectedValue === 'income_capital_injection' &&
						<>
							<li>Например, уставной капитал, ввод денег в бизнес собственником</li>
							<li>Не влияет на <b>Отчет о прибылях и убытках</b></li>
							<li>Увеличивает капитал в отчете <b>Баланс</b></li>
						</>
						}

						{selectedValue === 'income_sale_fixed_assets' &&
						<>
							<li>Например, вы продаете старую технику — деньги от продажи не будут считаться прибылью</li>
							<li>Не влияет на <b>Отчет о прибылях и убытках</b></li>
							<li><b>Инвестиционная деятельность</b> это покупка, продажа и обслуживание основных средств: оборудования, имущества, значимых объектов вашей инфраструктуры</li>
						</>
						}

						{selectedValue === 'income_sale_borrowing' &&
						<>
							<li>Заёмные средства, которые потом нужно возвращать</li>
							<li>Также возврат/получение возвратных депозитов</li>
							<li>Не влияет на <b>Отчет о прибылях и убытках</b></li>
							<li><b>Финансовая деятельность</b> это кредиты и инвестиционные транши. Вы просто получаете (или отдаете) деньги. Дальше они могут пойти в инвестиционную или операционную деятельность</li>
						</>
						}

					</ul>
				</div>

			</div>
		</div>

	)
	
});

export default TypeCategoryOperationInput;