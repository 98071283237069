/* eslint-disable no-unused-vars */
import { AppSettings } from '../../appSettings';
import { BaseInfo, Calls, CountryInfo, ManagersTable, Revenue, RevenueInfo, RevenueProducts } from '../../models/analytics';
import ApiClient from '../ApiClient';
import { NiceResponse } from '../dto/NiceResponse';
import ConversionManagersRequest from './dto/ConversionManagersRequest.dto';
import ConversionManagers from './dto/ConversionManagersResp.dto';
import DateRequest from './dto/DateRequest.dto';
import DealTypesStats from './dto/DialTypesStatsResp.dto';
import FbSpentCountry from './dto/GetFbSpentCountry.dto';
import FbSpentUrl from './dto/GetFbSpentUrl.dto';
import GetManagerStatsRequest from './dto/GetManagerStatsRequest.dto';
import ProductUrls from './dto/ProductUrls.dto';
import ProductWebinars from './dto/ProductWebinars.dto';
import RepeatSales from './dto/RepeatSales';
import TrfficStatsRequest from './dto/TrfficStatsRequest.dto';
import TrfficStats from './dto/TrfficStatsResp.dto';
import WebinarsRevenueReq from './dto/WebinarsRevenueReq.dto';
import WebinarsRevenueResp from './dto/WebinarsRevenueResp.dto';
import WebStatsRequest from './dto/WebStatsRequest.dto';
import WebStatsResp from './dto/WebStatsResp.dto';

export class AnalyticsApi {
	constructor(private api: ApiClient) { }

	path = '/analytics';

	async getRevenue(req: DateRequest) {
		const revenue = await this.api.client.post<NiceResponse<Revenue[]>>(this.path + '/revenue', { ...req});
		return revenue.data;
	}

	async getRevenueProducts(req: DateRequest) {
		const revenue = await this.api.client.post<NiceResponse<RevenueProducts[]>>(this.path + '/revenue-products', { ...req});
		return revenue.data;
	}

	async getRevenueInfo(req: DateRequest) {
		const revenue = await this.api.client.post<NiceResponse<RevenueInfo>>(this.path + '/revenue-info', { ...req});
		return revenue.data;
	}
	
	async getManagersStats(req: GetManagerStatsRequest) {
		const revenue = await this.api.client.post<NiceResponse<ManagersTable[]>>(this.path + '/managers-table', { ...req, income_stages: AppSettings.income_stages, in_work_stages: AppSettings.in_work_stages, realized_stages: AppSettings.realized_stages, unrealized_stages: AppSettings.unrealized_stages});
		return revenue.data;
	}

	async getBaseData(req: DateRequest) {
		const revenue = await this.api.client.post<NiceResponse<BaseInfo>>(this.path + '/base-data', { ...req});
		return revenue.data;
	}

	async getBaseTotal() {
		const revenue = await this.api.client.post<NiceResponse<BaseInfo>>(this.path + '/base-data-total');
		return revenue.data;
	}

	async getDealTypesStats(req: GetManagerStatsRequest) {
		const revenue = await this.api.client.post<NiceResponse<DealTypesStats[]>>(this.path + '/deal-types-stats', { ...req});
		return revenue.data;
	}

	async getSpendFbUrl(req: DateRequest) {
		const revenue = await this.api.client.post<NiceResponse<FbSpentUrl[]>>(this.path + '/spend-fb-url', { ...req});
		return revenue.data;
	}

	async getTrafficStats(req: TrfficStatsRequest) {
		const revenue = await this.api.client.post<NiceResponse<TrfficStats>>(this.path + '/traffic-stats', { ...req});
		return revenue.data;
	}

	async getRevenueCountry(req: DateRequest) {
		const revenue = await this.api.client.post<NiceResponse<CountryInfo[]>>(this.path + '/revenue-country', { ...req});
		return revenue.data;
	}

	async getSpendFbCountry(req: DateRequest) {
		const revenue = await this.api.client.post<NiceResponse<FbSpentCountry[]>>(this.path + '/spend-fb-country', { ...req});
		return revenue.data;
	}

	async getUniqUrls() {
		const revenue = await this.api.client.get<NiceResponse<string[]>>(this.path + '/get-unique-urls');
		return revenue.data;
	}

	async addUrlForProducts(data: ProductUrls[]) {
		const revenue = await this.api.client.post<NiceResponse<string>>(this.path + '/add-url-for-product', { data });
		return revenue.data;
	}

	async getUrlsForProducts() {
		const revenue = await this.api.client.get<NiceResponse<ProductUrls[]>>(this.path + '/get-all-urls-for-products');
		return revenue.data;
	}

	async getWebinarNames() {
		const revenue = await this.api.client.get<NiceResponse<string[]>>(this.path + '/get-webinars-name');
		return revenue.data;
	}

	async addWebinarForProducts(data: ProductWebinars[]) {
		const revenue = await this.api.client.post<NiceResponse<string>>(this.path + '/add-web-for-product', { data });
		return revenue.data;
	}

	async getWebinarsForProducts() {
		const revenue = await this.api.client.get<NiceResponse<ProductWebinars[]>>(this.path + '/get-all-web-for-products');
		return revenue.data;
	}

	async getWebinarsStatsByName(req: WebStatsRequest) {
		const revenue = await this.api.client.post<NiceResponse<WebStatsResp[]>>(this.path + '/get-webinars-stats-by-name', { ...req});
		return revenue.data;
	}

	async getWebinarsRevenue(req: WebinarsRevenueReq) {
		const revenue = await this.api.client.post<NiceResponse<WebinarsRevenueResp[]>>(this.path + '/get-sales-for-webinars', { ...req});
		return revenue.data;
	}

	async getConversionManagers(req: ConversionManagersRequest) {
		const revenue = await this.api.client.post<NiceResponse<ConversionManagers[]>>(this.path + '/conversions-managers', { ...req});
		return revenue.data;
	}

	async getRepeatSales(endDate: string) {
		const revenue = await this.api.client.post<NiceResponse<RepeatSales>>(this.path + '/repeat-sales', { end_date: endDate });
		return revenue.data;
	}

	async getCalls(req: DateRequest) {
		const resp = await this.api.client.post<NiceResponse<Calls[]>>(this.path + '/calls', { ...req});
		return resp.data;
	}
}