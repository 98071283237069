/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './CreateAccount.module.css';
import { ReactComponent as Close } from '../../ico/close.svg';
import { Input } from '../../../../../components/UI/input/input';
import { Button } from '../../../../../components/UI/Button/Button';
import { useApi } from '../../../../../hooks/useApi';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../hooks/useStore';
import Select from '../../../../../components/UI/Select/Select';
import { FinanceAccountModel } from '../../../../../api/finance/dto/FinanceTypes';

interface PopupProps {
	account: FinanceAccountModel | null;
  onClose: () => void;
	updateAccounts: () => void;
}

const CreateUpdateAccountPopUP: React.FC<PopupProps> = observer(({ onClose, updateAccounts, account = null }) => {

	const store = useStores();
	const api = useApi();

	const [accountName, setAccountName] = useState<string>('');
	const [initialBalance, setInitialBalance] = useState<number>(0);

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	useEffect(() => {
		if (account) {
			setAccountName(account.name);
			setInitialBalance(account.initial_balance);
			setSelectedCurrency(account.currency);
		} else {
			setAccountName('');
			setInitialBalance(0);
			setSelectedCurrency('USD');
		}
	}, [account]);

	const handleButtonClick = async () => {
		try {
			store.notificationsStore.showPreloader();

			if (account) {
				await api.finance.UpdateAccount({
					id: account.id,
					name: accountName,
					currency: selectedCurrency,
					initial_balance: initialBalance
				});
				return;
			}

			await api.finance.CreateAccount({
				name: accountName,
				currency: selectedCurrency,
				initial_balance: initialBalance
			});

		} catch (e) {
			store.notificationsStore.setError(`${!account ? 'Ошибка создания счета' : 'Ошибка обновления счета'}`);
		} finally {
			updateAccounts();
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications(`${!account ? 'Счет успешно создан' : 'Счет успешно обновлен'}`);
			onClose();
		}
	}

	const deleteAccount = async () => {
		try {
			store.notificationsStore.showPreloader();

			if (account) {
				await api.finance.DeleteAccount(account.id);
				return;
			}

		} catch (e) {
			store.notificationsStore.setError('Ошибка удаления счета');
		} finally {
			updateAccounts();
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications('Счет успешно удален');
			onClose();
		}
	}

	const [selectedCurrency, setSelectedCurrency] = useState<string>('USD');

	const currenceList = [
		{
			id: 'RUB',
			name: 'RUB'
		},
		{
			id: 'USD',
			name: 'USD'
		}
	]
	
	return (
		<>
			<div className={styles.container} onClick={handleOverlayClick}>
				<div className={styles.form}>
					<Close className={styles.close} onClick={onClose}/>
					<h3>{`${!account ? 'Создание нового счета' : 'Обновление счета'}`}</h3>
					<div className={styles.line} style={{marginBottom: 10}}/>

					<Input 
						style={{maxWidth: '90%', marginBottom: 10}} 
						placeholder='Название счета' 
						textLabel='Название счета' 
						onChange={(event) => setAccountName(event.target.value)}
						type='text'
						defaultValue={account ? account?.name : ''}
					/>

					<Select 
						style={{maxWidth: '90%', marginBottom: 10}} 
						listCategories={currenceList}
						selectedValue={account ? account?.currency : selectedCurrency}
						onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setSelectedCurrency(event.target.value)}
					/>

					<Input 
						style={{maxWidth: '90%'}} 
						placeholder='Начальный баланс' 
						textLabel='Начальный баланс' 
						onChange={(event) => setInitialBalance(+event.target.value)}
						type='number'
						defaultValue={account ? account?.initial_balance : 0}
					/>

					
					<div className={styles.line} style={{marginBottom: 10}}/>

					<div className={styles.buttonsContainer}>

						<Button onClick={handleButtonClick} style={{margin: 5, maxWidth: 250}} appearance='auth'>
							{`${!account ? 'Создать счет' : 'Обновить счет'}`}
						</Button>

						{account &&
						<Button onClick={deleteAccount} style={{margin: 5, maxWidth: 250}} appearance='link'>
						Удалить счет
						</Button>
						}

					</div>

				</div>
			</div>
		</>
	);
});

export default CreateUpdateAccountPopUP;
