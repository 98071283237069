/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import styles from './base.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
// import { LineChart } from '@mui/x-charts/LineChart';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { formatNumberWithSpaces } from '../../utils/numbers';
import Indicator from '../../components/UI/Indicator/Indicator';
require('moment/locale/ru');

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TooltipComparison from '../../components/UI/TooltipComparison/tooltipComparison';
import { BaseInfo, CountryInfo } from '../../models/analytics';
import { TablePagination } from '@mui/material';

type formatedUserInfo = {
	date: string;
	value: number;
	dateLast?: string;
	valueLast?: number;
	symbol?: string;
}

const Base: React.FC = observer(() => {
	const api = useApi();
	const store = useStores();

	const [baseInfoCurrent, setBaseInfoCurrent] = useState<BaseInfo>();
	const [baseInfoLast, setBaseInfoLast] = useState<BaseInfo>();
	const [baseInfoTotal, setBaseInfoTotal] = useState<BaseInfo>();

	const [baseInfoFormated, setBaseInfoFormated] = useState<formatedUserInfo[]>([]);

	useEffect(() => {

		const startDate = moment(store.dateStore.startDate);
		const endDate = moment(store.dateStore.endDate);

		const diffDays = endDate.diff(startDate, 'days') + 1;

		if (!baseInfoCurrent?.user_per_day) {
			return;
		}

		setBaseInfoFormated(baseInfoCurrent?.user_per_day?.map(item => {
			return {
				date: moment(item.date).format('DD MMM'),
				value: item.users_count,
				dateLast: moment(item.date).subtract(diffDays, 'days').format('DD MMM'),
				valueLast: baseInfoLast?.user_per_day?.find(itemLast => moment(itemLast.date).format('DD MMM') === moment(item.date).subtract(diffDays, 'days').format('DD MMM'))?.users_count,
				symbol: 'конт.'
			}
		}));

	}, [baseInfoCurrent, baseInfoLast]);

	//Страны текущие
	const [currentCountry, setCurrentCountry] = useState<CountryInfo[]>();
	const [pageCountry, setPageCountry] = useState(0);
	const [rowsPerPageCountry, setRowsPerPageCountry] = useState(10);

	const handleChangePageCountry = (event: unknown, newPage: number) => {
		setPageCountry(newPage);
	};

	const handleChangeRowsPerPageCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPageCountry(+event.target.value);
		setPageCountry(0);
	};

	//UTM Source
	const [currentUtmSource, setCurrentUtmSource] = useState<{utm_name: string, total: number}[]>();
	const [pageUtmSource, setPageUtmSource] = useState(0);
	const [rowsPerPageUtmSource, setRowsPerPageUtmSource] = useState(10);

	const handleChangePageUtmSource = (event: unknown, newPage: number) => {
		setPageUtmSource(newPage);
	};

	const handleChangeRowsPerPageUtmSource = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPageUtmSource(+event.target.value);
		setPageUtmSource(0);
	};

	//UTM Medium
	const [currentUtmMedium, setCurrentUtmMedium] = useState<{utm_name: string, total: number}[]>();
	const [pageUtmMedium, setPageUtmMedium] = useState(0);
	const [rowsPerPageUtmMedium, setRowsPerPageUtmMedium] = useState(10);

	const handleChangePageUtmMedium = (event: unknown, newPage: number) => {
		setPageUtmMedium(newPage);
	};

	const handleChangeRowsPerPageUtmMedium = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPageUtmMedium(+event.target.value);
		setPageUtmMedium(0);
	};

	//UTM Campaign
	const [currentUtmCampaign, setCurrentUtmCampaign] = useState<{utm_name: string, total: number}[]>();
	const [pageUtmCampaign, setPageUtmCampaign] = useState(0);
	const [rowsPerPageUtmCampaign, setRowsPerPageUtmCampaign] = useState(10);

	const handleChangePageUtmCampaign = (event: unknown, newPage: number) => {
		setPageUtmCampaign(newPage);
	};

	const handleChangeRowsPerPageUtmCampaign = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPageUtmCampaign(+event.target.value);
		setPageUtmCampaign(0);
	};

	useEffect(() => {
		if (baseInfoCurrent?.countries && baseInfoCurrent?.countries) {
			setCurrentCountry(
				baseInfoCurrent?.countries?.filter(item => item.country !== 'Unknown').sort((a, b) => b.total - a.total)
			)
		}

		if (baseInfoCurrent?.utm_source) {
			setCurrentUtmSource(
				baseInfoCurrent?.utm_source
					.filter(item => item.utm_name !== 'Unknown')
					.sort((a, b) => b.total - a.total)
			)
		}

		if (baseInfoCurrent?.utm_medium) {
			setCurrentUtmMedium(
				baseInfoCurrent?.utm_medium
					.filter(item => item.utm_name !== 'Unknown')
					.sort((a, b) => b.total - a.total)
			)
		}

		if (baseInfoCurrent?.utm_campaign) {
			setCurrentUtmCampaign(
				baseInfoCurrent?.utm_campaign
					.filter(item => item.utm_name !== 'Unknown')
					.sort((a, b) => b.total - a.total)
			)
		}

	}, [baseInfoCurrent, baseInfoTotal]);

	useEffect(() => {
		(async () => {
			try {
				store.notificationsStore.showPreloader();
				const {body: bodyCurrent} = await api.analytics.getBaseData({
					start_date: store.dateStore.startDate,
					end_date: store.dateStore.endDate,
				});

				if (bodyCurrent) {
					setBaseInfoCurrent(bodyCurrent);
				}

				const {body: bodyLast} = await api.analytics.getBaseData({
					start_date: store.dateStore.lastPeriodStartDate,
					end_date: store.dateStore.lastPeriodEndDate,
				});

				if (bodyLast) {
					setBaseInfoLast(bodyLast);
				}

				const {body: bodyTotal} = await api.analytics.getBaseTotal()

				if (bodyTotal) {
					setBaseInfoTotal(bodyTotal);
				}


			} catch (error) {
				store.notificationsStore.setError('Ошибка при загрузке данных')
			} finally {
				store.notificationsStore.hidePreloader();
			}
		})();
	}, [store.dateStore.startDate, store.dateStore.endDate]);




	return (
		<>
			<div className={styles.container}>
				<div className={styles.pageName}>
					<div className={styles.top}>
						<div className={styles.title}>
							<h2>База</h2>
						</div>
					</div>

				</div>

				<div>

					<div style={{display: 'flex', flexWrap: 'wrap'}}>
						<Indicator 
							name='Всего контактов' 
							curentValue={baseInfoTotal?.total}
							// lastValue={totalLastSales} 
						/>

						<Indicator 
							name='Контактов' 
							curentValue={baseInfoCurrent?.total}
							// lastValue={totalLastSales} 
						/>
					</div>
					

					<ResponsiveContainer width={'100%'} height={400}>
						<LineChart margin={{top: 20}} data={baseInfoFormated}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="date"/>
							<YAxis fontSize={12} width={70}/>
							<Tooltip content={<TooltipComparison/>}/>
							<Line type="monotone" dataKey={'value'} stroke="#8884d8" strokeWidth={2} name='Контактов'/>
							<Line type="monotone" dataKey={'valueLast'} stroke="#8884d8" strokeWidth={2} name='Контактов' opacity={0.2}/>
						</LineChart>
					</ResponsiveContainer>


					<div style={{display: 'flex', flexWrap: 'wrap', marginTop: 20, justifyContent: 'center'}}>
					
						<Paper sx={{ width: '100%', maxWidth: 400, margin: '5px' }}>
							<TableContainer 
								style={{marginTop: 20, overflowX: 'auto'}}>
								<Table sx={{ minWidth: 320 }}>
									<TableHead>
										<TableRow>
											<TableCell>Страна</TableCell>
											<TableCell align="right">Количество</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{currentCountry && currentCountry.slice(pageCountry * rowsPerPageCountry, pageCountry * rowsPerPageCountry + rowsPerPageCountry)
											.map((row, i) => (
												<TableRow
													key={row.country}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													<TableCell component="th" scope="row">
														{row.country}
													</TableCell>
													<TableCell align="right">{row.total}</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
							<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={currentCountry ? currentCountry.length : 0}
								rowsPerPage={rowsPerPageCountry}
								page={pageCountry}
								onPageChange={handleChangePageCountry}
								onRowsPerPageChange={handleChangeRowsPerPageCountry}
							/>
						</Paper>


						<Paper sx={{ width: '100%', maxWidth: 400, margin: '5px' }}>
							<TableContainer 
								style={{marginTop: 20, overflowX: 'auto'}}>
								<Table sx={{ minWidth: 320 }}>
									<TableHead>
										<TableRow>
											<TableCell>UTM Source</TableCell>
											<TableCell align="right">Количество</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{currentUtmSource && currentUtmSource.slice(pageUtmSource * rowsPerPageUtmSource, pageUtmSource * rowsPerPageUtmSource + rowsPerPageUtmSource)
											.map((row, i) => (
												<TableRow
													key={row.utm_name}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													<TableCell component="th" scope="row">
														{row.utm_name}
													</TableCell>
													<TableCell align="right">{row.total}</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
							<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={currentUtmSource ? currentUtmSource.length : 0}
								rowsPerPage={rowsPerPageUtmSource}
								page={pageUtmSource}
								onPageChange={handleChangePageUtmSource}
								onRowsPerPageChange={handleChangeRowsPerPageUtmSource}
							/>
						</Paper>

						<Paper sx={{ width: '100%', maxWidth: 400, margin: '5px' }}>
							<TableContainer 
								style={{marginTop: 20, overflowX: 'auto'}}>
								<Table sx={{ minWidth: 320 }}>
									<TableHead>
										<TableRow>
											<TableCell>UTM Medium</TableCell>
											<TableCell align="right">Количество</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{currentUtmMedium && currentUtmMedium.slice(pageUtmMedium * rowsPerPageUtmMedium, pageUtmMedium * rowsPerPageUtmMedium + rowsPerPageUtmMedium)
											.map((row, i) => (
												<TableRow
													key={row.utm_name}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													<TableCell component="th" scope="row">
														{row.utm_name}
													</TableCell>
													<TableCell align="right">{row.total}</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
							<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={currentUtmMedium ? currentUtmMedium.length : 0}
								rowsPerPage={rowsPerPageUtmMedium}
								page={pageUtmMedium}
								onPageChange={handleChangePageUtmMedium}
								onRowsPerPageChange={handleChangeRowsPerPageUtmMedium}
							/>

						</Paper>

						<Paper sx={{ width: '100%', maxWidth: 400, margin: '5px' }}>
							<TableContainer 
								style={{marginTop: 20, overflowX: 'auto'}}>
								<Table sx={{ minWidth: 320 }}>
									<TableHead>
										<TableRow>
											<TableCell>UTM Campaign</TableCell>
											<TableCell align="right">Количество</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{currentUtmCampaign && currentUtmCampaign.slice(pageUtmCampaign * rowsPerPageUtmCampaign, pageUtmCampaign * rowsPerPageUtmCampaign + rowsPerPageUtmCampaign)
											.map((row, i) => (
												<TableRow
													key={row.utm_name}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													<TableCell component="th" scope="row">
														{row.utm_name}
													</TableCell>
													<TableCell align="right">{row.total}</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
							<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={currentUtmCampaign ? currentUtmCampaign.length : 0}
								rowsPerPage={rowsPerPageUtmCampaign}
								page={pageUtmCampaign}
								onPageChange={handleChangePageUtmCampaign}
								onRowsPerPageChange={handleChangeRowsPerPageUtmCampaign}
							/>
						</Paper>

					</div>


				</div>

				

			</div>

		</>
	);
});

export default Base;



// SELECT DATE(o.paid_date) AS date, SUM(o.paid_amount) AS revenue, COUNT(o.order_id) AS sales
// 	FROM orders o
// 	WHERE o.paid_date BETWEEN '2023-12-01 05:00:00' AND '2023-12-31 04:59:59' AND o.status = "fulfilled" OR o.status = "partiallyPaid"
// 	GROUP BY DATE(DATE_ADD(o.paid_date, INTERVAL -5 HOUR));