import React, { PropsWithChildren} from 'react';
import { createContext } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import MainStore from '../stores/mainStore';
import ApiClientProps from '../api/ApiClientProps';


export const MainContext = createContext<MainStore | null>(null);

export const MainContextProvider: React.FC<PropsWithChildren<{ api: ApiClientProps}>> = observer((props) => {
	const store = useLocalObservable(() => new MainStore(props.api));

	return (
		<MainContext.Provider value={store}>
			{props.children}
		</MainContext.Provider>
	);
});


