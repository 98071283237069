import React, { useEffect, useState } from 'react';
import styles from './tooltipComparison.module.css';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { formatNumberWithSpaces } from '../../../utils/numbers';

const TooltipComparison = ( props: TooltipProps<ValueType, NameType>) => {

	const [data, setData] = useState<{date: string, name: string, value: string, dateLast: string, valueLast: string}>({
		date: '',
		name: '',
		value: '',
		dateLast: '',
		valueLast: ''
	})

	useEffect(() => {
		if (props?.payload && props?.payload[0]) {
			const payload = props.payload[0];
			setData({
				date: payload.payload.date,
				name: payload.name as string,
				value: `${formatNumberWithSpaces(payload.value as number)}` + ` ${payload.payload.symbol ? payload.payload.symbol : '$'}`,
				dateLast: payload.payload.dateLast,
				valueLast: `${formatNumberWithSpaces(payload.payload.valueLast)}` + ` ${payload.payload.symbol ? payload.payload.symbol : '$'}`,
			})
		}
			
	}, [props.payload])
	
	return (
		<>
			{props.active && props.payload && props.payload.length &&
		<div className={styles.tooltip}>
			<div className={styles.tooltip__item}>
				<div className={styles.tooltip__name}>{data.date}</div>
				<div className={styles.tooltip__value}>{data.name}: {data.value}</div>
			</div>
			<div className={styles.tooltip__item}>
				<div className={styles.tooltip__name}>{data.dateLast}</div>
				<div className={styles.tooltip__value}>{data.name}: {data.valueLast}</div>
			</div>
		</div>
			}
		</>
	);
};

export default TooltipComparison;
