/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import styles from './deals.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import moment from 'moment';
import Indicator from '../../components/UI/Indicator/Indicator';
require('moment/locale/ru');

import Select from '../../components/UI/Select/Select';

import { Managers as ManagersModel } from '../../models/managers';
import { DealTypesStats } from '../../api/analytics/dto/DialTypesStatsResp.dto';
import DealsTable from '../../components/UI/DealTable/DealsTable';
import NormsLead from '../../api/managers/dto/GetNormsLead.dto';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { DealLog } from '../../api/managers/dto/GetDealsLogsResp.dto';

const Deals: React.FC = observer(() => {
	const api = useApi();
	const store = useStores();

	const [selectedRolesNames, setSelectedRolesNames] = useState<string[]>(['Консультант', 'Консультант 2', 'Консультант 3']);
	const [, setSelectedOp] = useState(0);

	const [managersList, setManagersList] = useState<ManagersModel[]>([]);
	const [selectedManager, setSelectedManager] = useState<ManagersModel>({id: 'all', name: 'Все менеджеры', post: ''});

	const [managersStats, setManagersStats] = useState<DealTypesStats[]>([]);

	const [dealsTotals, setDealsTotals] = useState<{name: string, count: number}[]>([]);

	const [dealsTable, setDealsTable] = useState<{nameTypeDeal: string, stats: {managerName: string, count: number, league: string, plan: number}[]}[]>([]);

	const [normsLeadToWeek, setNormsLeadToWeek] = useState<NormsLead[]>([]);

	const [selectedWeeks, setSelectedWeeks] = useState<number>(1);

	const [dealLogs, setDealLogs] = useState<DealLog[]>([]);
	const [dealsLogsTotal, setDealsLogsTotal] = useState<number>(0);

	const [selectedDealType, setSelectedDealType] = useState<{id: number, name: string}>({id: 0, name: 'Все'});
	const [listDealTypes, setListDealTypes] = useState<{id: number, name: string}[]>([{id: 0, name: 'Все'}]);

	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);

	useEffect(() => {
		let listTypes: {id: number, name: string}[] = [{id: 0, name: 'Все'}];
		dealsTable.forEach((item, i) => {
			listTypes.push({id: i + 1, name: item.nameTypeDeal});
		});

		listTypes = listTypes.filter((item, index) => listTypes.findIndex(t => t.name === item.name) === index);

		setListDealTypes(listTypes);

	}, [dealsTable]);

	useEffect(() => {
		(async () => {
			try {
				setPage(0);
				await loadDealLogs(0 * rowsPerPage, rowsPerPage, selectedDealType.id === 0 ? undefined : selectedDealType.name);
			} catch (error) {
				store.notificationsStore.setError('Ошибка при загрузке данных')
			}
		})();
	}, [selectedDealType]);

	useEffect(() => {
		(async () => {
			try {
				await loadDealLogs(page * rowsPerPage, rowsPerPage, selectedDealType.id === 0 ? undefined : selectedDealType.name);
			} catch (error) {
				store.notificationsStore.setError('Ошибка при загрузке данных')
			}
		})();
	}, [page, rowsPerPage]);

	function formatUserTime(dateString: string) {
		// Парсинг строки даты и времени
		const date = moment(dateString);

		// Преобразование даты и времени в локальное время пользователя и форматирование
		const formattedDate = date.local().format('DD-MM-YYYY HH:mm');

		return formattedDate;
	}

	useEffect(() => {
		const { startDate, endDate } = store.dateStore;
		const adjustedEndDate = moment(endDate).add(1, 'days'); // Добавляем один день к endDate
		const weeks = adjustedEndDate.diff(moment(startDate), 'weeks');
		setSelectedWeeks(weeks > 0 ? weeks : 1);
	}, [store.dateStore.startDate, store.dateStore.endDate]);

	useEffect(() => {
		setDealsTable(
			dealsTable.map(item => {
				return {
					...item,
					stats: item.stats.map(stat => {
						const norm = normsLeadToWeek.find(norms => norms.deal_type === item.nameTypeDeal);
						return {
							...stat,
							plan: norm ? norm.count * selectedWeeks : 0
						}
					})
				}
			})
		);
	}, [selectedWeeks]);


	useEffect(() => {
		const newDealsTotals: { name: string; count: number }[] = [];
		const newDealsTable: { nameTypeDeal: string; stats: {managerName: string, count: number, league: string, plan: number}[] }[] = [];

		managersStats.forEach(item => {
			item.type_deals.forEach(deal => {
				// Проверяем, что имена не пустые строки или пробелы
				if (deal.deal_type.trim() !== '' && item.manager_name.trim() !== '') {
					const index = newDealsTotals.findIndex(total => total.name === deal.deal_type);
					if (index === -1) {
						newDealsTotals.push({ name: deal.deal_type, count: deal.count });
					} else {
						newDealsTotals[index].count += deal.count;
					}

					const tableIndex = newDealsTable.findIndex(total => total.nameTypeDeal === deal.deal_type);
					if (tableIndex === -1) {
						newDealsTable.push({ nameTypeDeal: deal.deal_type, stats: [{ managerName: item.manager_name, league: item.league_name, count: deal.count, plan: deal.plan * selectedWeeks }] });
					} else {
						newDealsTable[tableIndex].stats.push({ managerName: item.manager_name, league: item.league_name, count: deal.count, plan: deal.plan * selectedWeeks});
					}
				}
			});
		});

		setDealsTotals(newDealsTotals);
		setDealsTable(newDealsTable);
	}, [managersStats]);

	useEffect(() => {
		(async () => {
			try {
				await loadManagers();
			} catch (error) {
				store.notificationsStore.setError('Ошибка при загрузке данных')
			}
		})();
	}, [selectedRolesNames]);

	useEffect(() => {
		(async () => {
			try {
				await loadDealStats();
			} catch (error) {
				store.notificationsStore.setError('Ошибка при загрузке данных')
			}
		})();
	}, [selectedManager, managersList]);

	useEffect(() => {
		(async () => {
			try {
				await loadManagers();
				await loadDealStats();
				await loadDealLogs(0, rowsPerPage);

			} catch (error) {
				store.notificationsStore.setError('Ошибка при загрузке данных')
			}
		})();
	}, [store.dateStore.startDate, store.dateStore.endDate]);

	const loadManagers = async () => {
		const {body: managers} = await api.managers.getManangers(selectedRolesNames);

		if (!managers) {return;}

		setManagersList([{ id: 'all', name: 'Все менеджеры', post: '' }, ...managers]);
		setSelectedManager({id: 'all', name: 'Все менеджеры', post: ''});

		return managers;
	}

	const loadDealLogs = async (skip: number, take : number, dealType?: string) => {
		try {
			store.notificationsStore.showPreloader();
			const data: {type_deal?: string, skip: number, take: number} = {
				take: take,
				skip: skip,
			}

			if (dealType) {
				data['type_deal'] = dealType;
			}

			const {body} = await api.managers.getDealsLogs(data);

			if (!body) {return;}

			setDealLogs(body.deals);
			setDealsLogsTotal(body.total);
		} catch (error) {
			store.notificationsStore.setError('Ошибка при загрузке логов')
		} finally {
			store.notificationsStore.hidePreloader();
		}
	};

	const loadDealStats = async () => {
		try {
			store.notificationsStore.showPreloader();
			const responsables = selectedManager?.id !== 'all' ? [selectedManager?.id] : managersList.map(item => item.id);
	
			const dataRequest = {
				start_date: store.dateStore.startDate,
				end_date: store.dateStore.endDate,
				responsables,
			}

			if (managersList.length > 0) {
				const {body} = await api.analytics.getDealTypesStats(dataRequest);
	
				if (body && body.length > 0) {
					setManagersStats(body);
				}
			}

			const {body: normsLead} = await api.managers.getLeadNorms();

			if (normsLead) {
				setNormsLeadToWeek(normsLead);
			}

		} catch (error) {
			store.notificationsStore.setError('Ошибка при загрузке данных')
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const selectOp = async (e: any) => {
		setSelectedOp(e.target.value);

		let currentSelectedRolesNames: string[] = [];

		if (+e.target.value === 0) {
			currentSelectedRolesNames = ['Консультант', 'Консультант 2'];
		}
		if (+e.target.value === 1) {
			currentSelectedRolesNames = ['Консультант'];
		}
		if (+e.target.value === 2) {
			currentSelectedRolesNames = ['Консультант 2'];
		}
		if (+e.target.value === 3) {
			currentSelectedRolesNames = ['Консультант 3'];
		}

		setSelectedRolesNames(currentSelectedRolesNames);
	}

	const selectManager = async (e: any) => {
		const selected = managersList.find(item => item.id === e.target.value);
		setSelectedManager(selected !== undefined ? selected : {id: 'all', name: 'Все менеджеры', post: ''});
	};

	return (
		<>
			<div className={styles.container}>
				<div className={styles.pageName}>
					<div className={styles.top}>
						<div className={styles.title}>
							<h2>Лиды</h2>
						</div>
						<div className={styles.filters}>
							{/* @ts-ignore */}
							<Select onChange={selectOp} style={{minWidth: 170, marginRight: 10, width: 'max-content'}} listCategories={[{id: 0, name: 'Все'}, {id: 1, name: 'Отдел продаж 1'}, {id: 2, name: 'Отдел продаж 2'}, {id: 3, name: 'Отдел продаж 3'}]}/>
							<Select selectedValue={selectedManager.id} onChange={selectManager} listCategories={managersList} style={{minWidth: 250, width: 'max-content'}}/>
						</div>
					</div>

				</div>

				<div style={{width: '100%', display: 'flex', flexWrap: 'wrap'}}>

					{
						dealsTotals.length > 0 && dealsTotals
							.sort((a, b) => b.count - a.count)
							.map((item, index) => {
								return (
									<Indicator 
										key={index}
										name={item.name} 
										curentValue={item.count}
										//@ts-ignore
										lastValue={(normsLeadToWeek.find(norm => norm.deal_type === item.name)?.count) * selectedWeeks || 0}
									/>
								);
							})
					}

				</div>

				<div style={{display: 'flex', flexWrap: 'wrap'}}>
					{dealsTable.length > 0 && dealsTable
						.sort((a, b) => b.stats.reduce((acc, item) => acc + item.count, 0) - a.stats.reduce((acc, item) => acc + item.count, 0))
						.map((item, index) => {
							return (
								<DealsTable key={index} name={item.nameTypeDeal} stats={item.stats}/>
							)
						})
					}
				</div>

				<div style={{display: 'flex', flexDirection: 'column', marginTop: 20}}>
					<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30}}>
						<h2>Логи заявок</h2>
						<Select
							listCategories={listDealTypes}
							selectedValue={selectedDealType.id}
							onChange={(e) => {
								//@ts-ignore
								const selected = listDealTypes.find(item => item.id === +e.target.value);
								setSelectedDealType(selected !== undefined ? selected : {id: 0, name: 'Все'});
							}}
							style={{maxWidth: 300, margin: 0}}
						/>
					</div>
					<Paper sx={{ width: '100%', maxWidth: 600, margin: '5px' }}>
						<TableContainer 
							style={{marginTop: 20, overflowX: 'auto'}}>
							<Table sx={{ minWidth: 320 }}>
								<TableHead>
									<TableRow>
										<TableCell align="left">Дата</TableCell>
										<TableCell align="right">Тип заявки</TableCell>
										<TableCell align="right">Кому</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{dealLogs && dealLogs
										.map((row) => (
											<TableRow
												key={row.id}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell component="th" scope="row">
													{formatUserTime(row.date_created)}
												</TableCell>
												<TableCell align="right">{row.type_deal}</TableCell>
												<TableCell align="right">{row.responsible_name}</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={dealsLogsTotal}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={(e, newPage) => setPage(newPage)}
							onRowsPerPageChange={(e) => {
								setRowsPerPage(+e.target.value);
								setPage(0);
							}}
						/>
					</Paper>
				</div>
				

			</div>

		</>
	);
});

export default Deals;