/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './User.module.css';
import { ReactComponent as Close } from '../ico/close.svg';
import { Button } from '../../UI/Button/Button';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { useApi } from '../../../hooks/useApi';
import { Input } from '../../UI/input/input';
import { useEffect, useState } from 'react';
import { UserMeResponse } from '../../../api/user/dto/UserMeResponse.dto';
import { allAccessRoles, allRoles } from '../../../utils/accessRoles';
import { Managers } from '../../../models/managers';
import Select from '../../UI/Select/Select';
import { generatePassword } from '../../../utils/text';

interface PopupProps {
  user: UserMeResponse | null;
	managersAxl: Managers[];
  onClose: () => void;
	update: () => void;
}

const UserPopUP: React.FC<PopupProps> = observer(({ user, managersAxl, onClose, update }) => {

	const store = useStores();
	const api = useApi();


	const [name, setName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [role, setRole] = useState<allRoles | null>(null);
	const [axlId, setAxlId] = useState<string | null>(null);

	const [disable, setDisable] = useState<boolean>(true);

	const [managersList, setManagersList] = useState<Managers[]>([]);

	useEffect(() => {
		setManagersList([{id: '', name: 'Выберите менеджера', post: ''}, ...managersAxl]);
	}, [managersAxl]);

	useEffect(() => {
		if (name !== '' && email !== '' && role !== null && password !== '') {
			setDisable(false);
		} else {
			setDisable(true);
		}
	},[name, email, role, password]);


	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	const handleButtonClick = async () => {
		try {
			store.notificationsStore.showPreloader();

			if (name === '') {
				store.notificationsStore.setError('Заполните поле "Имя"');
				return;
			}

			if (email === '') {
				store.notificationsStore.setError('Заполните поле "Email"');
				return;
			}

			if (role === null) {
				store.notificationsStore.setError('Выберите роль');
				return;
			}

			await api.user.registration({
				name,
				email,
				password,
				role,
				axl_id: axlId
			});

			update();
			onClose();
			store.notificationsStore.setNotifications('Пользователь создан');

		} catch (e) {
			store.notificationsStore.setError('Ошибка обработки клиента');
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const createPassword = () => {
		setPassword(generatePassword());
	}
	
	return (
		<>
			<div className={styles.container} onClick={handleOverlayClick}>
				<div className={styles.form}>
					<Close className={styles.close} onClick={onClose}/>
					<h3>Создание пользователя</h3>
					<div className={styles.line} style={{marginBottom: 20}}/>

					<Input style={{maxWidth: '90%'}} value={name} placeholder='Имя и Фамилия' textLabel='Имя и Фамилия' onChange={(event) => setName(event.target.value)}/>

					<Input style={{maxWidth: '90%'}} value={email} placeholder='Email' textLabel='Email' onChange={(event) => setEmail(event.target.value)}/>

					<div style={{display: 'flex', maxWidth: '90%', width: '90%'}}>
						<Input style={{maxWidth: '90%'}} value={password} placeholder='Пароль' textLabel='Пароль' onChange={(event) => setPassword(event.target.value)}/>
						<Button onClick={createPassword} appearance='link' style={{maxWidth: 150, marginLeft: 10}}>Сгенерировать</Button>
					</div>

					{/* @ts-ignore */}
					<Select style={{maxWidth: '90%'}} listCategories={allAccessRoles} onChange={(e) => setRole(e.target.value)} selectedValue={role ? role : 'nameSelect'}/>

					{role === 'mop' &&
					<>
						{/* @ts-ignore */}
						<Select style={{maxWidth: '90%'}} listCategories={managersList} onChange={(e) => setAxlId(e.target.value === '' ? null : e.target.value)} selectedValue={axlId ? axlId : ''}/>
					</>
					}
					

				

					<div className={styles.line} style={{marginBottom: 10}}/>

					<Button disabled={disable} onClick={handleButtonClick} style={{margin: 5, maxWidth: 320, marginBottom: 25}} appearance='auth'>Создать</Button>

				</div>
			</div>
		</>
	);
});

export default UserPopUP;
