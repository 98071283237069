import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './ProtectedLink.module.css';
import cn from 'classnames';

interface ProtectedLinkProps {
  to: string;
  roles: string[];
  userRole: string;
  label: string;
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

const ProtectedLink: React.FC<ProtectedLinkProps> = ({ to, roles, userRole, label, Icon }) => {
	const setActiveLink = (isActive: boolean) =>
		isActive ? cn(styles.menuItem, styles.active) : styles.menuItem;

	if (!roles.includes(userRole)) {
		return null;
	}

	return (
		<NavLink to={to} className={({ isActive }) => setActiveLink(isActive)}>
			<div className={styles.line} />
			<Icon />
			<p>{label}</p>
		</NavLink>
	);
};

export default ProtectedLink;
