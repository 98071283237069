export function formatNumberWithSpaces(value: number | string): string {
	if (typeof value === 'number') {
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	} else if (typeof value === 'string') {
		const numericValue = parseFloat(value);
		if (!isNaN(numericValue)) {
			return numericValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
		}
	}
	return '';
}

export function roundNum(value: number, decimals: number = 2): number {
	const factor = Math.pow(10, decimals);
	return Math.round(value * factor) / factor;
}

export function zipNum(value: number): string {
	// Преобразуем миллионы
	if (value >= 1_000_000) {
		return (value / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
	}
	// Преобразуем тысячи
	if (value >= 1_000) {
		return (value / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
	}
	// Если число меньше 1000, возвращаем как есть
	return value.toString();
}