import { makeAutoObservable, runInAction } from 'mobx';
import MainStore from './mainStore';
import { User } from '../models/user';

export default class UserStore {
	mainStore: MainStore;

	constructor(mainStore: MainStore) {
		makeAutoObservable(this);

		this.mainStore = mainStore;
	}

	user: User | null = null;

	setUser = (user: User | null) => {
		if (!user) {return}
		runInAction(() => this.user = user);
	}

	removeUser = () => {
		runInAction(() => this.user = null);
	}
}