/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './Amount.module.css';
import cn from 'classnames';
import { formatNumberWithSpaces } from '../../../../utils/numbers';

interface AmountFinanceProps {
	amount: number;
	isPlaned?: boolean;
	secondary?: boolean;
	currency?: string;
}

const AmountFinance: React.FC<AmountFinanceProps> = observer(({amount,  isPlaned = false, currency, secondary = false}) => {

	return (
		<div className={cn(styles.tableCellTotalText, {[styles.secondary] : secondary})} style={{opacity: !isPlaned ? 1 : 0.5}}>
			<span className={cn(styles.uiMoney, { [styles.uiMoneyNegative]: amount < 0, [styles.uiMoneyPositive]: amount > 0 })}>
				{amount > 0 ? '+' : ''}{formatNumberWithSpaces(Math.floor(amount).toString())}
				<sub>,{amount.toFixed(2).split('.')[1] || '00'}</sub>
				<span> {currency}</span>
			</span>
		</div>

	)
	
});

export default AmountFinance;