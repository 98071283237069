/* eslint-disable no-console */
import axios, { AxiosInstance } from 'axios';
import ApiClientProps from './ApiClientProps';
import { UserApi } from './user/User';
import { UserAuthResponse } from './user/dto/UserAuthResponse.dto';
import { NiceResponse } from './dto/NiceResponse';
import { AnalyticsApi } from './analytics/Analytics';
import { ManagersApi } from './managers/Managers';
import MainStore from '../stores/mainStore';
import { SpentsApi } from './spents/Spents';
import { FinanceApi } from './finance/Finance';

export default class ApiClient {
	client: AxiosInstance;
	baseUrl: string;
	lastTimeUpdateToken: number;
	private props: ApiClientProps;
	private store: MainStore;

	constructor(props: ApiClientProps, store: MainStore) {
		this.props = props;
		this.baseUrl = this.props.baseUrl;
		this.store = store;
		this.lastTimeUpdateToken = 0;

		this.client = axios.create({
			baseURL: this.baseUrl,
			// headers: {
			// 	'Referer': this.props.baseUrl
			// }
		});

		this.setInterceptors();
	}

	public getAccessToken(): string | undefined | null {
		return this.props.accessToken || null;
	}

	public getRefreshToken(): string | undefined | null {
		return this.props.refreshToken || null;
	}

	private updateTimeToken() {
		this.lastTimeUpdateToken = Date.now();
	}

	public saveTokens(accessToken: string | null |  undefined, refreshToken: string | null |  undefined) {
		if (accessToken) {
			this.props.accessToken = accessToken;
			this.props.saveToken(this.props.auth.access.name, accessToken);
		} else {
			this.props.accessToken = null;
			this.props.deleteToken(this.props.auth.access.name);
		}

		if (refreshToken) {
			this.props.refreshToken = refreshToken;
			this.props.saveToken(this.props.auth.refresh.name, refreshToken );
		} else {
			this.props.refreshToken = null;
			this.props.deleteToken(this.props.auth.refresh.name);
		}

		this.user.me().then((res) => {
			this.store.userStore.setUser(res.body);
		}).catch(() => {});
	}

	public async Unauthorize() {
		this.saveTokens(null, null);
	}

	public isAuthorized() {
		return this.getAccessToken() != null && this.getRefreshToken() != null;
	}

	private async setInterceptors() {
		this.client.interceptors.request.use(
			async config => {
				config.headers['Accept'] = 'application/json';

				const accessToken = this.getAccessToken();
				const refreshToken = this.getRefreshToken();

				if (this.props.debug) {
					const { url, data, headers } = config;
					console.log('REQUEST:', url, JSON.stringify(data, null, 4), headers);
				}

				if (accessToken)
				{config.headers['Authorization'] = `Bearer ${accessToken}`;}

				//we dont have access BUT we have refresh token
				if (!accessToken && refreshToken) {
					const result = await this.refreshAccessTokenAndSave();
					if (result) {
						config.headers['Authorization'] = `Bearer ${result}`;
						return config;
					}
				}

				return config;
			}, error => {
				Promise.reject(error)
			});

		this.client.interceptors.response.use(
			(response) => {
				if (this.props.debug) {
					const { status, data } = response;
					console.log('RESPONSE:', JSON.stringify(data, null, 4), 'STATUS:', status);
				}

				return response
			},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			async (error: any) => {
				const originalRequest = error.config;
				const refreshToken = this.getRefreshToken();
				const errors = error?.response?.data?.error as string
				const code = error?.response?.data?.code as number
				// console.log(errors)
				const needToUpdateToken = code === 401;

				if (errors === 'refresh token invalid') {
					this.store.authStore.logout();
					return;
				}

				if (needToUpdateToken && refreshToken && !originalRequest._retry) {

					if (Date.now() - this.lastTimeUpdateToken < 10000) {
						originalRequest._retry = true;
						return this.client(originalRequest);
					}

					const result = await this.refreshAccessTokenAndSave();

					if (result) {
						return this.client(originalRequest);
					}
				}

				if (this.props.debug) {
					console.error('RESPONSE ERROR:', errors, 'CODE:', code);
				}

				return Promise.reject({
					success: false,
					error: error?.response?.data?.error,
					data: error,
					code: error?.response?.status
				});
			});
	}

	private async refreshAccessTokenAndSave(): Promise<boolean> {
		this.updateTimeToken();

		if (this.props.debug) {
			console.log('REFRESHING TOKEN...');
		}
	
		const refreshToken = this.getRefreshToken();
		if (refreshToken) {
			try {
				// Используем основной экземпляр Axios для отправки запроса на обновление токена
				const res = await this.client.post<NiceResponse<UserAuthResponse>>(this.props.baseUrl + '/auth/refresh', { token: refreshToken });
				if (res.data.success) {
					this.saveTokens(res.data?.body?.access_token || null, res.data?.body?.refresh_token || null);
					return true;
				}
				throw new Error();
			} catch (e) {
				await this.Unauthorize();
				return false;
			}
		} else {
			return false;
		}
	}
	

	user: UserApi = new UserApi(this)
	analytics: AnalyticsApi = new AnalyticsApi(this)
	managers: ManagersApi = new ManagersApi(this)
	spents: SpentsApi = new SpentsApi(this)
	finance: FinanceApi = new FinanceApi(this)
}
