import { ButtonProps } from './Button.props';
import styles from './Button.module.css';
import cn from 'classnames';
import React from 'react';

export const Button = ({appearance, children, className, ...props} : ButtonProps) : JSX.Element => {
	return (
		<button
			className={cn(styles.button, className, {
				[styles.auth]: appearance === 'auth',
				[styles.back]: appearance === 'back',
				[styles.link]: appearance === 'link',
			})}
			{...props}
		>
			{children}
		</button>
	);
};