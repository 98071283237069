/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import styles from './traffic.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
// import { LineChart } from '@mui/x-charts/LineChart';
require('moment/locale/ru');

import countries from 'i18n-iso-countries';
import * as ru from 'i18n-iso-countries/langs/ru.json';
import DatePickerLocal from '../../components/UI/DatePicker/datePickerLocal';
import cn from 'classnames';
import ProductUrls from '../../api/analytics/dto/ProductUrls.dto';
import { Input } from '../../components/UI/input/input';
import { Button } from '../../components/UI/Button/Button';
import { ReactComponent as CrossIco } from './icon/cross.svg';
import ProductWebinars from '../../api/analytics/dto/ProductWebinars.dto';
import FB from '../../components/modules/traffic/fb/fb';
import Google from '../../components/modules/traffic/google/google';
import Instagram from '../../components/modules/traffic/instagram/instagram';
import Telegram from '../../components/modules/traffic/telegram/telegram';
import Youtube from '../../components/modules/traffic/youtube/youtube';

countries.registerLocale(ru);

const Traffic: React.FC = observer(() => {
	const api = useApi();
	const store = useStores();

	const [activeCategory, setActiveCategory] = useState<'fb' | 'google' | 'instagram' | 'telegram' | 'youtube' | 'products'>('fb');
	const [dateRevenue, setDateRevenue] = useState<[string, string]>([store.dateStore.startDate, store.dateStore.endDate]);

	const [uniqUrls, setUniqUrls] = useState<string[]>([]);
	const [urrlByProduct, setUrlByProduct] = useState<ProductUrls[]>([]);
	const [uniqProducts, setUniqProducts] = useState<{productName: string, urls: string[], webinars: string[]}[]>([]);
	const [activeSearchName, setActiveSearchName] = useState<string>('');

	const [webinarNames, setWebinarNames] = useState<string[]>([]);
	const [webinarsByProduct, setWebinarsByProduct] = useState<ProductWebinars[]>([]);
	const [activeSearchWebinar, setActiveSearchWebinar] = useState<string>('');

	useEffect(() => {
		(async () => {
			try {
				store.notificationsStore.showPreloader();

				const {body: getWebinarsByProductResp} = await api.analytics.getWebinarsForProducts();

				if (getWebinarsByProductResp) {
					setWebinarsByProduct(getWebinarsByProductResp);
				}

			} catch (error) {
				store.notificationsStore.setError('Ошибка при загрузке данных')
			} finally {
				store.notificationsStore.hidePreloader();
			}
		})();
	}, [store.dateStore.startDate, store.dateStore.endDate, dateRevenue]);

	useEffect(() => {
		(async () => {
			try {
				store.notificationsStore.showPreloader();

				const {body} = await api.analytics.getUniqUrls();

				if (body) {
					setUniqUrls(body);
				}

				const {body: urlByProductResp} = await api.analytics.getUrlsForProducts();

				if (urlByProductResp) {
					setUrlByProduct(urlByProductResp);
				}

				const {body: webinarNamesResp} = await api.analytics.getWebinarNames();

				if (webinarNamesResp) {
					setWebinarNames(webinarNamesResp);
				}

			} catch (error) {
				store.notificationsStore.setError('Ошибка при загрузке данных')
			} finally {
				store.notificationsStore.hidePreloader();
			}
		})();
	}, []);

	useEffect(() => {
		const productsMap: { [key: string]: {
			productName: string;
			urls: string[];
			webinars: string[];
	} } = {};

		// Добавляем URL в productsMap
		urrlByProduct.forEach((url) => {
			url.product_name.split(',').map(item => item.trim()).forEach((productName) => {
				if (!productsMap[productName]) {
					productsMap[productName] = { productName, urls: [], webinars: [] };
				}
				productsMap[productName].urls.push(url.url);
			});
		});

		// Добавляем Webinars в productsMap
		webinarsByProduct.forEach((webinar) => {
			webinar.product_name.split(',').map(item => item.trim()).forEach((productName) => {
				if (!productsMap[productName]) {
					productsMap[productName] = { productName, urls: [], webinars: [] };
				}
				productsMap[productName].webinars.push(webinar.webinar_name);
			});
		});

		// Убираем дубликаты и конвертируем в массив
		const productsAll: {
			productName: string;
			urls: string[];
			webinars: string[];
	}[] = Object.values(productsMap).map(product => {
		product.urls = Array.from(new Set(product.urls));
		product.webinars = Array.from(new Set(product.webinars));
		return product;
	});

		setUniqProducts(productsAll);
	}, [urrlByProduct, webinarsByProduct]);

	const addProductUrl = () => {
		setUniqProducts([...uniqProducts, {productName: 'Продукт', urls: [], webinars: []}]);
	}

	const updateProductUrl = async () => {
		try {
			store.notificationsStore.showPreloader();

			const data: ProductUrls[] = [];

			uniqProducts.forEach((product) => {
				product.urls.forEach((url) => {
					const existingUrlIndex = data.findIndex(item => item.url === url);

					if (existingUrlIndex !== -1) {
						// Если уже есть элемент с такой ссылкой, добавляем новое название продукта через запятую
						data[existingUrlIndex].product_name += `,${product.productName}`;
					} else {
						// Если элемент с такой ссылкой не найден, добавляем новый объект в массив
						data.push({product_name: product.productName, url});
					}
				});
			});

			const {body} = await api.analytics.addUrlForProducts(data);

			const dataWebinars: ProductWebinars[] = [];

			uniqProducts.forEach((product) => {
				product.webinars.forEach((webinar) => {
					const existingWebinarIndex = dataWebinars.findIndex(item => item.webinar_name === webinar);

					if (existingWebinarIndex !== -1) {
						// Если уже есть элемент с такой ссылкой, добавляем новое название продукта через запятую
						dataWebinars[existingWebinarIndex].product_name += `,${product.productName}`;
					} else {
						// Если элемент с такой ссылкой не найден, добавляем новый объект в массив
						dataWebinars.push({product_name: product.productName, webinar_name: webinar});
					}
				});
			});

			const {body: bodyWebinars} = await api.analytics.addWebinarForProducts(dataWebinars);

			if (body && bodyWebinars) {
				store.notificationsStore.setNotifications('Продукты успешно обновлены');
			}

			if (body) {
				store.notificationsStore.setNotifications('Продукты успешно обновлены');
			}

		} catch (error) {
			store.notificationsStore.setError('Ошибка при загрузке данных');
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	return (
		<>
			<div className={styles.container}>
				<div className={styles.pageName}>
					<div className={styles.top}>
						<div className={styles.title}>
							<h2>Трафик</h2>
						</div>
						<div style={{display: 'flex', flexDirection: 'column'}}>
							<p style={{textAlign: 'left', margin: 0}}>Дата выручки</p>
							<DatePickerLocal onChangeDate={setDateRevenue}/>
						</div>
					</div>
					<div className={styles.category}>
						<div className={cn(styles.categoryName, {[styles.categoryActive]: activeCategory === 'fb'})} onClick={() => {setActiveCategory('fb');}}>FB</div>
						<div className={styles.separator}/>
						<div className={cn(styles.categoryName, {[styles.categoryActive]: activeCategory === 'google'})} onClick={() => {setActiveCategory('google');}}>Google</div>
						<div className={styles.separator}/>
						<div className={cn(styles.categoryName, {[styles.categoryActive]: activeCategory === 'instagram'})} onClick={() => {setActiveCategory('instagram');}}>INST</div>
						<div className={styles.separator}/>
						<div className={cn(styles.categoryName, {[styles.categoryActive]: activeCategory === 'telegram'})} onClick={() => {setActiveCategory('telegram');}}>TG</div>
						<div className={styles.separator}/>
						<div className={cn(styles.categoryName, {[styles.categoryActive]: activeCategory === 'youtube'})} onClick={() => {setActiveCategory('youtube');}}>YT</div>
						<div className={styles.separator}/>
						<div className={cn(styles.categoryName, {[styles.categoryActive]: activeCategory === 'products'})} onClick={() => {setActiveCategory('products');}}>Продукты</div>
					</div>

				</div>

				{ activeCategory === 'fb' && <FB dateRevenue={dateRevenue}/> }
				
				{ activeCategory === 'google' && <Google/> }

				{ activeCategory === 'instagram' && <Instagram/> }

				{ activeCategory === 'telegram' && <Telegram/> }

				{ activeCategory === 'youtube' && <Youtube/> }

				{ activeCategory === 'products' &&
				<div>
					<div>
						{uniqProducts.map((product, i) => (
							<>
								<div style={{display: 'flex', marginTop: 10, marginBottom: 10, flexWrap: 'wrap'}}>

									<div style={{margin: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
										<Input 
											textLabel='Название продукта' type='text' id='text' placeholder='Введите название продукта'
											value={product.productName}
											onChange={(e) => {
												const newProducts = [...uniqProducts];
												newProducts[i].productName = e.target.value;
												setUniqProducts(newProducts);
											}}
											style={{maxWidth: 300, margin: 0}}
										/>
										<Button style={{marginTop: 5}} onClick={() => {
											const newProducts = [...uniqProducts];
											newProducts.splice(i, 1);
											setUniqProducts(newProducts);
										}} appearance={'link'}>Удалить</Button>
									</div>

									<div style={{display: 'flex', flexDirection: 'column'}}>

										<div>

											<p style={{margin: 5, fontSize: 12}}>Ссылки</p>

											<div 
												style={{width: '100%', position: 'relative', maxWidth: 500, margin: 5, background: 'white', border: '1px solid #e2e1f4', borderRadius: 8, display: 'flex', padding: 10, height: 'fit-content', flexDirection: 'row', flexWrap: 'wrap'}}
												id='tagContainer'
											>
												{product.urls.map((url, j) => (
													<div style={{display: 'flex', margin: 2, height: 26}}>

														<div style={{background: '#7a76ff', padding: 4, borderRadius: '4px 0 0 4px'}}>
															<p style={{fontSize: 13, color: 'white'}}>{url}</p>
														</div>

														<div 
															className={styles.tagDelete}
															onClick={() => {
																const newProducts = [...uniqProducts];
																newProducts[i].urls = newProducts[i].urls.filter((item, index) => index !== j);
																setUniqProducts(newProducts);
															}}
														>
															<CrossIco className={styles.tagDeleteIco} />
														</div>

													</div>
												))}
									
												<input type="text" style={{border: 0}}
													onFocus={() => setActiveSearchName(product.productName)}
												/>
											</div>

											{activeSearchName === product.productName && uniqUrls.length > 0 && 
										<div id='tagContainer' style={{position: 'absolute', border: '1px solid #e2e1f4', background: 'white', borderRadius: 8, padding: 10, zIndex: 1000, maxHeight: 200, overflow: 'auto', width: '100%', maxWidth: 500, margin: 5, marginTop: 0}}>
											{uniqUrls.map((uniqUrl) => {
												return (
													<div 
														style={{display: 'flex', margin: 2, height: 26}}
														onClick={() => {
															const newProducts = [...uniqProducts];
															newProducts[i].urls = [...newProducts[i].urls, uniqUrl];
															setUniqProducts(newProducts);
															setActiveSearchName('');
														}
														}
													>

														<div style={{background: '#7a76ff', padding: 4, borderRadius: '4px 0 0 4px'}}>
															<p style={{fontSize: 13, color: 'white'}}>{uniqUrl}</p>
														</div>

													</div>

												);
											})}
										</div>
											}

										</div>

										{/* Вебинары */}

										<div>

											<p style={{margin: 5, fontSize: 12}}>Вебинары</p>

											<div 
												style={{width: '100%', position: 'relative', maxWidth: 500, margin: 5, background: 'white', border: '1px solid #e2e1f4', borderRadius: 8, display: 'flex', padding: 10, height: 'fit-content', flexDirection: 'row', flexWrap: 'wrap'}}
												id='tagContainer'
											>
												{product.webinars.map((web, j) => (
													<div style={{display: 'flex', margin: 2, height: 26}}>

														<div style={{background: '#7a76ff', padding: 4, borderRadius: '4px 0 0 4px'}}>
															<p style={{fontSize: 13, color: 'white'}}>{web}</p>
														</div>

														<div 
															className={styles.tagDelete}
															onClick={() => {
																const newProducts = [...uniqProducts];
																newProducts[i].webinars = newProducts[i].webinars.filter((item, index) => index !== j);
																setUniqProducts(newProducts);
															}}
														>
															<CrossIco className={styles.tagDeleteIco} />
														</div>

													</div>
												))}
									
												<input type="text" style={{border: 0}}
													onFocus={() => setActiveSearchWebinar(product.productName)}
												/>
											</div>

											{activeSearchWebinar === product.productName && uniqProducts.length > 0 && 
										<div id='tagContainer' style={{position: 'absolute', border: '1px solid #e2e1f4', background: 'white', borderRadius: 8, padding: 10, zIndex: 1000, maxHeight: 200, overflow: 'auto', width: '100%', maxWidth: 500, margin: 5, marginTop: 0}}>
											{webinarNames.map((webName) => {
												return (
													<div 
														style={{display: 'flex', margin: 2, height: 26}}
														onClick={() => {
															const newProducts = [...uniqProducts];
															newProducts[i].webinars = [...newProducts[i].webinars, webName];
															setUniqProducts(newProducts);
															setActiveSearchWebinar('');
														}
														}
													>

														<div style={{background: '#7a76ff', padding: 4, borderRadius: '4px 0 0 4px'}}>
															<p style={{fontSize: 13, color: 'white'}}>{webName}</p>
														</div>

													</div>

												);
											})}
										</div>
											}

										</div>

									</div>


								</div>
								<div style={{width: '100%', height: 1, background: 'grey', marginTop: 20, opacity: 0.2}}/>

							</>
						))}


						
						<Button style={{marginTop: 30, maxWidth: 320, margin: 10}} onClick={addProductUrl} appearance={'link'}>Добавить продукт</Button>
						<Button style={{marginTop: 10, maxWidth: 320, margin: 10}} onClick={updateProductUrl} appearance={'auth'}>Обновить ссылки</Button>

					</div>
					
				</div>
				}

				

			</div>

		</>
	);
});

export default Traffic;