/* eslint-disable no-unused-vars */
import moment from 'moment';

import 'rsuite/DateRangePicker/styles/index.css';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { formatMySQLDatetime } from '../../../utils/date';

export default function DatePickerSingle({defaultValue, onChangeDate}: {defaultValue: string, onChangeDate: (dateChange: string) => void}) {

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<MobileDatePicker
				defaultValue={moment(defaultValue)}
				onChange={(date) => onChangeDate(date ? formatMySQLDatetime(date.toDate()) : '')}
			/>
		</LocalizationProvider>
	);
}