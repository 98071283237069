/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum EnvEnum {
	dev = 'Development',
	prod = 'Production'
}

export const env: EnvEnum = process.env.NODE_ENV == 'development' ? EnvEnum.dev : EnvEnum.prod;

const AppSettings = {
	auth: {
		access: {
			name: 'accessToken',
			durationDays: 24 / 1
		},
		refresh: {
			name: 'refreshToken',
			durationDays: 30
		}
	},
	api: {
		// url: 'http://localhost:3010',
		url: 'https://brain.evolution.life/',
	},
	income_stages: ['NEfi0m8CbUW9958D8eJuGQ'],
	in_work_stages: [
		'Z2_pp0OVjEuoUAT87J5PEg',
		'uu5O10rDpUq138X4vtaNTg',
		'8sPwO8gUKkS5UHhQ9Wbk4g',
		'z_7uxJf2akafi2JjxqUemw',
		'RjqQI1V56UO8yuQFOqWj0A',
		'H-6LkQoocEGB_S_nNxMg4w',
	],
	realized_stages: [
		'-QQlP92ohEGcsvT16oS79w'
	],
	unrealized_stages: ['bW7rsxPAD0m_k-3vaRpcdw'],
	bronIds: [
		'HxursHjB9U6wzixmJ_zICA',
	],
}

export { AppSettings }
