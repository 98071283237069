/* eslint-disable array-callback-return */
import React, { ForwardedRef, forwardRef } from 'react';
import { SelectProps } from './select.props';
import styles from './select.module.css';
import cn from 'classnames';

const Select = forwardRef(({listCategories, selectedValue, ...props}: SelectProps,  ref: ForwardedRef<HTMLSelectElement>) => {
	return (
		<select {...props} className={cn(styles.selectInput, styles.minimal)} ref={ref} value={selectedValue}>
			{listCategories.map((category, index) => {
				if (category.id !== null) {
					return <option key={index} value={category.id}>{category.name}</option>;
				}
			})}
		</select>
	);
});

export default Select;
