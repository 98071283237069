import React from 'react';
import styles from './MenuMobile.module.css';
import { FooterProps } from './MenuMobile.props';
import cn from 'classnames';
// import { ReactComponent as Courses } from './ico/courses-menu.svg';
import { ReactComponent as MenuGamburger } from './ico/gamburger-menu.svg';
// import { ReactComponent as DashboardIco } from './ico/dashboard.svg';
// import { ReactComponent as CartIco } from './ico/cart.svg';
import { Link } from 'react-router-dom';


export const MenuMobile = ({className, ...props}: FooterProps): JSX.Element => {
	return (
		<>
			<div {...props} className={cn(styles.menu, className)}>
				{/* <Link to='/dashboard'><DashboardIco/></Link>
			<Link to='/managers'><CartIco/></Link>
			<Link to='/curators'><Courses/></Link> */}
				<Link to='/menu'><MenuGamburger/></Link>
			</div>
		</>
	);
};
