/* eslint-disable no-unused-vars */
import ApiClient from '../ApiClient';
import { NiceResponse } from '../dto/NiceResponse';
import AddSpentsRequest from './dto/AddSpentReq';
import GetSpentReq from './dto/GetSpentReq';
import SpentCustom from './dto/SpentCustom';


export class SpentsApi {
	constructor(private api: ApiClient) { }

	path = '/spents';

	async addSpent(req: AddSpentsRequest) {
		const revenue = await this.api.client.post<NiceResponse<SpentCustom>>(this.path + '/add', { ...req});
		return revenue.data;
	}

	async getSpents(req: GetSpentReq) {
		const revenue = await this.api.client.post<NiceResponse<SpentCustom[]>>(this.path + '/get', { ...req});
		return revenue.data;
	}

	async getOneSpent(id: string) {
		const revenue = await this.api.client.post<NiceResponse<SpentCustom>>(this.path + '/get-one', { id });
		return revenue.data;
	}

	async deleteSpent(id: string) {
		const revenue = await this.api.client.delete<NiceResponse<null>>(this.path + '/delete', { data: { id }});
		return revenue.data;
	}

	async updateSpent(req: SpentCustom) {
		const revenue = await this.api.client.put<NiceResponse<null>>(this.path + '/update', { ...req });
		return revenue.data;
	}
}