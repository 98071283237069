/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './CounterpartyAction.module.css';
import { ReactComponent as Minus } from '../../ico/minus.svg';
import { ReactComponent as Plus } from '../../ico/plus.svg';
import { ReactComponent as Transfer } from '../../ico/transfer.svg';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { FinanceCounterpartyGroupModel, FinanceCounterpartyModel } from '../../../../api/finance/dto/FinanceTypes';
import { useStores } from '../../../../hooks/useStore';
import { useApi } from '../../../../hooks/useApi';
import TextInput from '../../../../components/UI/finance/text/Text';
import TextBigInput from '../../../../components/UI/finance/textBig/TextBig';
import TextAreaInput from '../../../../components/UI/finance/textArea/TextArea';
import CounterpartyGroupInput from '../../../../components/UI/finance/counterpartyGroup/CounterpartyGroup';

interface PopupProps {
	counterparty: FinanceCounterpartyModel | null;
  onClose: () => void;
	updateCounterparty: () => void;
	resultCounterparty: (counterparty: FinanceCounterpartyModel | null) => void;
}

const CounterpartyActionPopUP: React.FC<PopupProps> = observer(({ onClose, counterparty = null, updateCounterparty, resultCounterparty }) => {

	const store = useStores();
	const api = useApi();

	const [name, setName] = useState<string | null>(null);
	const [description, setDescription] = useState<string | null>(null);
	const [contactName, setContactName] = useState<string | null>(null);
	const [email, setEmail] = useState<string | null>(null);
	const [phone, setPhone] = useState<string | null>(null);
	const [group, setGroup] = useState<FinanceCounterpartyGroupModel | null>(null);

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	useEffect(() => {
		if (counterparty) {
			setName(counterparty.name);
			setDescription(counterparty.description);
			setContactName(counterparty.contact_name);
			setEmail(counterparty.email);
			setPhone(counterparty.phone);
			setGroup(counterparty.group);
		}
	}, [counterparty]);

	const handleButtonClick = async () => {
		try {
			if (!name) {
				store.notificationsStore.setError('Введите название контрагента');
				return;
			}

			store.notificationsStore.showPreloader();

			if (!counterparty) {
				const {body} = await api.finance.CreateCounterparty({
					name,
					description,
					contact_name: contactName,
					email,
					phone,
					group_id: group?.id ? group?.id : null
				});
				updateCounterparty();
				if(body) {resultCounterparty(body)}
			} else {
				const {body} = await api.finance.UpdateCounterparty(counterparty.id, {
					name,
					description,
					contact_name: contactName,
					email,
					phone,
					group_id: group?.id ? group?.id : null
				});
				updateCounterparty();
				if(body) {resultCounterparty(body)}
			}
			
		} catch (e) {
			store.notificationsStore.setError(`${!counterparty ? 'Ошибка создания контрагента' : 'Ошибка обновления контрагента'}`);
		} finally {
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications(`${!counterparty ? 'Контрагент успешно добавлен' : 'Контрагент успешно обновлен'}`);
			onClose();
		}
	}

	const deleteCounterparty = async () => {
		try {
			store.notificationsStore.showPreloader();

			if (counterparty) {
				await api.finance.DeleteCounterparty(counterparty.id);
				return;
			}
		} catch (e) {
			store.notificationsStore.setError('Ошибка удаления контрагента');
		} finally {
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications('Контрагент успешно удален');
			onClose();
		}
	}

	
	return (
		<div className={styles.container} onClick={handleOverlayClick}>
			<div className={styles.form}>

				

				<div className={styles.bodyFormContainer}>

					<TextBigInput
						placeholderText='ООО «Рога и копыта»'
						resultText={setName}
						marginTop={20}
						defaultValue={name}
					/>

					<TextAreaInput
						placeholderText='Описание'
						resultText={setDescription}
						marginTop={-1}
						defaultValue={description}
					/>

					<TextInput
						labelText='Контакт'
						placeholderText='Иван Иванов'
						resultText={setContactName}
						marginTop={20}
						defaultValue={contactName}
					/>

					<TextInput
						labelText='Почта'
						placeholderText='hello@example.com'
						resultText={setEmail}
						marginTop={-1}
						defaultValue={email}
					/>

					<TextInput
						labelText='Телефон'
						placeholderText='+9 999 999 99 99'
						resultText={setPhone}
						marginTop={-1}
						defaultValue={phone}
					/>

					<CounterpartyGroupInput
						result={setGroup}
						marginTop={-1}
						counterpartyGroupDefault={group}
					/>

				</div>

				<div className={styles.formActionsContainer}>

					<div className={styles.formActionsContainerLeft}>

						<button className={styles.formActionsButtonMain} onClick={handleButtonClick}>{!counterparty ? 'Создать' : 'Изменить'}</button>

						<button className={styles.formActionsButtonSecond} onClick={onClose}>Отменить</button>

					</div>

					<div className={styles.formActionsContainerRight}>

						{counterparty && <button style={{color: '#df3535'}} className={styles.formActionsButtonSecond} onClick={deleteCounterparty}>Удалить</button>}

					</div>

				</div>

			</div>


		</div>
	);
});

export default CounterpartyActionPopUP;
