import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import { useEffect, useState } from 'react';
import { DealsTableProps } from './DealsTable.props';

const DealsTable = ({name, stats}: DealsTableProps) => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const [sortStats, setSortStats] = useState<{managerName: string, count: number, league: string, plan: number}[]>([]);

	useEffect(() => {
		const newStats = stats.sort((a, b) => b.count - a.count);
		setSortStats(newStats);
	}, [stats]);

	return (
		<>
			<Paper sx={{ width: '100%', maxWidth: 600, margin: '5px' }}>
				<TableContainer 
					style={{marginTop: 20, overflowX: 'auto'}}>
					<Table sx={{ minWidth: 320 }}>
						<TableHead>
							<TableRow>
								<TableCell>{name}</TableCell>
								<TableCell align="right">Лига</TableCell>
								<TableCell align="right">Факт</TableCell>
								<TableCell align="right">План</TableCell>
								<TableCell align="right">%</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{sortStats && sortStats.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => (
									<TableRow
										key={row.managerName + row.count + name}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row" style={{color: row.plan > row.count ? 'rgb(245, 79, 79)' : 'rgb(76, 175, 80)'}}>
											{row.managerName}
										</TableCell>
										<TableCell align="right">{row.league}</TableCell>
										<TableCell align="right">{row.count}</TableCell>
										<TableCell align="right">{row.plan}</TableCell>
										<TableCell align="right">{row.plan !== 0 ? `${(row.count/row.plan * 100).toFixed(0)}%` : 0}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
				<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={sortStats.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={(e, newPage) => setPage(newPage)}
					onRowsPerPageChange={(e) => {
						setRowsPerPage(+e.target.value);
						setPage(0);
					}}
				/>
			</Paper>
		</>
	)
};

export default DealsTable;