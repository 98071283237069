/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './Text.module.css';

interface TextInputProps {
	labelText: string;
	placeholderText: string;
	resultText: (text: string | null) => void;
	marginTop?: number;
	defaultValue?: string | null;
}

const TextInput: React.FC<TextInputProps> = observer(({resultText, labelText, placeholderText, marginTop = 0, defaultValue }) => {

	const [text, setText] = useState<string | null>(null);
	const [zIndex, setZIndex] = useState<number>(0);

	useEffect(() => {
		resultText(text);
	}, [text]);

	useEffect(() => {
		if (defaultValue) {
			setText(defaultValue);
		}
	}, [defaultValue]);

	const onFocus = () => setZIndex(1);
	const onBlur = () => setZIndex(0);

	return (
		<div className={styles.lineFormContainer} style={{marginTop, zIndex}}>
			<div className={styles.lineFormLabel}>{labelText}</div>

			<div className={styles.lineFormInputContainer}>

				<div className={styles.lineFormInputDropDown}>
					<input
						style={{zIndex}}
						type='text'
						value={text || ''}
						className={styles.lineFormInputStandart}
						placeholder={placeholderText}
						onChange={(e) => setText(e.target.value)}
						onFocus={onFocus}
						onBlur={onBlur}
					/>
				</div>

			</div>

		</div>

	)
	
});

export default TextInput;