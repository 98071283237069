import { useContext } from 'react';
import MainStore from '../stores/mainStore';
import { MainContext } from '../context/storeContext';

export const useStores = (): MainStore => {
	const mainStore = useContext(MainContext);

	if (!mainStore) {
		throw new Error('useStores must be used within a MainContextProvider');
	}

	return mainStore;
};
