/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './OperationsCategoty.module.css';
import { ReactComponent as Minus } from '../../ico/minus.svg';
import { ReactComponent as Plus } from '../../ico/plus.svg';
import { ReactComponent as Transfer } from '../../ico/transfer.svg';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { FinanceOperationCategoryGroupModel, FinanceOperationCategoryModel, OperationCategoryTypes, OperationTypes } from '../../../../api/finance/dto/FinanceTypes';
import { useStores } from '../../../../hooks/useStore';
import { useApi } from '../../../../hooks/useApi';
import TextInput from '../../../../components/UI/finance/text/Text';
import TextBigInput from '../../../../components/UI/finance/textBig/TextBig';
import TextAreaInput from '../../../../components/UI/finance/textArea/TextArea';
import CounterpartyGroupInput from '../../../../components/UI/finance/counterpartyGroup/CounterpartyGroup';
import OperationCategoryGroupInput from '../../../../components/UI/finance/operationCategoryGroup /OperationCategoryGroup ';
import TypeCategoryOperationInput from '../../../../components/UI/finance/typeCategoryOperation/TypeCategoryOperation';
import ColorPickerInput from '../../../../components/UI/finance/colorPicker/ColorPicker';

interface PopupProps {
	operationsCategoty: FinanceOperationCategoryModel | null;
	operationType: OperationTypes;
  onClose: () => void;
	updateOperationsCategoty: () => void;
	result: (category: FinanceOperationCategoryModel | null) => void;
}

const OperationsCategotyActionPopUP: React.FC<PopupProps> = observer(({ onClose, operationsCategoty = null, operationType, updateOperationsCategoty, result }) => {

	const store = useStores();
	const api = useApi();

	const [operaionType, setOperaionType] = useState<OperationTypes>(operationType);
	const [categoryType, setCategoryType] = useState<OperationCategoryTypes>(operationType === 'expense' ? 'expense_core_business' : operaionType === 'income' ? 'income_core_business' : 'transfer_core_business');
	const [name, setName] = useState<string | null>(null);
	const [description, setDescription] = useState<string | null>(null);
	const [color, setColor] = useState<string | null>(null);
	const [group, setGroup] = useState<FinanceOperationCategoryGroupModel | null>(null);
	const [nalogType, setNalogType] = useState<string | null>(null);


	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	useEffect(() => {
		if (operationsCategoty) {
			setName(operationsCategoty.name);
			setDescription(operationsCategoty.description);
			setColor(operationsCategoty.color);
			setGroup(operationsCategoty.group);
			setNalogType(operationsCategoty.nalog_type);
		}
	}, [operationsCategoty]);

	const handleButtonClick = async () => {
		try {
			if (!name) {
				store.notificationsStore.setError('Введите название для статьи операции');
				return;
			}

			store.notificationsStore.showPreloader();

			if (!operationsCategoty) {
				const {body: newOperationsCategoty} = await api.finance.CreateOperationCategory({
					name: name,
					description: description,
					color: color,
					group_id: group?.id ? group.id : null,
					category_type: categoryType,
					operation_type: operaionType,
					nalog_type: nalogType
				});

				result(newOperationsCategoty);
				return;
			} else {
				await api.finance.UpdateOperationCategory(operationsCategoty.id, {
					name: name,
					description: description,
					color: color,
					group_id: group?.id ? group.id : null,
					category_type: categoryType,
					operation_type: operaionType,
					nalog_type: nalogType
				});

				updateOperationsCategoty();
				return;
			}
			
			
		} catch (e) {
			store.notificationsStore.setError(`${!operationsCategoty ? 'Ошибка создания статьи' : 'Ошибка обновления статьи'}`);
		} finally {
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications(`${!operationsCategoty ? 'Статья успешно создана' : 'статья успешно обновлена'}`);
			onClose();
		}
	}

	const deleteOperationsCategoty = async () => {
		try {
			store.notificationsStore.showPreloader();

			if (operationsCategoty) {
				await api.finance.DeleteOperationCategory(operationsCategoty.id);
				return;
			}
		} catch (e) {
			store.notificationsStore.setError('Ошибка удаления группы');
		} finally {
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications('Группа успешно удалена');
			onClose();
		}
	}

	const resultTypeCategoryOperation = (iteam: string, nalogTypeSet: string | null) => {
		setCategoryType(iteam as OperationCategoryTypes);
		setNalogType(nalogTypeSet);
	}

	const [typeText] = useState<string>(operaionType === 'expense' ? 'расхода' : operaionType === 'income' ? 'прихода' : 'перевода');
	
	return (
		<div className={styles.container} onClick={handleOverlayClick}>
			<div className={styles.form}>

				<div className={styles.bodyFormContainer}>

					<div className={styles.bodyFormContainerTitle}>{!operationsCategoty ? 'Создание статьи ' + typeText : 'Обновление статьи ' + typeText}</div>

					<TextInput
						labelText='Название'
						placeholderText='Название статьи'
						resultText={setName}
						marginTop={0}
						defaultValue={name}
					/>

					<TextInput
						labelText='Описание'
						placeholderText='Описание статьи'
						resultText={setDescription}
						marginTop={-1}
						defaultValue={description}
					/>

					<OperationCategoryGroupInput
						result={setGroup}
						marginTop={-1}
						defaultValue={group}
						operatorType={operaionType}
					/>

					<TypeCategoryOperationInput 
						labelText={'Тип ' + typeText} 
						result={resultTypeCategoryOperation}
						operationType={operaionType}
						marginTop={20}
					/>

					<ColorPickerInput
						result={setColor}
						marginTop={20}
						defaultValue={color}
					/>

				</div>

				<div className={styles.formActionsContainer}>

					<div className={styles.formActionsContainerLeft}>

						<button className={styles.formActionsButtonMain} onClick={handleButtonClick}>{!operationsCategoty ? 'Создать' : 'Изменить'}</button>

						<button className={styles.formActionsButtonSecond} onClick={onClose}>Отменить</button>

					</div>

					<div className={styles.formActionsContainerRight}>

						{operationsCategoty && <button style={{color: '#df3535'}} className={styles.formActionsButtonSecond} onClick={deleteOperationsCategoty}>Удалить</button>}

					</div>

				</div>

			</div>


		</div>
	);
});

export default OperationsCategotyActionPopUP;
