import React from 'react';
import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import styles from './Layout.module.css';
import { MenuMobile } from './MenuMobile/MenuMobile';
import { Sidebar } from './Sidebar/Sidebar';
import { ReactComponent as Logo } from './Header/evo-logo.svg';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react';

export const Layout: React.FC = observer(() => {
	return (
		<div className={styles.container}>
			<div className={styles.left}>
				<div className={styles.logoFix}>
					<Logo/>
				</div>
				<Sidebar className={styles.sidebar}/>
			</div>
			<div className={styles.right}>
				<Header className={styles.header}/>
				<div className={styles.content}><Outlet /></div>
				<Footer className={styles.footerLayout}/>
			</div>
			<MenuMobile className={styles.menuLayout}/>
		</div>
	);
});