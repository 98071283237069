/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './CounterpartyGroup.module.css';
import { ReactComponent as Down } from '../ico/down.svg';
import { ReactComponent as Search } from '../ico/search.svg';
import { ReactComponent as Cancel } from '../ico/cancel.svg';
import { ReactComponent as CreateIco } from '../ico/create.svg';
import cn from 'classnames';
import { FinanceCounterpartyGroupModel, FinanceCounterpartyModel } from '../../../../api/finance/dto/FinanceTypes';
import { useApi } from '../../../../hooks/useApi';
import GetCounterpartReq from '../../../../api/finance/dto/Counterparty/GetCounterpartReq';
import CounterpartyActionPopUP from '../../../../pages/finance/popUps/counterparty/CounterpartyAction';
import CounterpartyGroupActionPopUP from '../../../../pages/finance/popUps/counterpartyGroup/CounterpartyGroupAction';

interface CounterpartyGroupInputProps {
	result: (counterparty: FinanceCounterpartyGroupModel | null) => void;
	marginTop?: number;
	counterpartyGroupDefault?: FinanceCounterpartyGroupModel | null;
}

const CounterpartyGroupInput: React.FC<CounterpartyGroupInputProps> = observer(({result, marginTop = 0, counterpartyGroupDefault = null }) => {

	const api = useApi();

	const [counterpartyGroupList, setCounterpartyGroupList] = useState<FinanceCounterpartyGroupModel[] | null>(null);
	const [selectedCounterpartyGroup, setSelectedCounterparty] = useState<FinanceCounterpartyGroupModel | null>(null);
	const [haveResults, setHaveResults] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [sortText, setSortText] = useState<string>('');
	const [showPopUp, setShowPopUp] = useState<boolean>(false);

	const tooglePopUp = () => setShowPopUp(!showPopUp);

	useEffect(() => {
		if (counterpartyGroupDefault) {
			setSelectedCounterparty(counterpartyGroupDefault);
		}
	},[counterpartyGroupDefault]);

	useEffect(() => {
		if (counterpartyGroupList && counterpartyGroupList.length > 0) {
			setHaveResults(true);
		} else {
			setHaveResults(false);
		}
	}, [counterpartyGroupList]);

	const getCounterpartyGroupList = async (skip: number, take: number) => {

		const data: GetCounterpartReq = {
			skip,
			take
		};

		if (sortText && sortText !== '') {
			data.name = sortText;
		}

		const { body } = await api.finance.GetCounterpartyGroups(data);

		if (body) {
			setCounterpartyGroupList(body);
		}
	}

	const updateCounterpartyGroupList = () => getCounterpartyGroupList(0, 100)

	useEffect(() => {
		getCounterpartyGroupList(0, 15);
	}, [sortText]);

	useEffect(() => {
		getCounterpartyGroupList(0, 100);
	},[selectedCounterpartyGroup]);

	useEffect(() => {
		result(selectedCounterpartyGroup);
	}, [selectedCounterpartyGroup]);

	const handleFocusText = (e: React.FocusEvent<HTMLInputElement>) => {
		e.target.value = '';
	};

	const handleFocusInputAccountFrom = () => {
		if (inputRef.current) {
			inputRef.current.focus();
			setIsOpen(!isOpen)
		}
	};

	const setNewCounterparty = (group: FinanceCounterpartyGroupModel | null) => {
		if (!group) {return}
		
		setSelectedCounterparty(group);
		setIsOpen(false);
		setSortText('');
	}

	return (
		<>
			{showPopUp && <CounterpartyGroupActionPopUP resultCounterpartyGroup={setNewCounterparty} counterpartyGroup={null} onClose={tooglePopUp} updateCounterpartyGroup={updateCounterpartyGroupList}/>}

			<div className={styles.lineFormContainer} style={{marginTop}}>
				<div className={styles.lineFormLabel}>Группа</div>

				<div className={styles.lineFormInputContainer}>

					<div className={styles.lineFormInputDropDown}>
						<input
							type='text'
							className={styles.lineFormInputStandart}
							placeholder='Группа'
							ref={inputRef}
							onChange={(e) => setSortText(e.target.value)}
							onBlur={() => {setIsOpen(false); setSortText('')}}
							onFocus={(e) => handleFocusText(e)}
						/>
						<button
							className={styles.lineFormInputButton}
							style={{display: !isOpen ? 'block' : 'none'}}
							tabIndex={-1}
							onClick={handleFocusInputAccountFrom}
						>
							<div className={styles.accountSelectorButton}>
								{!selectedCounterpartyGroup && <span>Без группы</span>}
								{selectedCounterpartyGroup && <span style={{color: 'black'}}>{selectedCounterpartyGroup.name}</span>}
							</div>
						</button>

						<span className={cn(styles.lineFormInputStandartSymbol, {[styles.clearButton]: selectedCounterpartyGroup})}
						>
							{!selectedCounterpartyGroup && !isOpen && <Down/>}
							{!selectedCounterpartyGroup && isOpen && <Search/>}
							{selectedCounterpartyGroup && 
									<Cancel
										onClick={() => {
											setSelectedCounterparty(null);
											setSortText('')
										}}
									/>
							}
						</span>
					</div>

					{isOpen && 
							<div className={styles.searchResultContainer}>
								<div className={styles.searchResultWraper}>
									<div className={styles.searchResultScroller}>
										{counterpartyGroupList && counterpartyGroupList.map((item, i) => {
											return (
												<>
													<div
														key={item.id}
														className={styles.searchResultItem}
														onMouseDown={() => {
															setSelectedCounterparty(item);
															setIsOpen(false);
															setSortText('');
														}}
													>
														{item.name}
													</div>
													{counterpartyGroupList.length - 1 === i && 
										<div className={styles.createRow}>
											<button className={styles.createRowButton}>
												<span className={styles.createRowButtonText} onMouseDown={() => setShowPopUp(true)}>
													<span>Создать</span>
												</span>
											</button>
										</div>
													}
												</>
											)
										})}

										{!haveResults && <div className={styles.searchEmptyResult}>Такого не найдено :-(</div>}

									</div>
									<div className={styles.createButton} onMouseDown={() => setShowPopUp(true)}>
										<CreateIco/>
									</div>
								</div>
							</div>
					}

				</div>

			</div>
		</>
		

	)
	
});

export default CounterpartyGroupInput;