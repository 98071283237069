/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './NalogType.module.css';
import { ReactComponent as Down } from '../ico/down.svg';
import { ReactComponent as Search } from '../ico/search.svg';
import { ReactComponent as Cancel } from '../ico/cancel.svg';
import cn from 'classnames';
import { useApi } from '../../../../hooks/useApi';
import { useStores } from '../../../../hooks/useStore';
import { NalogTypes, OperationCategoryTypes } from '../../../../api/finance/dto/FinanceTypes';

interface InputProps {
	result: (id: string | null) => void;
	defaultValue?: string | null;
}

interface NalogTypesList {
	id: NalogTypes;
	name: string;
}

const nalogList: NalogTypesList[] = [
	{ id: 'nds', name: 'Налог на добавленную стоимость' },
	{ id: 'payroll', name: 'Налог на фонд оплаты труда' },
	{ id: 'property', name: 'Налог на имущество (земельный и трнспортный)' },
	{ id: 'income', name: 'Налог на прибыль (единный при УСН и патенте, ЕНВД или НПД)' },
];

const NalogTypeInput: React.FC<InputProps> = observer(({result , defaultValue}) => {

	const [list] = useState<NalogTypesList[] | null>(nalogList);
	const [selected, setSelected] = useState<NalogTypesList | null>(null);

	const inputRef = useRef<HTMLInputElement>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [sortText, setSortText] = useState<string>('');

	useEffect(() => {
		result(selected?.id || null);
	}, [selected]);

	useEffect(() => {
		if (defaultValue) {
			setSelected(nalogList.find((item) => item.id === defaultValue) || null);
		}
	},[defaultValue]);

	const handleFocusText = (e: React.FocusEvent<HTMLInputElement>) => {
		e.target.value = '';
	};

	const handleFocusInput = () => {
		if (inputRef.current) {
			inputRef.current.focus();
			setIsOpen(!isOpen)
		}
	};

	return (

		<div className={styles.lineFormInputContainer}>

			<div className={styles.lineFormInputDropDown}>
				<input
					type='text'
					className={styles.lineFormInputStandart}
					placeholder='Выберите тип налога'
					ref={inputRef}
					onChange={(e) => setSortText(e.target.value)}
					onBlur={() => {setIsOpen(false); setSortText('')}}
					onFocus={(e) => handleFocusText(e)}
				/>
				<button
					className={styles.lineFormInputButton}
					style={{display: !isOpen ? 'block' : 'none'}}
					tabIndex={-1}
					onClick={handleFocusInput}
				>
					<div className={styles.accountSelectorButton}>
						{!selected && <span>Выберите тип налога</span>}
						{selected && <span style={{color: 'black'}}>{selected.name}</span>}
					</div>
				</button>

				<span className={cn(styles.lineFormInputStandartSymbol, {[styles.clearButton]: selected})}
				>
					{!selected && !isOpen && <Down/>}
					{!selected && isOpen && <Search/>}
					{selected && 
									<Cancel
										onClick={() => {
											setSelected(null);
											setSortText('')
										}}
									/>
					}
				</span>
			</div>

			{isOpen && 
							<div className={styles.searchResultContainer}>
								{list && list.map((account) => {
									if (sortText) {
										if (account.name.toLowerCase().includes(sortText.toLowerCase())) {
											return (
												<div
													key={account.id}
													className={styles.searchResultItem}
													onMouseDown={() => {
														setSelected(account);
														setIsOpen(false);
														setSortText('')
													}}
												>
													{account.name}
												</div>
											);
										}
									} else {
										return (
											<div
												key={account.id}
												className={styles.searchResultItem}
												onMouseDown={() => {
													setSelected(account);
													setIsOpen(false);
													setSortText('')
												}}
											>
												{account.name}
											</div>
										);
									}
								}
								)}
							</div>
			}

		</div>

	)
	
});

export default NalogTypeInput;