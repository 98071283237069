/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './Account.module.css';
import { ReactComponent as Down } from '../ico/down.svg';
import { ReactComponent as Search } from '../ico/search.svg';
import { ReactComponent as Cancel } from '../ico/cancel.svg';
import cn from 'classnames';
import { formatNumberWithSpaces } from '../../../../utils/numbers';
import { getSymbolFromCurrency } from '../../../../utils/text';
import { useApi } from '../../../../hooks/useApi';
import { useStores } from '../../../../hooks/useStore';
import { FinanceAccountModel } from '../../../../api/finance/dto/FinanceTypes';

interface AccountInputProps {
	labelName: string;
	resultAccount: (account: FinanceAccountModel | null) => void;
	accountDefault?: FinanceAccountModel | null;
	marginTop?: number;
}

const AccountInput: React.FC<AccountInputProps> = observer(({labelName, resultAccount, accountDefault, marginTop = 0 }) => {

	const api = useApi();
	const store = useStores();

	const [accountsList, setAccountsList] = useState<FinanceAccountModel[] | null>(null);
	const [accountFrom, setAccountFrom] = useState<FinanceAccountModel | null>(null);
	const inputRefAccountFrom = useRef<HTMLInputElement>(null);
	const [isAccountFromOpen, setIsAccountFromOpen] = useState<boolean>(false);
	const [sortTextFromAccount, setSortTextFromAccount] = useState<string>('');

	useEffect(() => {
		resultAccount(accountFrom);
	}, [accountFrom]);

	useEffect(() => {
		if (accountDefault) {
			setAccountFrom(accountDefault);
		}
	},[accountDefault]);

	useEffect(() => {
		getAccounts();
	}, []);

	const handleFocusText = (e: React.FocusEvent<HTMLInputElement>) => {
		e.target.value = '';
	};

	const handleFocusInputAccountFrom = () => {
		if (inputRefAccountFrom.current) {
			inputRefAccountFrom.current.focus();
			setIsAccountFromOpen(!isAccountFromOpen)
		}
	};

	const getAccounts = async () => {
		try {
			const { body } = await api.finance.GetAccounts();
	
			if (body) {
				setAccountsList(body);
			}
		} catch (error) {
			store.notificationsStore.setError('Ошибка при загрузке счетов');
		}
	}

	return (
		<div className={styles.lineFormContainer} style={{marginTop}}>
			<div className={styles.lineFormLabel}>{labelName}</div>

			<div className={styles.lineFormInputContainer}>

				<div className={styles.lineFormInputDropDown}>
					<input
						id='accountFrom'
						type='text'
						className={styles.lineFormInputStandart}
						placeholder='Выберите счёт'
						ref={inputRefAccountFrom}
						onChange={(e) => setSortTextFromAccount(e.target.value)}
						onBlur={() => {setIsAccountFromOpen(false); setSortTextFromAccount('')}}
						onFocus={(e) => handleFocusText(e)}
					/>
					<button
						className={styles.lineFormInputButton}
						style={{display: !isAccountFromOpen ? 'block' : 'none'}}
						tabIndex={-1}
						onClick={handleFocusInputAccountFrom}
					>
						<div className={styles.accountSelectorButton}>
							{!accountFrom && <span>Выберите счёт</span>}
							{accountFrom && <span style={{color: 'black'}}>{accountFrom.name}</span>}
						</div>
					</button>

					<span className={cn(styles.lineFormInputStandartSymbol, {[styles.clearButton]: accountFrom})}
					>
						{!accountFrom && !isAccountFromOpen && <Down/>}
						{!accountFrom && isAccountFromOpen && <Search/>}
						{accountFrom && 
									<Cancel
										onClick={() => {
											setAccountFrom(null);
											setSortTextFromAccount('')
										}}
									/>
						}
					</span>
				</div>

				{isAccountFromOpen && 
							<div className={styles.searchResultContainer}>
								{accountsList && accountsList.map((account) => {
									if (sortTextFromAccount) {
										if (account.name.toLowerCase().includes(sortTextFromAccount.toLowerCase())) {
											return (
												<div
													key={account.id}
													className={styles.searchResultItem}
													onMouseDown={() => {
														setAccountFrom(account);
														setIsAccountFromOpen(false);
														setSortTextFromAccount('')
													}}
												>
													{`${account.name} | ${formatNumberWithSpaces(account.balance)} ${getSymbolFromCurrency(account.currency)}`}
												</div>
											);
										}
									} else {
										return (
											<div
												key={account.id}
												className={styles.searchResultItem}
												onMouseDown={() => {
													setAccountFrom(account);
													setIsAccountFromOpen(false);
													setSortTextFromAccount('');
												}}
											>
												{`${account.name} | ${formatNumberWithSpaces(account.balance)} ${getSymbolFromCurrency(account.currency)}`}
											</div>
										);
									}
								}
								)}
							</div>
				}

			</div>

		</div>

	)
	
});

export default AccountInput;