export const getSymbolFromCurrency = (currency: string) => {
	if (currency === 'RUB') {
		return '₽';
	} else if (currency === 'USD') {
		return '$';
	} else if (currency === 'EUR') {
		return '€';
	}
	return currency;
}

export function generatePassword(): string {
	const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*';
	const minLength = 6;
	const maxLength = 20;

	// Функция для генерации случайного числа в диапазоне [min, max)
	const randomInt = (min: number, max: number): number => Math.floor(Math.random() * (max - min)) + min;

	// Генерация случайной длины пароля
	const length = randomInt(minLength, maxLength + 1);

	// Генерация пароля
	let password = '';
	for (let i = 0; i < length; i++) {
		const randomChar = charset[randomInt(0, charset.length)];
		password += randomChar;
	}

	return password;
}