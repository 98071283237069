/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './OperationCategory.module.css';
import { ReactComponent as Down } from '../ico/down.svg';
import { ReactComponent as Search } from '../ico/search.svg';
import { ReactComponent as Cancel } from '../ico/cancel.svg';
import { ReactComponent as CreateIco } from '../ico/create.svg';
import cn from 'classnames';
import { useApi } from '../../../../hooks/useApi';
import { FinanceOperationCategoryModel, OperationTypes } from '../../../../api/finance/dto/FinanceTypes';
import GetOperationCategoryReq from '../../../../api/finance/dto/OperationCategory/GetOperationCategoryReq';
import { useStores } from '../../../../hooks/useStore';
import OperationsCategotyActionPopUP from '../../../../pages/finance/popUps/operationsCategoty/OperationsCategoty';
import { set } from 'mobx';

interface OperationCategoryInputProps {
	resultOperationCategory: (operationCategory: FinanceOperationCategoryModel | null) => void;
	marginTop?: number;
	operationType: OperationTypes;
	operationCategoryDefault?: FinanceOperationCategoryModel | null;
}

interface GroupedCategory {
	categoryName: string | null;
	categories: FinanceOperationCategoryModel[];
}

const OperationCategoryInput: React.FC<OperationCategoryInputProps> = observer(({resultOperationCategory, operationType, marginTop = 0, operationCategoryDefault = null }) => {

	const api = useApi();
	const store = useStores();

	const [categoryList, setCategoryList] = useState<FinanceOperationCategoryModel[] | null>(null);
	const [groupedCategoryList, setGroupedCategoryList] = useState<GroupedCategory[] | null>(null);
	const [haveResults, setHaveResults] = useState<boolean>(false);
	const [selectedOperationCategory, setSelectedOperationCategory] = useState<FinanceOperationCategoryModel | null>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [sortText, setSortText] = useState<string>('');
	const [placeholderName, setPlaceholderName] = useState<string>('');
	const [showPopUp, setShowPopUp] = useState<boolean>(false);

	const tooglePopUp = () => setShowPopUp(!showPopUp);

	useEffect(() => {
		if (operationCategoryDefault) {
			setSelectedOperationCategory(operationCategoryDefault);
		}
	},[operationCategoryDefault]);

	useEffect(() => {
		if (groupedCategoryList && groupedCategoryList.length > 0) {
			setHaveResults(true);
		} else {
			setHaveResults(false);
		}
	}, [groupedCategoryList]);

	useEffect(() => {
		if (categoryList) {
			const groupedCategoryListNew: GroupedCategory[] = [];
			categoryList.forEach((item) => {
				const category = groupedCategoryListNew.find((group) => group.categoryName === item?.group?.name);
				if (category) {
					category.categories.push(item);
				} else {
					groupedCategoryListNew.push({categoryName: item?.group?.name ? item?.group?.name : null, categories: [item]});
				}
			});
			setGroupedCategoryList(groupedCategoryListNew);
		}
	}, [categoryList]);

	useEffect(() => {
		if (operationType === 'income') {
			setPlaceholderName('Доход');
		} else if (operationType === 'expense') {
			setPlaceholderName('Расход');
		} else if (operationType === 'transfer') {
			setPlaceholderName('Перевод');
		}
	}, [operationType]);

	useEffect(() => {
		setSelectedOperationCategory(null);
		getOperationCategoryList(0, 100);
	}, [operationType]);

	const getOperationCategoryList = async (skip: number, take: number) => {
		try {
			const data: GetOperationCategoryReq = {
				skip,
				take
			};
	
			if (sortText && sortText !== '') {
				data.name = sortText;
			}
	
			if (operationType) {
				data.operationType = operationType;
			}
	
			const { body } = await api.finance.GetOperationCategories(data);
	
			if (body) {
				setCategoryList(body);
			}
		} catch (error) {
			store.notificationsStore.setError('Ошибка при загрузке статей')
		}
	}

	const updateList = async () => getOperationCategoryList(0, 100);

	const setCategory = (category: FinanceOperationCategoryModel | null) => {

		if (!category) {return}

		setSelectedOperationCategory(category);
		setIsOpen(false);
		setSortText('');
	}

	useEffect(() => {
		getOperationCategoryList(0, 15);
	}, [sortText]);

	useEffect(() => {
		getOperationCategoryList(0, 100);
	},[selectedOperationCategory, operationType]);

	useEffect(() => {
		resultOperationCategory(selectedOperationCategory);
	}, [selectedOperationCategory]);

	const handleFocusText = (e: React.FocusEvent<HTMLInputElement>) => {
		e.target.value = '';
	};

	const handleFocusInputAccountFrom = () => {
		if (inputRef.current) {
			inputRef.current.focus();
			setIsOpen(!isOpen)
		}
	};

	return (
		<>
			{showPopUp && <OperationsCategotyActionPopUP operationsCategoty={null} operationType={operationType} onClose={tooglePopUp} updateOperationsCategoty={updateList} result={setCategory}/>}
			<div className={styles.lineFormContainer} style={{marginTop}}>
				<div className={styles.lineFormLabel}>Статья</div>

				<div className={styles.lineFormInputContainer}>

					<div className={styles.lineFormInputDropDown}>
						<input
							type='text'
							className={styles.lineFormInputStandart}
							placeholder={placeholderName}
							ref={inputRef}
							onChange={(e) => setSortText(e.target.value)}
							onBlur={() => {setIsOpen(false); setSortText('')}}
							onFocus={(e) => handleFocusText(e)}
						/>
						<button
							className={styles.lineFormInputButton}
							style={{display: !isOpen ? 'block' : 'none'}}
							tabIndex={-1}
							onClick={handleFocusInputAccountFrom}
						>
							<div className={styles.accountSelectorButton}>
								{!selectedOperationCategory && <span>{placeholderName}</span>}
								{selectedOperationCategory && <span style={{color: 'black'}}>{selectedOperationCategory.name}</span>}
							</div>
						</button>

						<span className={cn(styles.lineFormInputStandartSymbol, {[styles.clearButton]: selectedOperationCategory})}
						>
							{!selectedOperationCategory && !isOpen && <Down/>}
							{!selectedOperationCategory && isOpen && <Search/>}
							{selectedOperationCategory && 
									<Cancel
										onClick={() => {
											setSelectedOperationCategory(null);
											setSortText('')
										}}
									/>
							}
						</span>
					</div>

					{isOpen && 
				<div className={styles.searchResultContainer}>

					<div className={styles.searchResultWraper}>

						<div className={styles.searchResultScroller}>

							{groupedCategoryList && groupedCategoryList.length > 0 && groupedCategoryList
								.map((category, i) => (
									<>
										<div>
											{category.categoryName &&
					<div key={category.categoryName} className={styles.searchResultCategoryItem}>
						{category.categoryName}
					</div>
											}
											{category.categories.map((item) => {
												return (
													<div
														style={{paddingLeft: category.categoryName ? 20 : 6}}
														key={item.id}
														className={styles.searchResultItem}
														onMouseDown={() => {
															setSelectedOperationCategory(item);
															setIsOpen(false);
															setSortText('');
														}}
													>
														{item.color && <div style={{width: 10, height: 10, borderRadius: 100, backgroundColor: item.color, marginRight: 5}}/>}
														{item.name}
													</div>
												)
											})}
										</div>
										{groupedCategoryList.length - 1 === i && 
										<div className={styles.createRow}>
											<button className={styles.createRowButton}>
												<span className={styles.createRowButtonText} onMouseDown={() => setShowPopUp(true)}>
													<span>Создать</span>
												</span>
											</button>
										</div>
										}
									</>
								))}
					
					
							{!haveResults && <div className={styles.searchEmptyResult}>Такого не найдено :-(</div>}

						</div>

						<div className={styles.createButton} onMouseDown={() => setShowPopUp(true)}>
							<CreateIco/>
						</div>

					</div>
				</div>

					}

				</div>

			</div>
		</>

	)
	
});

export default OperationCategoryInput;