import React from 'react';
import styles from '../Notification/Notification.module.css';
import { ReactComponent as Cross } from './ico/cross.svg';
import { ReactComponent as Check } from './ico/check.svg';
import { errorMessage } from '../../../utils/error';
import { useStores } from '../../../hooks/useStore';
import { observer } from 'mobx-react';

export const Notification: React.FC = observer(() => {
	
	const { notificationsStore }  = useStores();

	return (
		<>
			{notificationsStore.showError && 
				<div className={styles.notification} id='error'>
					<Cross/>
					<p>{errorMessage(notificationsStore.errorMessage)}</p>
				</div>
			}

			{notificationsStore.showNotifications && 
				<div className={styles.notification} id='success'>
					<Check/>
					<p>{notificationsStore.notificationMessage}</p>
				</div>
			}
		</>
	);
});

export default Notification;