/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import styles from './spents.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import moment from 'moment';
import Indicator from '../../components/UI/Indicator/Indicator';
require('moment/locale/ru');

import Select from '../../components/UI/Select/Select';

import { Managers as ManagersModel } from '../../models/managers';
import { DealTypesStats } from '../../api/analytics/dto/DialTypesStatsResp.dto';
import DealsTable from '../../components/UI/DealTable/DealsTable';
import NormsLead from '../../api/managers/dto/GetNormsLead.dto';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { DealLog } from '../../api/managers/dto/GetDealsLogsResp.dto';
import SpentCustom from '../../api/spents/dto/SpentCustom';
import { formatNumberWithSpaces } from '../../utils/numbers';
import { Button } from '../../components/UI/Button/Button';
import { ReactComponent as CrossIco } from './icon/delete.svg';
import { ReactComponent as EditIco } from './icon/edit.svg';
import { set } from 'mobx';
import DatePickerSingle from '../../components/UI/DatePicker/datePickerSingle';
import { Input } from '../../components/UI/input/input';
import { formatMySQLDatetime } from '../../utils/date';

const Spents: React.FC = observer(() => {

	const api = useApi();
	const store = useStores();

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const [editSpentId, setEditSpentId] = useState<string | null>(null);
	const [editSpent, setEditSpent] = useState<SpentCustom | null>(null);

	const [addSpent, setAddSpent] = useState<SpentCustom | null>(null);

	const [showForm, setShowForm] = useState<boolean>(false);

	const [spents, setSpents] = useState<SpentCustom[]>([]);

	useEffect(() => {
		loadSpents();
	}, [store.dateStore.startDate, store.dateStore.endDate]);

	const loadSpents = async () => {
		try {
			store.notificationsStore.showPreloader();
			const {body} = await api.spents.getSpents({
				start_date: store.dateStore.startDate,
				end_date: store.dateStore.endDate,
			});
	
			if (body) {
				setSpents(body);
			}
		} catch (e) {
			store.notificationsStore.setError('Ошибка при загрузке данных')
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	}

	const deleteSpent = async (id: string) => {
		try {
			store.notificationsStore.showPreloader();
			await api.spents.deleteSpent(id);
			store.notificationsStore.setNotifications('Успешно удалено');
		} catch (e) {
			store.notificationsStore.setError('Ошибка при удалении')
		} finally {
			setSpents(spents.filter(spent => spent.id !== id));
			store.notificationsStore.hidePreloader();
		}
	}

	const onChangeDate = (date: string) => {
		setEditSpent({
			...editSpent,
			date,
		} as SpentCustom);
	}

	const changeDateAdd = (date: string) => {
		setAddSpent({
			...addSpent,
			date,
		} as SpentCustom);
	}

	const updateSpent = async () => {
		try {
			store.notificationsStore.showPreloader();

			const data = editSpent as SpentCustom;

			data.spent = Number(data.spent);

			data.date = moment(data.date).format('YYYY-MM-DD');

			await api.spents.updateSpent(data);
			store.notificationsStore.setNotifications('Успешно обновлено');
		} catch (e) {
			store.notificationsStore.setError('Ошибка при обновлении')
		} finally {
			setEditSpentId(null);
			loadSpents();
			store.notificationsStore.hidePreloader();
		}
	}

	const addSpentToBase = async () => {
		try {
			store.notificationsStore.showPreloader();

			const data = addSpent as SpentCustom;
			data.spent = Number(data.spent);
			data.date = moment(data.date).format('YYYY-MM-DD');

			await api.spents.addSpent(data as SpentCustom);
			store.notificationsStore.setNotifications('Успешно добавлено');
		} catch (e) {
			store.notificationsStore.setError('Ошибка при добавлении')
		} finally {
			loadSpents();
			setShowForm(false);
			setAddSpent(null);
			store.notificationsStore.hidePreloader();
		}
	}
	

	return (
		<>
			<div className={styles.container}>
				<div className={styles.pageName}>
					<div className={styles.top}>
						<div className={styles.title}>
							<h2>Расходы</h2>
						</div>
						<Button style={{maxWidth: 150}} appearance="auth" onClick={() => setShowForm(showForm ? false : true)}>{showForm ? 'Закрыть' : 'Добавить'}</Button>
					</div>

				</div>

				{showForm && 
				<>
					<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%', maxWidth: 1200, margin: '5px'}}>

						<div style={{display: 'flex', flexDirection: 'column', padding: 5}}>
							<DatePickerSingle
								defaultValue={formatMySQLDatetime(new Date())}
								// @ts-ignore
								onChangeDate={changeDateAdd}
							/>
							<Input
								style={{margin: 5}}
								placeholder='UTM Source'
								textLabel='UTM Source'
								// @ts-ignore
								onChange={(e) => setAddSpent({...addSpent, utm_source: e.target.value})}
							/>
						</div>

						<div style={{display: 'flex', flexDirection: 'column', padding: 5}}>
							<Input 
								style={{margin: 5}}
								placeholder='UTM Medium'
								textLabel='UTM Medium'
								// @ts-ignore
								onChange={(e) => setAddSpent({...addSpent, utm_medium: e.target.value})}
							/>
							<Input
								style={{margin: 5}}
								placeholder='UTM Campaign'
								textLabel='UTM Campaign'

								// @ts-ignore
								onChange={(e) => setAddSpent({...addSpent, utm_campaign: e.target.value})}
							/>
						</div>

						<div style={{display: 'flex', flexDirection: 'column', padding: 5}}>
							<Input 
								style={{margin: 5}}
								placeholder='Сумма'
								textLabel='Сумма'
								type='number'
								// @ts-ignore
								onChange={(e) => setAddSpent({...addSpent, spent: Number(e.target.value)})}
							/>
							<Button 
								style={{margin: 5}}
								appearance="auth" 
								onClick={addSpentToBase}
							>
							Отправить
							</Button>
						</div>
					</div>
				</>
				}

				<div style={{ width: '100%', maxWidth: 1200, margin: '5px' }}>

					<Paper sx={{ width: '100%', maxWidth: 1200, margin: '5px', overflowX: 'auto' }}>
						<TableContainer 
							style={{marginTop: 20, overflowX: 'auto'}}>
							<Table sx={{ minWidth: 320 }}>
								<TableHead>
									<TableRow>
										<TableCell>Дата</TableCell>
										<TableCell align="right">UTM Source</TableCell>
										<TableCell align="right">UTM Medium</TableCell>
										<TableCell align="right">UTM Campaign</TableCell>
										<TableCell align="right">Расход</TableCell>
										<TableCell align="right">Действие</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{spents && spents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => (
											<TableRow
												key={row.id}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												{editSpentId === row.id && <>
													<TableCell component="th" scope="row">
														<DatePickerSingle 
															defaultValue={editSpent?.date ? editSpent.date : ''}
															onChangeDate={onChangeDate}
														/>
													</TableCell>

													<TableCell align="right">
														<input 
															value={editSpent?.utm_source || ''}
															style={{maxWidth: 70, borderColor: 'transparent', borderBottom: '1px solid #E0E0E0'}}
															// @ts-ignore
															onChange={(e) => setEditSpent({...editSpent, utm_source: e.target.value})}
														/>
													</TableCell>

													<TableCell align="right">
														<input 
															value={editSpent?.utm_medium || ''}
															style={{maxWidth: 70, borderColor: 'transparent', borderBottom: '1px solid #E0E0E0'}}
															// @ts-ignore
															onChange={(e) => setEditSpent({...editSpent, utm_medium: e.target.value})}
														/>
													</TableCell>

													<TableCell align="right">
														<input 
															value={editSpent?.utm_campaign || ''}
															style={{maxWidth: 70, borderColor: 'transparent', borderBottom: '1px solid #E0E0E0'}}
															// @ts-ignore
															onChange={(e) => setEditSpent({...editSpent, utm_campaign: e.target.value})}
														/>
													</TableCell>

													<TableCell align="right">
														<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
															<input 
																type='number'
																value={editSpent?.spent || ''}
																style={{textAlign: 'right', maxWidth: 70, borderColor: 'transparent', borderBottom: '1px solid #E0E0E0'}}
																// @ts-ignore
																onChange={(e) => setEditSpent({...editSpent, spent: e.target.value})}
															/>
														</div>
													</TableCell>

													<TableCell align="right">
														<Button
															appearance="auth" 
															onClick={updateSpent}>
																Сохранить
														</Button>
													</TableCell>
												</>}

												{editSpentId !== row.id &&
												<>
													<TableCell component="th" scope="row">
														{row.date ? moment(row.date).format('DD.MM.YYYY') : ''}
													</TableCell>
													<TableCell align="right">{row.utm_source}</TableCell>
													<TableCell align="right">{row.utm_medium}</TableCell>
													<TableCell align="right">{row.utm_campaign}</TableCell>
													<TableCell align="right">{row.spent ? `${formatNumberWithSpaces(row.spent.toFixed(0))} $`: 0}</TableCell>
													<TableCell align="right">
														<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
															<EditIco className={styles.tagDeleteIco} 
																onClick={() => {
																	setEditSpentId(row.id);
																	setEditSpent(row);
																}}
															/>
															<CrossIco className={styles.tagDeleteIco} 
																onClick={() => deleteSpent(row.id)}
															/>
														</div>
													</TableCell>
												</>
												}

											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
						<TablePagination
							labelRowsPerPage="Строк на странице"
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={spents ? spents.length : 0}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Paper>

				</div>
				

			</div>

		</>
	);
});

export default Spents;