/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './OperationCategoryGroup.module.css';
import { ReactComponent as Down } from '../ico/down.svg';
import { ReactComponent as Search } from '../ico/search.svg';
import { ReactComponent as Cancel } from '../ico/cancel.svg';
import { ReactComponent as CreateIco } from '../ico/create.svg';
import cn from 'classnames';
import { useApi } from '../../../../hooks/useApi';
import GetCounterpartReq from '../../../../api/finance/dto/Counterparty/GetCounterpartReq';
import { FinanceOperationCategoryGroupModel, OperationTypes } from '../../../../api/finance/dto/FinanceTypes';
import OperationsCategotyGroupActionPopUP from '../../../../pages/finance/popUps/operationsCategotyGroup/OperationsCategotyGroup';
import GetOperationCategoryGroupReq from '../../../../api/finance/dto/OperationCategoryGroup/GetOperationCategoryGroupReq';

interface Props {
	result: (counterparty: FinanceOperationCategoryGroupModel | null) => void;
	marginTop?: number;
	defaultValue?: FinanceOperationCategoryGroupModel | null;
	operatorType: OperationTypes;
}

const OperationCategoryGroupInput: React.FC<Props> = observer(({result, marginTop = 0, defaultValue = null, operatorType }) => {

	const api = useApi();

	const [list, setList] = useState<FinanceOperationCategoryGroupModel[] | null>(null);
	const [selected, setSelected] = useState<FinanceOperationCategoryGroupModel | null>(null);
	const [haveResults, setHaveResults] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [sortText, setSortText] = useState<string>('');
	const [showPopUp, setShowPopUp] = useState<boolean>(false);

	const tooglePopUp = () => setShowPopUp(!showPopUp);

	useEffect(() => {
		if (defaultValue) {
			setSelected(defaultValue);
		}
	},[defaultValue]);

	useEffect(() => {
		if (list && list.length > 0) {
			setHaveResults(true);
		} else {
			setHaveResults(false);
		}
	}, [list]);

	const getList = async (skip: number, take: number) => {

		const data: GetOperationCategoryGroupReq = {
			skip,
			take,
			typeOperation: operatorType
		};

		if (sortText && sortText !== '') {
			data.name = sortText;
		}

		const { body } = await api.finance.GetOperationCategoryGroups(data);

		if (body) {
			setList(body);
		}
	}

	const updateList = () => getList(0, 100)

	useEffect(() => {
		getList(0, 15);
	}, [sortText]);

	useEffect(() => {
		getList(0, 100);
	},[selected]);

	useEffect(() => {
		result(selected);
	}, [selected]);

	const handleFocusText = (e: React.FocusEvent<HTMLInputElement>) => {
		e.target.value = '';
	};

	const handleFocusInputAccountFrom = () => {
		if (inputRef.current) {
			inputRef.current.focus();
			setIsOpen(!isOpen)
		}
	};

	const setNewItem = (item: FinanceOperationCategoryGroupModel | null) => {
		if (!item) {return}
		
		setSelected(item);
		setIsOpen(false);
		setSortText('');
	}

	return (
		<>
			{showPopUp && <OperationsCategotyGroupActionPopUP operationType={operatorType} result={setNewItem} defaultValue={null} onClose={tooglePopUp} update={updateList}/>}

			<div className={styles.lineFormContainer} style={{marginTop}}>
				<div className={styles.lineFormLabel}>Группа</div>

				<div className={styles.lineFormInputContainer}>

					<div className={styles.lineFormInputDropDown}>
						<input
							type='text'
							className={styles.lineFormInputStandart}
							placeholder='Группа'
							ref={inputRef}
							onChange={(e) => setSortText(e.target.value)}
							onBlur={() => {setIsOpen(false); setSortText('')}}
							onFocus={(e) => handleFocusText(e)}
						/>
						<button
							className={styles.lineFormInputButton}
							style={{display: !isOpen ? 'block' : 'none'}}
							tabIndex={-1}
							onClick={handleFocusInputAccountFrom}
						>
							<div className={styles.accountSelectorButton}>
								{!selected && <span>Без группы</span>}
								{selected && <span style={{color: 'black'}}>{selected.name}</span>}
							</div>
						</button>

						<span className={cn(styles.lineFormInputStandartSymbol, {[styles.clearButton]: selected})}
						>
							{!selected && !isOpen && <Down/>}
							{!selected && isOpen && <Search/>}
							{selected && 
									<Cancel
										onClick={() => {
											setSelected(null);
											setSortText('')
										}}
									/>
							}
						</span>
					</div>

					{isOpen && 
							<div className={styles.searchResultContainer}>
								<div className={styles.searchResultWraper}>
									<div className={styles.searchResultScroller}>
										{list && list.map((item, i) => {
											return (
												<>
													<div
														key={item.id}
														className={styles.searchResultItem}
														onMouseDown={() => {
															setSelected(item);
															setIsOpen(false);
															setSortText('');
														}}
													>
														{item.name}
													</div>
													{list.length - 1 === i && 
										<div className={styles.createRow}>
											<button className={styles.createRowButton}>
												<span className={styles.createRowButtonText} onMouseDown={() => setShowPopUp(true)}>
													<span>Создать</span>
												</span>
											</button>
										</div>
													}
												</>
											)
										})}

										{!haveResults && <div className={styles.searchEmptyResult}>Такого не найдено :-(</div>}

									</div>
									<div className={styles.createButton} onMouseDown={() => setShowPopUp(true)}>
										<CreateIco/>
									</div>
								</div>
							</div>
					}

				</div>

			</div>
		</>
		

	)
	
});

export default OperationCategoryGroupInput;