import { makeAutoObservable, runInAction } from 'mobx';
import MainStore from './mainStore';
import { Revenue, RevenueProducts } from '../models/analytics';

export default class AnalyticsStore {
	private mainStore: MainStore;
	revenueCurentPeriod: Revenue[] = [];
	revenueLastPeriod: Revenue[] = [];

	revenueProducts: RevenueProducts[] = [];

	constructor(mainStore: MainStore) {
		makeAutoObservable(this);

		this.mainStore = mainStore;
	}

	setrevenueCurentPeriod = (revenue: Revenue[]) => {
		runInAction(() => this.revenueCurentPeriod = revenue);
	}

	setrevenueLastPeriod = (revenue: Revenue[]) => {
		runInAction(() => this.revenueLastPeriod = revenue);
	}

	setRevenueProducts = (revenueProducts: RevenueProducts[]) => {
		runInAction(() => this.revenueProducts = revenueProducts);
	}

}