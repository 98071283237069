import { makeAutoObservable, runInAction, reaction } from 'mobx';
import MainStore from './mainStore';
import moment from 'moment';
import { formatMySQLDatetime } from '../utils/date';

export default class DateStore {
	private mainStore: MainStore;
	startDate: string = '';
	endDate: string = '';

	lastPeriodStartDate: string = '';
	lastPeriodEndDate: string = '';

	constructor(mainStore: MainStore) {
		makeAutoObservable(this);

		this.mainStore = mainStore;

		reaction(
			() => this.startDate,
			() => {this.setLastPeriodDate();}
		);

		reaction(
			() => this.endDate,
			() => {this.setLastPeriodDate();}
		);
	}

	setStartDate = (date: string) => {
		runInAction(() => this.startDate = date);
	}

	setEndDate = (date: string) => {
		runInAction(() => this.endDate = date);
	}

	private setLastPeriodDate = () => {
		runInAction(() => {
			if (this.startDate === '' || this.endDate === '') {return;}

			const startDate = moment(this.startDate);
			const endDate = moment(this.endDate);

			const diff = endDate.diff(startDate, 'days') + 1;

			this.lastPeriodStartDate = formatMySQLDatetime(startDate.subtract(diff, 'days').toDate());
			this.lastPeriodEndDate = formatMySQLDatetime(endDate.subtract(diff, 'days').toDate(), true);
		});
	}

	
}