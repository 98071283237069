import { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import styles from './dasboard.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
// import { LineChart } from '@mui/x-charts/LineChart';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { formatNumberWithSpaces } from '../../utils/numbers';
import Indicator from '../../components/UI/Indicator/Indicator';
require('moment/locale/ru');

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TooltipComparison from '../../components/UI/TooltipComparison/tooltipComparison';
import { TablePagination } from '@mui/material';
import { CountryInfo, RevenueInfo } from '../../models/analytics';
import RepeatSales from '../../api/analytics/dto/RepeatSales';

type formatedRevenue = {
	date: string;
	value: number;
	dateLast?: string;
	valueLast?: number;
}

const Dasboard: React.FC = observer(() => {
	const api = useApi();
	const store = useStores();

	const [formatedRevenueCurrent, setFormatedRevenueCurrent] = useState<formatedRevenue[]>([]);
	// const [formatedRevenueLast, setFormatedRevenueLast] = useState<formatedRevenue[]>([]);

	const [totalCurrentRevenue, setTotalCurrentRevenue] = useState<number>(0);
	const [totalLastRevenue, setTotalLastRevenue] = useState<number>(0);

	const [totalCurrentIncome, setTotalCurrentIncome] = useState<number>(0);
	const [totalLastIncome, setTotalLastIncome] = useState<number>(0);

	const [totalCurrentSales, setTotalCurrentSales] = useState<number>(0);
	const [totalLastSales, setTotalLastSales] = useState<number>(0);

	const [revenuesInfo, setRevenuesInfo] = useState<RevenueInfo>();

	const [repeatSales, setRepeatSales] = useState<RepeatSales>({revenue_per_one_customer: 0});
	const [repeatSalesLast, setRepeatSalesLast] = useState<RepeatSales>({revenue_per_one_customer: 0});

	useEffect(() => {

		const startDate = moment(store.dateStore.startDate);
		const endDate = moment(store.dateStore.endDate);

		const diffDays = endDate.diff(startDate, 'days') + 1;

		setTotalCurrentRevenue(store.analyticsStore.revenueCurentPeriod?.reduce((acc, item) => acc + item.revenue, 0))
		setTotalLastRevenue(store.analyticsStore.revenueLastPeriod?.reduce((acc, item) => acc + item.revenue, 0))

		setTotalCurrentIncome(store.analyticsStore.revenueCurentPeriod?.reduce((acc, item) => acc + item.income, 0))
		setTotalLastIncome(store.analyticsStore.revenueLastPeriod?.reduce((acc, item) => acc + item.income, 0))

		setTotalCurrentSales(store.analyticsStore.revenueCurentPeriod?.reduce((acc, item) => acc + item.sales, 0))
		setTotalLastSales(store.analyticsStore.revenueLastPeriod?.reduce((acc, item) => acc + item.sales, 0))

		setFormatedRevenueCurrent(store.analyticsStore.revenueCurentPeriod?.map(item => {
			return {
				date: moment(item.date).format('DD MMM'),
				value: item.revenue,
				dateLast: moment(item.date).subtract(diffDays, 'days').format('DD MMM'),
				valueLast: store.analyticsStore.revenueLastPeriod?.find(itemLast => moment(itemLast.date).format('DD MMM') === moment(item.date).subtract(diffDays, 'days').format('DD MMM'))?.revenue,
			}
		}));

	}, [store.analyticsStore.revenueCurentPeriod, store.analyticsStore.revenueLastPeriod]);

	useEffect(() => {
		(async () => {
			try {
				store.notificationsStore.showPreloader();
				const {body} = await api.analytics.getRevenue({
					start_date: store.dateStore.startDate,
					end_date: store.dateStore.endDate,
				});

				if (body) {
					store.analyticsStore.setrevenueCurentPeriod(body);
				}

				const {body: bodyLast} = await api.analytics.getRevenue({
					start_date: store.dateStore.lastPeriodStartDate,
					end_date: store.dateStore.lastPeriodEndDate,
				});

				if (bodyLast) {
					store.analyticsStore.setrevenueLastPeriod(bodyLast);
				}


				const {body: bodyProducts} = await api.analytics.getRevenueProducts({
					start_date: store.dateStore.startDate,
					end_date: store.dateStore.endDate,
				});

				if (bodyProducts) {
					store.analyticsStore.setRevenueProducts(bodyProducts);
				}
				
				const {body: revenueInfo} = await api.analytics.getRevenueInfo({
					start_date: store.dateStore.startDate,
					end_date: store.dateStore.endDate,
				});

				if (revenueInfo) {
					setRevenuesInfo(revenueInfo);
				}

				const {body: getRepeatSales} = await api.analytics.getRepeatSales(store.dateStore.endDate);

				if (getRepeatSales) {
					setRepeatSales(getRepeatSales);
				}

				const {body: getRepeatSalesLast} = await api.analytics.getRepeatSales(store.dateStore.lastPeriodEndDate);

				if (getRepeatSalesLast) {
					setRepeatSalesLast(getRepeatSalesLast);
				}

			} catch (error) {
				store.notificationsStore.setError('Ошибка при загрузке данных')
			} finally {
				store.notificationsStore.hidePreloader();
			}
		})();
	}, [store.dateStore.startDate, store.dateStore.endDate]);


	//Продукты
	const [pageProducts, setPageProducts] = useState(0);
	const [rowsPerPageProducts, setRowsPerPageProducts] = useState(10);

	const handleChangePageProducts = (event: unknown, newPage: number) => {
		setPageProducts(newPage);
	};

	const handleChangeRowsPerPageProducts = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPageProducts(+event.target.value);
		setPageProducts(0);
	};

	//Страны текущие
	const [currentCountry, setCurrentCountry] = useState<CountryInfo[]>();
	const [pageCountry, setPageCountry] = useState(0);
	const [rowsPerPageCountry, setRowsPerPageCountry] = useState(10);

	const handleChangePageCountry = (event: unknown, newPage: number) => {
		setPageCountry(newPage);
	};

	const handleChangeRowsPerPageCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPageCountry(+event.target.value);
		setPageCountry(0);
	};

	//UTM Source
	const [currentUtmSource, setCurrentUtmSource] = useState<{utm_name: string, total: number}[]>();
	const [pageUtmSource, setPageUtmSource] = useState(0);
	const [rowsPerPageUtmSource, setRowsPerPageUtmSource] = useState(10);

	const handleChangePageUtmSource = (event: unknown, newPage: number) => {
		setPageUtmSource(newPage);
	};

	const handleChangeRowsPerPageUtmSource = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPageUtmSource(+event.target.value);
		setPageUtmSource(0);
	};

	//UTM Medium
	const [currentUtmMedium, setCurrentUtmMedium] = useState<{utm_name: string, total: number}[]>();
	const [pageUtmMedium, setPageUtmMedium] = useState(0);
	const [rowsPerPageUtmMedium, setRowsPerPageUtmMedium] = useState(10);

	const handleChangePageUtmMedium = (event: unknown, newPage: number) => {
		setPageUtmMedium(newPage);
	};

	const handleChangeRowsPerPageUtmMedium = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPageUtmMedium(+event.target.value);
		setPageUtmMedium(0);
	};

	//UTM Campaign
	const [currentUtmCampaign, setCurrentUtmCampaign] = useState<{utm_name: string, total: number}[]>();
	const [pageUtmCampaign, setPageUtmCampaign] = useState(0);
	const [rowsPerPageUtmCampaign, setRowsPerPageUtmCampaign] = useState(10);

	const handleChangePageUtmCampaign = (event: unknown, newPage: number) => {
		setPageUtmCampaign(newPage);
	};

	const handleChangeRowsPerPageUtmCampaign = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPageUtmCampaign(+event.target.value);
		setPageUtmCampaign(0);
	};


	useEffect(() => {
		if (revenuesInfo?.countries && revenuesInfo?.countries) {
			setCurrentCountry(
				revenuesInfo?.countries?.filter(item => item.country !== 'Unknown').sort((a, b) => b.total - a.total)
			)
		}

		if (revenuesInfo?.utm_source) {
			setCurrentUtmSource(
				revenuesInfo?.utm_source
					.filter(item => item.utm_name !== 'Unknown')
					.sort((a, b) => b.total - a.total)
			)
		}

		if (revenuesInfo?.utm_medium) {
			setCurrentUtmMedium(
				revenuesInfo?.utm_medium
					.filter(item => item.utm_name !== 'Unknown')
					.sort((a, b) => b.total - a.total)
			)
		}

		if (revenuesInfo?.utm_campaign) {
			setCurrentUtmCampaign(
				revenuesInfo?.utm_campaign
					.filter(item => item.utm_name !== 'Unknown')
					.sort((a, b) => b.total - a.total)
			)
		}

	}, [revenuesInfo]);


	return (
		<>
			<div className={styles.container}>
				<div className={styles.pageName}>
					<div className={styles.top}>
						<div className={styles.title}>
							<h2>Дашборд</h2>
						</div>
					</div>

				</div>

				<div>

					<div style={{display: 'flex', flexWrap: 'wrap'}}>
						<Indicator 
							name='Выручка' 
							curentValue={totalCurrentRevenue}
							lastValue={totalLastRevenue} 
							format='currency' 
							symbol='$'
						/>

						<Indicator 
							name='Касса' 
							curentValue={totalCurrentIncome}
							lastValue={totalLastIncome} 
							format='currency' 
							symbol='$'
						/>

						<Indicator 
							name='Продажи' 
							curentValue={totalCurrentSales}
							lastValue={totalLastSales} 
						/>

						<Indicator 
							name='Средний чек'
							curentValue={totalCurrentRevenue / totalCurrentSales}
							lastValue={totalLastRevenue / totalLastSales}
							format='currency' 
							symbol='$'
						/>

						<Indicator 
							name='Ср. выручка на клиента'
							curentValue={repeatSales.revenue_per_one_customer}
							lastValue={repeatSalesLast.revenue_per_one_customer}
							format='currency' 
							symbol='$'
						/>
					</div>
					

					<ResponsiveContainer width={'100%'} height={400}>
						<LineChart margin={{top: 20}} data={formatedRevenueCurrent}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="date"/>
							<YAxis tickFormatter={v => `${formatNumberWithSpaces(v)} $`} fontSize={12} width={70}/>
							<Tooltip content={<TooltipComparison/>}/>
							<Line type="monotone" dataKey={'value'} stroke="#8884d8" strokeWidth={2} name='Выручка'/>
							<Line type="monotone" dataKey={'valueLast'} stroke="#8884d8" strokeWidth={2} name='Выручка' opacity={0.2}/>
						</LineChart>
					</ResponsiveContainer>


					<div style={{display: 'flex', flexWrap: 'wrap', marginTop: 20, justifyContent: 'center'}}>

						<Paper sx={{ width: '100%', maxWidth: 400, margin: '5px' }}>
							<TableContainer 
								style={{marginTop: 20, overflowX: 'auto'}}>
								<Table sx={{ minWidth: 320 }}>
									<TableHead>
										<TableRow>
											<TableCell>Страна</TableCell>
											<TableCell align="right">Выручка</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{currentCountry && currentCountry.slice(pageCountry * rowsPerPageCountry, pageCountry * rowsPerPageCountry + rowsPerPageCountry)
											.map((row) => (
												<TableRow
													key={row.country}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													<TableCell component="th" scope="row">
														{row.country}
													</TableCell>
													<TableCell align="right">{`${formatNumberWithSpaces(row.total)} $`}</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
							<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={currentCountry ? currentCountry.length : 0}
								rowsPerPage={rowsPerPageCountry}
								page={pageCountry}
								onPageChange={handleChangePageCountry}
								onRowsPerPageChange={handleChangeRowsPerPageCountry}
							/>
						</Paper>


						<Paper sx={{ width: '100%', maxWidth: 400, margin: '5px' }}>
							<TableContainer 
								style={{marginTop: 20, overflowX: 'auto'}}>
								<Table sx={{ minWidth: 320 }}>
									<TableHead>
										<TableRow>
											<TableCell>UTM Source</TableCell>
											<TableCell align="right">Выручка</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{currentUtmSource && currentUtmSource.slice(pageUtmSource * rowsPerPageUtmSource, pageUtmSource * rowsPerPageUtmSource + rowsPerPageUtmSource)
											.map((row) => (
												<TableRow
													key={row.utm_name}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													<TableCell component="th" scope="row">
														{row.utm_name}
													</TableCell>
													<TableCell align="right">{`${formatNumberWithSpaces(row.total)} $`}</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
							<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={currentUtmSource ? currentUtmSource.length : 0}
								rowsPerPage={rowsPerPageUtmSource}
								page={pageUtmSource}
								onPageChange={handleChangePageUtmSource}
								onRowsPerPageChange={handleChangeRowsPerPageUtmSource}
							/>
						</Paper>

						<Paper sx={{ width: '100%', maxWidth: 400, margin: '5px' }}>
							<TableContainer 
								style={{marginTop: 20, overflowX: 'auto'}}>
								<Table sx={{ minWidth: 320 }}>
									<TableHead>
										<TableRow>
											<TableCell>UTM Medium</TableCell>
											<TableCell align="right">Выручка</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{currentUtmMedium && currentUtmMedium.slice(pageUtmMedium * rowsPerPageUtmMedium, pageUtmMedium * rowsPerPageUtmMedium + rowsPerPageUtmMedium)
											.map((row) => (
												<TableRow
													key={row.utm_name}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													<TableCell component="th" scope="row">
														{row.utm_name}
													</TableCell>
													<TableCell align="right">{`${formatNumberWithSpaces(row.total)} $`}</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
							<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={currentUtmMedium ? currentUtmMedium.length : 0}
								rowsPerPage={rowsPerPageUtmMedium}
								page={pageUtmMedium}
								onPageChange={handleChangePageUtmMedium}
								onRowsPerPageChange={handleChangeRowsPerPageUtmMedium}
							/>

						</Paper>

						<Paper sx={{ width: '100%', maxWidth: 400, margin: '5px' }}>
							<TableContainer 
								style={{marginTop: 20, overflowX: 'auto'}}>
								<Table sx={{ minWidth: 320 }}>
									<TableHead>
										<TableRow>
											<TableCell>UTM Campaign</TableCell>
											<TableCell align="right">Выручка</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{currentUtmCampaign && currentUtmCampaign.slice(pageUtmCampaign * rowsPerPageUtmCampaign, pageUtmCampaign * rowsPerPageUtmCampaign + rowsPerPageUtmCampaign)
											.map((row) => (
												<TableRow
													key={row.utm_name}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													<TableCell component="th" scope="row">
														{row.utm_name}
													</TableCell>
													<TableCell align="right">{`${formatNumberWithSpaces(row.total)} $`}</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
							<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={currentUtmCampaign ? currentUtmCampaign.length : 0}
								rowsPerPage={rowsPerPageUtmCampaign}
								page={pageUtmCampaign}
								onPageChange={handleChangePageUtmCampaign}
								onRowsPerPageChange={handleChangeRowsPerPageUtmCampaign}
							/>
						</Paper>


						<Paper sx={{ width: '100%', margin: '5px' }}>
							<TableContainer>
								<Table sx={{ minWidth: 320 }}>
									<TableHead>
										<TableRow>
											<TableCell>Продукт</TableCell>
											<TableCell align="right">Выручка</TableCell>
											<TableCell align="right">Продаж</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{store.analyticsStore.revenueProducts.slice(pageProducts * rowsPerPageProducts, pageProducts * rowsPerPageProducts + rowsPerPageProducts)
											.map((row) => (
												<TableRow
													key={row.product_id}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													<TableCell component="th" scope="row">
														{row.product_name}
													</TableCell>
													<TableCell align="right">{`${formatNumberWithSpaces(row.revenue)} $`}</TableCell>
													<TableCell align="right">{row.sales}</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
							<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={store.analyticsStore.revenueProducts ? store.analyticsStore.revenueProducts.length : 0}
								rowsPerPage={rowsPerPageProducts}
								page={pageProducts}
								onPageChange={handleChangePageProducts}
								onRowsPerPageChange={handleChangeRowsPerPageProducts}
							/>
						</Paper>


					</div>


				</div>

				

			</div>

		</>
	);
});

export default Dasboard;



// SELECT DATE(o.paid_date) AS date, SUM(o.paid_amount) AS revenue, COUNT(o.order_id) AS sales
// 	FROM orders o
// 	WHERE o.paid_date BETWEEN '2023-12-01 05:00:00' AND '2023-12-31 04:59:59' AND o.status = "fulfilled" OR o.status = "partiallyPaid"
// 	GROUP BY DATE(DATE_ADD(o.paid_date, INTERVAL -5 HOUR));