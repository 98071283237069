export const errorMessage = (message: string): string => {
	switch (message){
		case 'Incorrect password':
			return 'Неверный пароль';

		case 'User not found':
			return 'Пользователь не найден';
		
		case 'Such a user has already been registered':
			return 'Такой пользователь уже зарегистрирован';

		case 'Passwords do not match':
			return 'Пароли не совпадают';

		case 'You have not changed any fields':
			return 'Вы не изменили ни одного поля';

		case 'Token expired':
			return 'Токен доступа истек';

		case 'Binance API access keys are wrong or not enough permissions':
			return 'Не валиднный API для Binance';

		case 'Bybit API access keys are wrong or not enough permissions':
			return 'Не валиднный API для ByBit';

		default:
			return 'Что-то пошло не так';
	}
};